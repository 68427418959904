import { Input } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import TranslatedErrorMessage from '../TranslatedErrorMessage';

function DateField({
  fieldName, fieldValue, onBlur, onChange,
  autoFocus = false, disabled = false,
}) {
  return (
    <>
      <Input
        autoFocus={autoFocus}
        type="date"
        name={fieldName}
        onChange={onChange}
        onBlur={onBlur}
        value={fieldValue}
        max="2100-12-31"
        disabled={disabled}
      />
      <TranslatedErrorMessage name={fieldName} />
    </>
  );
}

DateField.propTypes = {
  // Field properties
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  // Other props
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateField;
