import {
  Button,
  Divider,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  IconButton,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CloseIcon from '../../../icons/CloseIcon';
import {
  formatDateTime,
  getTestID,
} from '../../../utils/utils';
import { revisionStatus } from '../../../utils/constants';

function ModalChangeRevisonStatus(props) {
  const {
    revision,
    open,
    setOpen,
    handleUpdate,
  } = props;
  const { t } = useTranslation();

  const [status, setStatus] = useState();

  return (
    <Modal
      data-testid={getTestID('ModalChangeRevisonStatus')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('changeApproval')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <Text margin={0} fontWeight="700">
            {revision?.name}
          </Text>
          <Text size="sm">{formatDateTime(revision?.updatedOn)}</Text>
          <Divider />
          <RadioGroup onChange={setStatus} value={status}>
            <Radio value="waitingForApproval">
              {t('revision.approval.reset')}
            </Radio>
            {revision?.status === revisionStatus.APPROVED ? (
              <Radio value="declined">
                {t('revision.decline')}
              </Radio>
            ) : (
              <Radio value="approved">
                {t('revision.approve')}
              </Radio>
            )}
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            mr={3}
            disabled={!status}
            onClick={(event) => {
              event.preventDefault();
              handleUpdate({ request: revision.uuid, status });
              setOpen(false);
            }}
          >
            {t('confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalChangeRevisonStatus.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedOn: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleUpdate: PropTypes.shape({
    mutate: PropTypes.func.isRequired,
  }).isRequired,
};

export default ModalChangeRevisonStatus;
