import {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import {
  CatalogueCountry,
  CatalogueExchangeStandard,
  CatalogueLanguage,
  CatalogueName,
  CatalogueStatus,
  CatalogueTooltipIcons,
} from '../Cells';
import Toggler from '../../ui/Toggler';
import RevisionTable from '../RevisionTable/RevisionTable';

function CatalogueTableSmall({
  data, selectedIndexes, toggleRow,
}) {
  const { t } = useTranslation();
  const [columnWidths, setColumnWidths] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const updateWidths = () => {
      if (tableRef.current) {
        const widths = Array.from(tableRef.current.querySelectorAll('td'))
          .slice(0, 4) // There are four columns to measure
          .map((td) => `${td.offsetWidth}px`);
        setColumnWidths(widths);
      }
    };
    updateWidths(); // Call on mount to set initial widths
    window.addEventListener('resize', updateWidths); // Adjust on window resize
    return () => {
      window.removeEventListener('resize', updateWidths);
    };
  }, []);

  return (
    <Table variant="main" className="table-main" ref={tableRef}>
      <Thead>
        <Tr>
          <Th />
          <Th>{t('catalogue.name')}</Th>
          <Th>{t('country')}</Th>
          <Th>{t('exchangeStandard')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.pages.map((page) => (
          page.catalogues.map((catalogue) => (
            <Fragment key={catalogue.catalogueUuid}>
              <Tr className={selectedIndexes.includes(catalogue.catalogueUuid) ? 'selected' : 'normal'} borderBottom="none">
                <Td className=".first-cell">
                  <Toggler
                    expand={selectedIndexes.includes(catalogue.catalogueUuid)}
                    handleClick={() => toggleRow(catalogue.catalogueUuid)}
                  />
                </Td>
                <CatalogueName
                  name={catalogue.name}
                  uuid={catalogue.catalogueUuid}
                  maxWidth={['300px']}
                />
                <CatalogueCountry region={catalogue.region} />
                <CatalogueExchangeStandard exchangeStandard={catalogue.exchangeStandard} />
              </Tr>
              <Tr>
                <Td colSpan="4" padding="0" className="single-row-data">
                  <Collapse in={selectedIndexes.includes(catalogue.catalogueUuid)} animateOpacity>
                    <Table>
                      <Thead visibility="collapse">
                        <Tr>
                          <Th width={columnWidths[0]} />
                          <Th width={columnWidths[1]} />
                          <Th width={columnWidths[2]} />
                          <Th width={columnWidths[3]} />
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr className={selectedIndexes.includes(catalogue.catalogueUuid) && 'selected'}>
                          <Td />
                          <CatalogueLanguage
                            language={catalogue.language}
                            withLabel
                          />
                          <CatalogueTooltipIcons
                            isShared={catalogue.isShared}
                            assetsConfiguredFlag={catalogue.assetsConfiguredFlag}
                          />
                          <CatalogueStatus status={catalogue.status} />
                        </Tr>
                      </Tbody>
                    </Table>
                  </Collapse>
                </Td>
              </Tr>
              {
                catalogue.revisions?.length > 0 && (
                  <Tr className="nested">
                    <Td colSpan="4" padding="0">
                      <Collapse
                        in={selectedIndexes.includes(catalogue.catalogueUuid)}
                        animateOpacity
                      >
                        <RevisionTable
                          styling
                          catalogue={catalogue}
                          revisions={catalogue.revisions}
                        />
                      </Collapse>
                    </Td>
                  </Tr>
                )
              }
            </Fragment>
          ))
        ))}
      </Tbody>
    </Table>
  );
}

CatalogueTableSmall.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      catalogues: PropTypes.arrayOf(PropTypes.shape({
        catalogueUuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
  selectedIndexes: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleRow: PropTypes.func.isRequired,
};

export default CatalogueTableSmall;
