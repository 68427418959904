import {
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Input from '../../Form/Input';
import parseArticleKeys from '../../parseArticleKeys';
import { getTestID } from '../../../../utils/utils';

function ArticleList(props) {
  const {
    exchangeStandard,
    articleListParam,
  } = props;
  const { t } = useTranslation();

  const [hasArticleList, setHasArticleList] = useState(false);
  const [articleList] = useField(articleListParam.name, 'text');

  return (
    <GridItem>
      <Grid
        gridTemplateColumns="repeat(3, auto)"
      >
        <GridItem>
          {!hasArticleList && (
            <>
              <Input
                key={JSON.stringify(articleListParam)}
                type="parameter"
                data={articleListParam}
                transformation={exchangeStandard.value}
                label=""
                hint={t('dexAssetsPortal.articleListAnonymous.hint')}
              />
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  setHasArticleList(true);
                }}
              >
                {t('submitArticleList')}
              </Button>
            </>
          )}
          {hasArticleList && (
            <Grid
              flexDirection="column"
              marginTop="8"
            >
              {Object.entries(parseArticleKeys(articleList.value))
                .filter(([, value]) => Number.isInteger(value))
                .map(([key, value]) => (
                  <Flex data-testid={getTestID('ArticleNumberDetails')} key={key}>
                    <Text>
                      {value}
                      &nbsp;
                    </Text>
                    <Text variant="label">{t(key)}</Text>
                  </Flex>
                ))}
              <Flex>
                <Button
                  variant="link"
                  onClick={() => { setHasArticleList(false); }}
                  padding="0"
                >
                  {t('reviewArticleList')}
                </Button>
              </Flex>
            </Grid>
          )}
        </GridItem>
      </Grid>
    </GridItem>
  );
}

ArticleList.propTypes = {
  exchangeStandard: PropTypes.shape({ value: PropTypes.string }).isRequired,
  articleListParam: PropTypes.shape({
    name: PropTypes.string,
    mandatory: PropTypes.bool,
  }).isRequired,
};

export default ArticleList;
