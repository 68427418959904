import {
  Box,
  Divider,
  Heading,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationPage from './ConfirmationPage';
import SuccessPage from './SuccessPage';
import { getTestID } from '../../../utils/utils';

function GenerateRevision() {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleShowConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  return (
    <Box data-testid={getTestID('DexAssetsPortalGenerator')}>
      <Heading as="h1" size="xl">
        {t('dexAssetsPortal.header')}
      </Heading>
      <Divider />
      {showConfirmation
        ? <SuccessPage />
        : <ConfirmationPage showSuccessPage={handleShowConfirmation} /> }
    </Box>
  );
}

export default GenerateRevision;
