import { Icon } from '@chakra-ui/react';

function CustomDashIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M2 8 L14 8" // Draws a horizontal line from (2, 8) to (14, 8)
        stroke="currentColor" // Sets the color to the current text color
        strokeWidth="2" // Adjusts the thickness of the dash
        strokeLinecap="round" // Makes the ends of the line rounded
      />
    </Icon>
  );
}

export default CustomDashIcon;
