const Link = {
  variants: {
    truncated: {
      display: 'block',
      overflow: 'hidden',
      width: 'inherit',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: 'inherit',
      _groupHover: {
        color: 'geberit.500',
      },
    },
  },
  baseStyle: {
    color: 'geberit.500',
    _focus: {
      boxShadow: 'none',
    },
  },
};

export default Link;
