import { useBreakpointValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import CatalogueTableSmall from './CatalogueTableSmall';
import CatalogueTableLarge from './CatalogueTableLarge';
import CatalogueTableMobile from './CatalogueTableMobile'; // Assume you create this mobile view
import { useRowSelection } from '../hooks';

function CatalogueTable({ data }) {
  const tableView = useBreakpointValue({
    xs: 'mobile',
    sm: 'small',
    md: 'small',
    lg: 'large',
  });

  const [selectedIndexes, toggleRow] = useRowSelection();

  return (
    <>
      {tableView === 'large' && (
        <CatalogueTableLarge data={data} selectedIndexes={selectedIndexes} toggleRow={toggleRow} />
      )}
      {tableView === 'small' && (
        <CatalogueTableSmall data={data} selectedIndexes={selectedIndexes} toggleRow={toggleRow} />
      )}
      {tableView === 'mobile' && (
        <CatalogueTableMobile data={data} selectedIndexes={selectedIndexes} toggleRow={toggleRow} />
      )}
    </>
  );
}

CatalogueTable.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      catalogues: PropTypes.arrayOf(PropTypes.shape({
        catalogueUuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
};

export default CatalogueTable;
