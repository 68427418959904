const Text = {
  baseStyle: {
    whiteSpace: 'pre-wrap',
  },
  variants: {
    error: {
      marginTop: '8px',
      color: 'error',
    },
    label: {
      color: 'gray.700',
    },
  },
  sizes: {
    md: {
      fontSize: '14px',
    },
    sm: {
      fontSize: '12px',
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export default Text;
