import { useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import {
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

import { loginRequest } from '../../config/msal';
import Error from '../ui/Error';
import useInitRouteStore from '../../services/InitRouteStore';
import { getTestID } from '../../utils/utils';

function SignInButton() {
  const { t } = useTranslation();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const initRoute = useInitRouteStore((state) => state.initRoute);

  const [, setAccessToken] = useLocalStorage('accessToken');
  const [, setUuid] = useLocalStorage('uuid');

  const authenticateWithMsal = async () => {
    try {
      if (!accounts || accounts.length === 0) {
        throw new Error('No accounts available');
      }

      const res = await instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });
      setAccessToken(res.accessToken);
      setUuid(res.uniqueId);
    } catch (err) {
      if ((!isAuthenticated && inProgress === InteractionStatus.None)
        || err instanceof InteractionRequiredAuthError) {
        try {
          const res = await instance.acquireTokenPopup({ ...loginRequest, account: accounts[0] });
          setAccessToken(res.accessToken);
          setUuid(res.uniqueId);
        } catch (popupErr) {
          setError(popupErr);
        }
      } else {
        setError(err);
      }
    }
  };

  return (
    <>
      {error && <Error error={error} />}
      <Button
        size="md"
        w="100%"
        data-testid={getTestID('SignInButton')}
        variant="primary"
        onClick={() => authenticateWithMsal().then(() => {
          navigate(initRoute || '/', { replace: true });
        })}
      >
        {t('logInAs')}
      </Button>
    </>
  );
}

export default SignInButton;
