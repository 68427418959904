import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import customHistory from './history';

const reactPlugin = new ReactPlugin();
const connectionString = process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;

const appInsights = connectionString
  ? new ApplicationInsights({
    config: {
      connectionString,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: customHistory },
      },
    },
  })
  : null;

if (appInsights) {
  appInsights.loadAppInsights();
} else {
  // eslint-disable-next-line no-console
  console.warn('Application Insights connection string is not set.');
}

export { appInsights, reactPlugin };
