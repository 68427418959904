import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import UserRole from './UserRole';
import { getTestID } from '../../utils/utils';

function UserSummary(props) {
  const { user } = props;
  return (
    <Box
      data-testid={getTestID('UserSummary')}
      marginBottom={8}
    >
      <Flex flex="1" flexDirection="column">
        <Text marginBottom="1" fontWeight="700">
          {user.fullName || '\u00A0'}
        </Text>
        <Text marginBottom="2">{user.email || '\u00A0'}</Text>
      </Flex>
      <UserRole role={user.role} />
    </Box>
  );
}

UserSummary.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default UserSummary;
