import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Grid,
  Text,
  Input as ChakraInput,
  Textarea,
  GridItem,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../../utils/utils';
import GeberitSpinner from '../../ui/GeberitSpinner';
import Required from '../../ui/Required';
import Input from '../Form/Input';
import TranslatedErrorMessage from '../Form/TranslatedErrorMessage';
import AssortmentOptions from './AssortmentOptions/AssortmentOptions';

function Assortment({
  transformationData = {},
  transformationDataLoading = false,
}) {
  const { t, i18n } = useTranslation();

  const [nameField] = useField('name', 'text');
  const [descriptionField] = useField('description', 'text');
  const [exchangeStandard] = useField('exchangeStandard', 'text');

  const assortmentParam = transformationData?.parameters?.find((p) => p.name === 'assortment');
  const articleListParam = transformationData?.parameters?.find((p) => p.name === 'articleList');

  return (
    <Box data-testid={getTestID('Transformations')}>
      <Grid gridGap="8">
        <GridItem>
          <Heading as="h1" size="h1">
            {t('transformation.create')}
          </Heading>
          <Flex>
            <Text data-testid={getTestID('ExchangeStandardText')} variant="label">{t('transformationType')}</Text>
            &nbsp;
            <Text data-testid={getTestID('standardNameText')}>
              {t(`transformations.${exchangeStandard.value}.pretty_name`)}
            </Text>
          </Flex>
          <Divider />
        </GridItem>
        <GridItem>
          <FormLabel>
            <Heading>
              {`1. ${t('name')}`}
              <Required />
            </Heading>
          </FormLabel>
          <Text>{t('catalogue.nameBlurb')}</Text>
          <>
            <ChakraInput
              data-testid={getTestID('CatalogueNameText')}
              type="text"
              name={nameField.name}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
              value={nameField.value}
              maxLength={255}
              autoFocus
            />
            <TranslatedErrorMessage name={nameField.name} />
          </>
        </GridItem>
        <GridItem>
          <FormLabel>
            <Heading>
              {`2. ${t('description')}`}
            </Heading>
          </FormLabel>
          <Text>{t('catalogue.descriptionBlurb')}</Text>
          <>
            <Textarea
              data-testid={getTestID('CatalogueDescriptionText')}
              name={descriptionField.name}
              onChange={descriptionField.onChange}
              onBlur={descriptionField.onBlur}
              value={descriptionField.value}
              maxLength={255}
            />
            <TranslatedErrorMessage name={descriptionField.name} />
          </>
        </GridItem>
        {transformationDataLoading && <GeberitSpinner />}
        {transformationData?.parameters?.length > 0 && (
          <GridItem>
            <FormLabel>
              <Heading>
                {`3. ${t('defineCatalogueAssortment')}`}
                <Required />
              </Heading>
            </FormLabel>
            {transformationData?.parameters
              .filter((p) => p.name === 'locale')
              .map((p) => (
                <Input
                  key={JSON.stringify(p)}
                  type="parameter"
                  label={`parameters.${p.name}.label`}
                  data={p}
                  transformation={exchangeStandard.value}
                  hint={(i18n.exists(`parameters.${p.name}.hint`) && t(`parameters.${p.name}.hint`)) || ''}
                  searchbox
                />
              ))}
          </GridItem>
        )}
        <AssortmentOptions
          assortmentParam={assortmentParam}
          assortmentParamLabel={assortmentParam && `parameters.${assortmentParam.name}.label`}
          articleListParam={articleListParam}
          articleListParamLabel={articleListParam && `parameters.${articleListParam.name}.label`}
        />
      </Grid>
    </Box>
  );
}

Assortment.propTypes = {
  transformationData: PropTypes.shape({
    name: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
  transformationDataLoading: PropTypes.bool,
};

export default Assortment;
