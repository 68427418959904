import {
  createContext, useContext, useState, useCallback, useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const NetworkErrorContext = createContext();

// Custom hook to access the NetworkErrorContext
export const useError = () => useContext(NetworkErrorContext);

/**
 * NetworkErrorProvider manages the global error state.
 * It provides the ability to add new errors and clear all errors globally.
 * Errors are cleared on route change or when manually triggered.
 */
export function NetworkErrorProvider({ children }) {
  const [errors, setErrors] = useState([]);

  /**
   * Function to add an error to the global state, ensuring no duplicates.
   * If the same error (by message and description) already exists, it will not be added again.
   */
  const addError = useCallback((error) => {
    setErrors((prevErrors) => {
      const errorExists = prevErrors.some(
        (existingError) => existingError.message === error.message
          && existingError.description === error.description,
      );

      if (!errorExists) {
        return [...prevErrors, error];
      }

      return prevErrors;
    });
  }, []);

  /**
   * Function to clear all errors from the global state.
   * This can be triggered manually or on route changes.
   */
  const clearErrors = useCallback(() => {
    setErrors([]);
  }, []);

  // Clear errors on component mount (to handle page refresh).
  useEffect(() => {
    clearErrors();
  }, [clearErrors]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    errors,
    addError,
    clearErrors,
  }), [errors, addError, clearErrors]);

  return (
    // Provide the global error context to all children
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NetworkErrorContext.Provider value={contextValue}>
      {children}
    </NetworkErrorContext.Provider>
  );
}

NetworkErrorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
