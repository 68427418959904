import {
  Flex,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TimerIcon from '../../icons/TimerIcon';
import { getTestID } from '../../utils/utils';

const formatTime = (totalSeconds, t) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return [
    hours ? `${hours} ${t('hours')}` : '',
    minutes ? `${minutes} ${t('minutes')}` : '',
    `${seconds} ${t('seconds')}`,
  ].filter(Boolean).join(' ');
};

function SecondsAsEstimatedTime({
  totalSeconds = 0, showNull = false, showLabel = true, label = true, size = null,
}) {
  const { t } = useTranslation();
  const displayTime = totalSeconds > 0 || showNull;
  const timeString = formatTime(totalSeconds, t);

  return (
    <Flex alignItems="center">
      {showLabel && (
        <>
          <TimerIcon />
          <Text variant="label" mr="10px">{label ?? t('estimatedTime')}</Text>
        </>
      )}
      {displayTime ? (
        <Text data-testid={getTestID('SecondsAsEstimatedTime')} mr={0} size={size}>
          {timeString}
        </Text>
      ) : (
        <Text mr={0} size={size}>
          {t('uncalculated')}
        </Text>
      )}
    </Flex>
  );
}

SecondsAsEstimatedTime.propTypes = {
  totalSeconds: PropTypes.number,
  size: PropTypes.string,
  label: PropTypes.bool,
  showNull: PropTypes.bool,
  showLabel: PropTypes.bool,
};

export default SecondsAsEstimatedTime;
