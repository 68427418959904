/* eslint-disable func-names */
import * as yup from 'yup';

import { inputTypes } from '../../utils/constants';
import { parseNumberFromString } from '../../utils/utils';

// Custom validation method to check for max decimal places
yup.addMethod(yup.number, 'maxDigitsAfterDecimal', function (digits, message) {
  return this.test('maxDigitsAfterDecimal', message, function (value) {
    const { path, createError } = this;
    if (value !== undefined && value !== null) {
      const valueStr = value.toString();
      if (valueStr.includes('.')) {
        const decimalPart = valueStr.split('.')[1];
        if (decimalPart.length > digits) {
          return createError({ path, message });
        }
      }
    }
    return true;
  });
});

export const createYupSchema = (schema, config) => {
  // Return an empty object if the schema or config is not provided
  if (!schema || !config) return {};

  const { id, validationType, validations = [] } = config;

  // Check if the validation type exists in Yup (e.g., string, number)
  if (!yup[validationType]) {
    return schema;
  }

  // Initialize the validator with the corresponding Yup validation type
  let validator = yup[validationType]();

  // Iterate over the validations array and apply each validation rule to the validator
  validations.forEach((validation) => {
    const { params, type } = validation;

    // Check if the validation type exists on the validator (e.g., required, max)
    if (validator[type]) {
      // Apply the validation rule with the provided parameters
      if (Array.isArray(params)) {
        const finalParams = params.map((param, index) => {
          if (index === 1 && typeof param === 'string' && typeof params[2] === 'object') {
            return { key: param, params: params[2] }; // Message with parameters
          }
          return param; // Regular parameter or regex
        });
        validator = validator[type](...finalParams);
      } else {
        validator = validator[type](params);
      }
    }
  });

  // Return a new schema object with the added validator
  return {
    ...schema,
    [id]: validator,
  };
};

const regexMappings = {
  onlyUppercaseCharsNumbers: {
    regex: /^[A-Z0-9]+$/g,
    errorMessage: 'inputValidationMessages.onlyUppercaseCharsNumbers',
  },
  onlyUppercaseCharsNumbersWhitespace: {
    regex: /^[\sA-Z0-9]+$/g,
    errorMessage: 'inputValidationMessages.onlyUppercaseCharsNumbersWhitespace',
  },
  onlyUppercaseChars: {
    regex: /^[A-Z]+$/g,
    errorMessage: 'inputValidationMessages.onlyUppercaseChars',
  },
  onlyNumbers: {
    regex: /^[0-9]+$/g,
    errorMessage: 'inputValidationMessages.onlyNumbers',
  },
  onlyCharsNumbers: {
    regex: /^[a-zA-Z0-9]+$/g,
    errorMessage: 'inputValidationMessages.onlyCharsNumbers',
  },
  onlyCharsNumbersWhitespace: {
    regex: /^[\sa-zA-Z0-9]+$/g,
    errorMessage: 'inputValidationMessages.onlyCharsNumbersWhitespace',
  },
  email: {
    /* This regex validates email addresses by enforcing three main rules:
      - the local part (before the @ symbol) must not contain spaces or additional @ symbols
      - it must be followed by a domain name that also does not include spaces or @ symbols
      - the domain must end with a dot followed by at least one more character,
      which cannot be a space or @ symbol.
      The anchors ^ and $ ensure the entire string matches this pattern. */
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/g,
    errorMessage: 'inputValidationMessages.email',
  },
  onlyLimitedCharsetDqr: {
    /* This regex allows:
      - Uppercase and lowercase English letters (A-Za-z)
      - Numbers (0-9)
      - German umlauts (ÄäÜüÖö) and the eszett (ß)
      - The specified special characters
      - Whitespace (\s matches spaces, tabs, and line breaks) */
    regex: /^[A-Za-z0-9ÄäÜüÖö!"#$%&‘()*+,\-.\\/:;<=>?@^_`§ß\s]+$/g,
    errorMessage: 'inputValidationMessages.onlyLimitedCharsetDqr',
  },
};

// Convert the input type to a Yup validation type
const getValidationType = (type) => {
  if (type === inputTypes.TEXT || type === inputTypes.EMAIL) {
    return 'string';
  }
  if (type === inputTypes.INTEGER) {
    return 'number';
  }
  return type;
};

/**
 * The generateValidationParams function creates validation parameters in a consistent structure:
 *
 * Required: Adds a required validation with a message key.
 * Max: Adds a max value validation with a message key and additional parameters.
 * Min: Adds a min value validation with a message key and additional parameters.
 * TypeError: Adds a type error validation for numbers.
 * Precision: Adds a precision validation for numbers with a message key and additional parameters.
 * Regex: Adds a regex validation with a regex pattern and a message key.
 */
const generateValidationParams = (d) => {
  const validations = [];

  if (d.required) {
    validations.push({ type: 'required', params: ['inputValidationMessages.required'] });
  }
  if (d.max) {
    const max = parseNumberFromString(d.max);
    const messageKey = d.type === inputTypes.TEXT
      ? 'inputValidationMessages.maxValueExceeded'
      : 'inputValidationMessages.maxValueExceededNum';
    validations.push({ type: 'max', params: [max, messageKey, { max }] });
  }
  if (d.min) {
    const min = parseNumberFromString(d.min);
    const messageKey = d.type === inputTypes.TEXT
      ? 'inputValidationMessages.minValueExceeded'
      : 'inputValidationMessages.minValueExceededNum';
    validations.push({ type: 'min', params: [min, messageKey, { min }] });
  }
  if (d.type === inputTypes.NUMBER) {
    validations.push({ type: 'typeError', params: ['inputValidationMessages.minValueExceededNum', { min: d.min }] });
  }
  if (d.precision) {
    const precision = parseInt(d.precision, 10);
    validations.push({
      type: 'maxDigitsAfterDecimal',
      params: [precision, 'inputValidationMessages.maxDigitsAfterDecimal', { digits: precision }],
    });
  }
  if (d.rule) {
    validations.push({ type: 'matches', params: [regexMappings[d.rule].regex, regexMappings[d.rule].errorMessage] });
  }
  if (d.type === inputTypes.EMAIL) {
    validations.push({ type: 'matches', params: [regexMappings[d.type].regex, regexMappings[d.type].errorMessage] });
  }

  return validations;
};

const generateValidationSchemaForFields = (fields) => fields.map((field) => ({
  id: field.name,
  validationType: getValidationType(field.type),
  validations: generateValidationParams(field),
}));

export const generateValidationSchemaForParametersAndPresets = (transformationData) => {
  const validationParams = generateValidationSchemaForFields(transformationData?.parameters || []);
  const validationPresets = generateValidationSchemaForFields(transformationData?.presets || []);

  const combinedSchema = {
    ...validationParams.reduce(createYupSchema, {}),
    ...validationPresets.reduce(createYupSchema, {}),
  };

  return yup.object().shape(combinedSchema);
};
