import { Icon } from '@chakra-ui/react';

function PaperClip(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M7,5A1,1,0,0,1,9,5v8.5A1.5,1.5,0,0,1,7.5,15h-1A1.5,1.5,0,0,1,5,13.5V3A2,2,0,0,1,7,1H9a2,2,0,0,1,2,2V13h1V3A3,3,0,0,0,9,0H7A3,3,0,0,0,4,3V13.5A2.5,2.5,0,0,0,6.5,16h1A2.5,2.5,0,0,0,10,13.5V5A2,2,0,0,0,6,5v7H7Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export default PaperClip;
