import { Icon } from '@chakra-ui/react';

function DatapointsIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M15,7a1,1,0,0,0-.85.5H12V5.9a1.15,1.15,0,0,0-.36-.83L12.75,4a1,1,0,0,0,1-.24,1,1,0,1,0-1.42-1.42,1,1,0,0,0-.24,1L10.78,4.51A6.79,6.79,0,0,0,8.5,4.05V1.85A1,1,0,0,0,9,1,1,1,0,0,0,7,1a1,1,0,0,0,.5.85v2.2a6.79,6.79,0,0,0-2.28.46L4,3.25a1,1,0,0,0-.24-1A1,1,0,0,0,2.29,3.71a1,1,0,0,0,1,.24L4.36,5.07A1.15,1.15,0,0,0,4,5.9V7.5H1.85A1,1,0,0,0,1,7,1,1,0,0,0,1,9a1,1,0,0,0,.85-.5H4v1.6a1.17,1.17,0,0,0,.36.83L3.25,12.05a1,1,0,0,0-1,.24,1,1,0,0,0,1.42,1.42,1,1,0,0,0,.24-1L5.21,11.5A7.09,7.09,0,0,0,7.5,12v2.17a1,1,0,1,0,1,0V12a7.09,7.09,0,0,0,2.29-.48l1.26,1.25a1,1,0,0,0,.24,1,1,1,0,0,0,1.42-1.42,1,1,0,0,0-1-.24l-1.11-1.12A1.17,1.17,0,0,0,12,10.1V8.5h2.15A1,1,0,1,0,15,7ZM8,11c-1.95,0-3-.64-3-.9V9.18A6.68,6.68,0,0,0,8,9.8a6.68,6.68,0,0,0,3-.62v.92C11,10.36,10,11,8,11ZM5,7.18A6.68,6.68,0,0,0,8,7.8a6.68,6.68,0,0,0,3-.62V7.9c0,.26-1.05.9-3,.9s-3-.64-3-.9ZM8,5c1.95,0,3,.64,3,.9S10,6.8,8,6.8s-3-.64-3-.9S6.05,5,8,5Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default DatapointsIcon;
