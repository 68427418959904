import { createIcon } from '@chakra-ui/react';

const XlsxIcon = createIcon({
  displayName: 'Xlsx',
  viewBox: '0 0 20 20',

  path: (
    <g fill="currentColor" fillRule="nonzero">
      <defs>
        <path d="M11 6H0v11h11V6zm-1 1v9H1V7h9z" id="a" />
      </defs>
      <path d="M13 0H5v6.8h1V1h6v4h4v14H6v-3H5v4h12V4l-4-4zm0 4V1.41L15.59 4H13z" />
      <path d="M4.853 11.367L3.126 9h1.265l1.094 1.597L6.601 9h1.19l-1.696 2.395L8 14H6.735l-1.287-1.82L4.213 14H3z" />
      <use xlinkHref="#a" />
    </g>
  ),
});

export default XlsxIcon;
