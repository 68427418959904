import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import { sortAlphabetically, getLocale } from '../../../../utils/utils';
import SingleSelectDropdown from '../../../ui/SingleSelectDropdown';

function SingleSelect({
  fieldName, onBlur, onChange, values,
  fieldValue = '', searchbox = false, autoFocus = false, disabled = false,
}) {
  const { t } = useTranslation();
  const [, setSelectedCountry] = useLocalStorage('countryLanguage', null);

  const persistSelectedLocaleToLocalStorage = (lng) => {
    setSelectedCountry(lng);
  };

  return (
    <SingleSelectDropdown
      name={fieldName}
      onChange={(e) => {
        if (fieldName.includes('locale')) {
          persistSelectedLocaleToLocalStorage(e.target.value);
        }
        onChange(e);
      }}
      onBlur={onBlur}
      value={fieldValue || values[0]}
      disabled={disabled}
      maxWidth="100%"
      autoFocus={autoFocus}
      searchbox={searchbox}
    >
      {values
        .sort(sortAlphabetically)
        .map((option) => (
          <option key={option} value={option}>
            {getLocale(option) || t(option) || option}
          </option>
        ))}
    </SingleSelectDropdown>
  );
}

SingleSelect.propTypes = {
  // Field properties
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  // Other props
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  searchbox: PropTypes.bool,

  // Data properties
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SingleSelect;
