import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { roles } from '../../utils/constants';
import { getTestID } from '../../utils/utils';

function UserRole(props) {
  const { role } = props;
  const { t } = useTranslation();

  return (
    <Box
      data-testid={getTestID('UserRole')}
      display="inline-block"
      height="fit-content"
      padding="0.25rem 0.5rem"
      textAlign="center"
      justifyContent="center"
      border="1px solid"
      fontSize="0.85rem"
      fontWeight="500"
      textTransform="uppercase"
      backgroundColor={role === roles.MDM || role === roles.ADMIN ? 'geberit.50' : 'warningTransparency10'}
      borderColor={role === roles.MDM || role === roles.ADMIN ? 'geberit.500' : 'warning'}
      color={role === roles.MDM || role === roles.ADMIN ? 'geberit.500' : 'warning'}
    >
      {role || t('user.role.unassigned')}
    </Box>
  );
}

UserRole.propTypes = {
  role: PropTypes.string.isRequired,
};

export default UserRole;
