import {
  Box,
  Divider,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { getTestID } from '../../../utils/utils';
import GeberitSpinner from '../../ui/GeberitSpinner';
import ExchangeStandardInput from '../Form/ExchangeStandardInput';

function Transformations({
  setSelectedTransformation,
  transformationsLoading,
  transformations = {},
}) {
  const { t } = useTranslation();

  const [field, , helpers] = useField('exchangeStandard');

  return (
    <Box data-testid={getTestID('Transformations')}>
      <Heading
        data-testid={getTestID('h1HeaderTextForCreateNewCatalogue')}
        as="h1"
        size="h1"
      >
        {t('transformation.create')}
      </Heading>
      <Heading>{t('transformation.choose')}</Heading>
      <Text>{t('transformation.presets')}</Text>
      <Divider />
      {transformationsLoading && <GeberitSpinner />}
      {Object.entries(transformations).length > 0 && (
        <>
          <SimpleGrid columns={[1, 1, 2, 3, 4, 4]} spacing={6}>
            {Object.entries(transformations)?.map(([key, value]) => (
              <ExchangeStandardInput
                key={key}
                title={t(`transformations.${key}.pretty_name`)}
                blurb={t(`transformations.${key}.description`)}
                revision={value.revision}
                selected={field.value === key}
                onClick={() => {
                  helpers.setValue(key);
                  helpers.setTouched(true);
                  setSelectedTransformation(key);
                }}
              />
            ))}
          </SimpleGrid>
          <Divider />
        </>
      )}
    </Box>
  );
}

Transformations.propTypes = {
  setSelectedTransformation: PropTypes.func.isRequired,
  transformationsLoading: PropTypes.bool.isRequired,
  transformations: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};

export default Transformations;
