import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { useMemo } from 'react';
import UserSummary from '../../ui/UserSummary';
import {
  getTestID,
  sortLocalesAlphabetically,
} from '../../../utils/utils';
import CloseIcon from '../../../icons/CloseIcon';
import InfoIcon from '../../../icons/InfoIcon';
import { useDeleteUserLocale } from '../../../api/hooks';

function ModalManageLocales({
  user, open, setOpen, setUserLocaleAdd, locales = [],
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: deleteUserLocale } = useDeleteUserLocale();

  const handleDeleteUserLocale = (userUuid, code) => {
    deleteUserLocale(
      { userUuid, code },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['userLocales', user.uuid]);
        },
      },
    );
  };

  // Memoize the sorted locales to optimize performance
  const sortedLocales = useMemo(() => [...locales].sort(
    (a, b) => sortLocalesAlphabetically(a.localeCode, b.localeCode),
  ), [locales]);

  return (
    <Modal
      data-testid={getTestID('ModalManageLocales')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('locale.manage.heading')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <UserSummary user={user} />
          {sortedLocales?.length > 0 && (
            <>
              <Divider />
              <FormLabel>{t('user.locales.assigned')}</FormLabel>
              {sortedLocales
                .map((locale) => (
                  <Flex
                    key={JSON.stringify(locale) + user.uuid}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontWeight={700} margin={0} padding="13px 0">
                      {t(`locales.${locale.localeCode}`)}
                    </Text>
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Button
                        data-testid={getTestID('UserLocaleDelete')}
                        variant="link"
                        color="error"
                        isDisabled={locales.length === 1}
                        onClick={() => {
                          handleDeleteUserLocale(user?.uuid, locale?.localeCode);
                        }}
                      >
                        {t('remove')}
                      </Button>
                      {locales.length === 1 && (
                        <Tooltip label={t('user.locale.deleteInfo')} placement="top-start" hasArrow>
                          <span><InfoIcon /></span>
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                ))}
            </>
          )}
          {locales?.length === 0 && (
            <Text>{t('user.locales.notFound')}</Text>
          )}
          <Box>
            <Button
              data-testid={getTestID('AssignCountryButton')}
              variant="link"
              paddingLeft={0}
              onClick={() => {
                setOpen(false);
                setUserLocaleAdd(true);
              }}
            >
              +
              {' '}
              {t('user.locale.assign')}
            </Button>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button data-testid={getTestID('CloseModalButton')} variant="outline" onClick={() => setOpen(false)}>
            {t('close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalManageLocales.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setUserLocaleAdd: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.shape({ localeCode: PropTypes.string })),
};

export default ModalManageLocales;
