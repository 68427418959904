import {
  Box,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useDownloadTransformationLog, useLogExists } from '../../../api/hooks';
import { getTestID } from '../../../utils/utils';
import GeberitSpinner from '../../ui/GeberitSpinner';

function DownloadTransformationLog(props) {
  const {
    revisionUuid,
    revisionName,
  } = props;
  const { t } = useTranslation();
  const fileName = `transformation-log-${revisionName}.log`;

  const { status: logExists, isError: noLogFile } = useLogExists(revisionUuid);
  const {
    downloadTransformationLog,
    progress,
  } = useDownloadTransformationLog(revisionUuid, fileName);

  if (noLogFile) { return null; }
  return (
    <Box
      data-testid={getTestID('DownloadTransformationLog')}
      marginTop="8"
      width="50%"
    >
      {logExists === 'success' && (
        <Button
          onClick={downloadTransformationLog}
          variant="secondary"
        >
          {t('downloadTransformationLog')}
        </Button>
      )}
      {progress && (
        <HStack>
          {fileName && (
            <Text marginTop="1" color="gray.700" size="sm">
              {fileName}
            </Text>
          )}
          <GeberitSpinner />
        </HStack>
      )}
    </Box>
  );
}

DownloadTransformationLog.propTypes = {
  revisionUuid: PropTypes.string.isRequired,
  revisionName: PropTypes.string.isRequired,
};

export default DownloadTransformationLog;
