import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const opacityValues = ['0.6', '0.4', '0.2', '1'];

// Rotates an array by the specified number of rotations.
function rotateArray(arr, rotations) {
  const rotatedArray = [...arr];
  for (let index = 0; index < rotations; index += 1) {
    const element = rotatedArray.pop();
    rotatedArray.unshift(element);
  }
  return rotatedArray;
}

// Generates keyframes for the animation based on the index and inverted flag.
const getKeyFrames = (index, inverted) => {
  const baseColor = inverted ? 'rgba(255, 255, 255, OPACITY)' : 'rgba(0, 0, 0, OPACITY)';

  const rotatedOpacityValues = rotateArray(opacityValues, index);
  const keyframes = rotatedOpacityValues.map((opacityValue) => ({
    backgroundColor: baseColor.replace('OPACITY', opacityValue),
  }));

  return keyframes;
};

// Define Square component with framer-motion
function Square({
  index, row, column, animationDuration, inverted,
}) {
  // Get keyframes for the current square
  const keyframes = getKeyFrames(index, inverted);

  const squareStyle = {
    width: '0.5rem',
    height: '0.5rem',
    gridColumn: column,
    gridRow: row,
  };

  // Extracting the keyframe values into arrays for each property
  const backgroundColors = keyframes.map((keyframe) => keyframe.backgroundColor);

  return (
    <motion.div
      style={squareStyle}
      animate={{ backgroundColor: backgroundColors }}
      transition={{
        duration: animationDuration,
        repeat: Infinity, // Ensures the animation loops continuously
        ease: 'easeInOut', // Easing function
        times: [0, 0.25, 0.5, 0.75, 1], // Timing function for the keyframes
      }}
    />
  );
}

Square.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
  animationDuration: PropTypes.number.isRequired,
  inverted: PropTypes.bool.isRequired,
};

function SpinnerContainer({ children }) {
  const containerStyle = {
    display: 'grid',
    width: '1.5rem',
    height: '1.5rem',
    gap: '0.25rem',
    padding: '2px',
    boxSizing: 'border-box',
  };

  return <div style={containerStyle}>{children}</div>;
}

SpinnerContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function GeberitSpinner({ inverted = false, animationDuration = 0.6 }) {
  const renderSquares = () => {
    const squares = [];
    for (let index = 1; index < 5; index += 1) {
      squares.push(
        <Square
          key={`square-${index}`}
          index={index}
          row={index <= 2 ? 1 : 2}
          column={index % 2 !== 0 ? 1 : 2}
          animationDuration={animationDuration}
          inverted={inverted}
        />,
      );
    }
    return squares;
  };

  return <SpinnerContainer>{renderSquares()}</SpinnerContainer>;
}

GeberitSpinner.propTypes = {
  inverted: PropTypes.bool,
  animationDuration: PropTypes.number,
};

export default GeberitSpinner;
