import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Divider,
  FormLabel,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import {
  useChangeOwner,
  useAssignableOwners,
} from '../../api/hooks';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import Error from '../ui/Error';
import GeberitSpinner from '../ui/GeberitSpinner';
import SingleSelectDropdown from '../ui/SingleSelectDropdown';

function ModalChangeOwnership(props) {
  const {
    open,
    setOpen,
    catalogue,
  } = props;
  const { t } = useTranslation();

  const [ownerUuid, setOwnerUuid] = useState();

  // Reset state when modal is closed or catalogue changes
  useEffect(() => {
    if (!open) {
      setOwnerUuid(undefined);
    }
  }, [open, catalogue]);

  const {
    data: assignableOwners,
    loading,
    error,
  } = useAssignableOwners(catalogue?.catalogueUuid);

  const { mutate: changeOwner } = useChangeOwner(catalogue?.catalogueUuid);
  const queryClient = useQueryClient();

  const handleOwnerChange = (newOwnerUuid, catalogueUuid) => {
    changeOwner(
      { newOwnerUuid, catalogueUuid },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['catalogue', catalogueUuid]);
          queryClient.invalidateQueries(['collaborators', catalogueUuid]);
        },
      },
    );
  };

  return (
    <Modal
      data-testid={getTestID('ModalChangeOwnership')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('catalogue.changeOwner')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <Heading marginBottom="1">{catalogue.name}</Heading>
          <Text margin={0}>{catalogue.exchangeStandard}</Text>
          <Divider />
          {loading && <GeberitSpinner />}
          {!loading && assignableOwners && (
            <>
              <FormLabel>{t('catalogue.selectNewOwner')}</FormLabel>
              <SingleSelectDropdown
                autoFocus
                value={ownerUuid}
                onChange={(e) => setOwnerUuid(e.target.value)}
                maxWidth="100%"
                label={t('inputSelectValues.select')}
                searchbox
              >
                {assignableOwners?.map((c) => (
                  <option
                    key={c?.user?.uuid}
                    value={c?.user?.uuid}
                  >
                    {`${c?.user?.fullName} (${c?.user?.role})`}
                  </option>
                ))}
              </SingleSelectDropdown>
            </>
          )}
          {error && <Error error={error} />}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            mr={6}
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            isDisabled={loading || !ownerUuid}
            onClick={(event) => {
              event.preventDefault();
              handleOwnerChange(ownerUuid, catalogue?.catalogueUuid);
              setOpen(false);
            }}
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalChangeOwnership.propTypes = {
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    exchangeStandard: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalChangeOwnership;
