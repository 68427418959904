import {
  Box,
  Divider,
  Flex,
  Heading,
  Switch,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import { useNotifications, useUpdateNotifications } from '../../api/hooks';
import BellIcon from '../../icons/BellIcon';
import { getTestID } from '../../utils/utils';
import GeberitSpinner from '../ui/GeberitSpinner';

function TabUserSettings() {
  const { t } = useTranslation();
  const [uuid] = useLocalStorage('uuid');
  const queryClient = useQueryClient();

  const { data: notifications, isLoading } = useNotifications(uuid);

  const { mutate: updateNotifications } = useUpdateNotifications(uuid);

  const handleUpdateNotifications = (key, value) => {
    const updatedNotifications = {
      ...notifications,
      [key]: value,
    };
    updateNotifications(
      updatedNotifications,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['notifications', uuid]);
        },
      },
    );
  };

  return (
    <div data-testid={getTestID('UserSettings')}>
      <Heading>
        <BellIcon margin="-4px 8px 0 0" />
        {t('notificationSettings')}
      </Heading>
      {isLoading && <GeberitSpinner />}
      {!isLoading && notifications && (
        <Box>
          <Flex>
            <Flex flex="1">{t('notifications.notifyByInApp')}</Flex>
            <Flex flex="1">
              <Switch
                key="notifyByInApp"
                isChecked={notifications.notifyByInApp}
                onChange={(e) => handleUpdateNotifications('notifyByInApp', e.target.checked)}
              />
            </Flex>
          </Flex>
          <Divider />
          <Flex>
            <Flex flex="1">{t('notifications.notifyByEmail')}</Flex>
            <Flex flex="1">
              <Switch
                key="notifyByEmail"
                isChecked={notifications.notifyByEmail}
                onChange={(e) => handleUpdateNotifications('notifyByEmail', e.target.checked)}
              />
            </Flex>
          </Flex>
          <Divider />
          <Flex>
            <Flex flex="1">{t('notifications.notifyForSelfCreated')}</Flex>
            <Flex flex="1">
              <Switch
                key="notifyForSelfCreated"
                isChecked={notifications.notifyForSelfCreated}
                onChange={(e) => handleUpdateNotifications('notifyForSelfCreated', e.target.checked)}
              />
            </Flex>
          </Flex>
          <Divider />
          <Flex>
            <Flex flex="1">{t('notifications.notifyForSubscriptions')}</Flex>
            <Flex flex="1">
              <Switch
                key="notifyForSubscriptions"
                isChecked={notifications.notifyForSubscriptions}
                onChange={(e) => handleUpdateNotifications('notifyForSubscriptions', e.target.checked)}
              />
            </Flex>
          </Flex>
        </Box>
      )}
    </div>
  );
}

export default TabUserSettings;
