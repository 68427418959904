import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCreateAnonymousCatalogue, useLocales, useTransformation } from '../../../api/hooks';
import { routes } from '../../../utils/constants';
import { getTestID } from '../../../utils/utils';
import parseArticleKeys from '../../CatalogueCreate/parseArticleKeys';
import Alert from '../../ui/Alert';
import GeberitSpinner from '../../ui/GeberitSpinner';
import { exchangeStandard, generatedInputValues } from '../utils';

function ConfirmationPage(props) {
  const { showSuccessPage } = props;
  const {
    locale,
    articleNumber,
  } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: createCatalogue } = useCreateAnonymousCatalogue();

  const handleCreateCatalogue = (catalogue) => {
    createCatalogue(
      { catalogue },
      {
        onSuccess: () => {
          showSuccessPage();
        },
      },
    );
  };

  const { data: locales, isLoading: localesDataLoading } = useLocales();

  const validLocale = locales?.some((l) => l.localeCode === locale);
  const countryName = validLocale && t(`locales.${locale}`);
  const validArticleNumber = parseArticleKeys(articleNumber).uniqueIDs.join('\n');

  const {
    data: transformation,
    isLoading: transformationLoading,
  } = useTransformation(exchangeStandard, !!(validLocale && validArticleNumber));

  const handleCreate = () => {
    const catalogue = generatedInputValues(locale, articleNumber, transformation);
    handleCreateCatalogue(catalogue);
  };

  const handleCancel = async () => {
    navigate(routes.DEX_ASSETS_PORTAL);
  };

  return (
    <Box data-testid={getTestID('DexAssetsConfirmation')}>
      {localesDataLoading && <GeberitSpinner />}
      {(transformationLoading && (locales && !validLocale))
        && (
          <Alert variant="error">
            {t('dexAssetsPortal.invalidLocale')}
          </Alert>
        )}
      {transformation
        && (
          <VStack spacing="90px" align="flex-start">
            <VStack spacing="8" align="flex-start">
              <Text>
                {t('dexAssetsPortal.successfullRequest', { article: articleNumber, locale: countryName })}
              </Text>
              <Text>
                {t('dexAssetsPortal.pleaseConfirm')}
              </Text>
            </VStack>
            <Flex
              justifyContent="flex-start"
              gap="90px"
              marginTop="60px"
            >
              <Text as="span">
                {t('dexAssetsPortal.amendSelection')}
              </Text>
              <Flex
                gap="8"
                justifyContent="flex-end"
              >
                <Button
                  variant="secondary"
                  onClick={handleCancel}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant="primary"
                  onClick={handleCreate}
                >
                  {t('dexAssetsPortal.create')}
                </Button>
              </Flex>
            </Flex>
          </VStack>
        )}
    </Box>
  );
}

ConfirmationPage.propTypes = {
  showSuccessPage: PropTypes.func.isRequired,
};

export default ConfirmationPage;
