import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';

function ExchangeStandardInput(props) {
  const {
    title,
    revision,
    blurb,
    selected,
    onClick,
  } = props;

  return (
    <Box
      name="exchangeStandard"
      position="relative"
      padding="5"
      border="1px solid"
      borderColor={!selected ? 'gray.400' : 'black'}
      cursor="pointer"
      _hover={{
        borderColor: 'black',
      }}
      onClick={onClick}
    >
      <Box
        position="absolute"
        top="-8px"
        left="5"
        width="24px"
        height="24px"
        borderRadius="100%"
        borderColor="inherit"
        _after={{
          content: '" "',
          position: 'absolute',
          display: 'block',
          width: '16px',
          height: '16px',
          borderRadius: '100%',
          border: '1px solid',
          borderColor: 'inherit',
          background: !selected ? 'white' : 'black',
          boxShadow: selected && '0 0 0 3px inset white',
        }}
        _groupHover={
          !selected && {
            top: '-12px',
            left: '16px',
            background: 'gray.200',
            _after: {
              top: '4px',
              left: '4px',
              background: 'inherit',
            },
          }
        }
      />
      <Flex justifyContent="space-between">
        <Text
          fontSize="md"
          color="black"
        >
          {title}
        </Text>
        <Text
          fontSize="md"
          color="black"
        >
          {revision}
        </Text>
      </Flex>
      <Text
        fontSize="sm"
        overflow="hidden"
        color="gray.600"
      >
        {blurb}
      </Text>
    </Box>
  );
}

ExchangeStandardInput.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  revision: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExchangeStandardInput;
