const Heading = {
  baseStyle: {
    marginBottom: '15px',
  },
  sizes: {
    h1: {
      fontSize: '24px',
      marginBottom: '40px',
      span: {
        fontSize: '24px',
      },
    },
    h2: {
      fontSize: '18px',
      span: {
        fontSize: '18px',
      },
    },
  },
  defaultProps: {
    size: 'h2',
  },
};

export default Heading;
