import {
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';
import Back from '../ui/Back';

function NotFound({
  catalogueName = '',
  catalogueUuid = '',
}) {
  const { t } = useTranslation();

  return (
    <Box data-testid={getTestID('NotFound')}>
      <Back
        text={
          catalogueUuid && catalogueName
            ? `${t('backTo')} ${catalogueName}`
            : t('backToOverview')
        }
        to={catalogueUuid ? `/catalogue/${catalogueUuid}` : '/'}
        paddingLeft={0}
      />
      <Heading as="h1" size="h1">
        {t('revision.notFound')}
      </Heading>
      <Text>{t('revision.notFoundBlurb')}</Text>
    </Box>
  );
}

NotFound.propTypes = {
  catalogueName: PropTypes.string,
  catalogueUuid: PropTypes.string,
};

export default NotFound;
