import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import { clearLocalStorageExcept } from '../../utils/utils';
import LogOutIcon from '../../icons/LogOutIcon';

const signOutClickHandler = async (instance, homeAccountId) => {
  // Clear access token and other necessary keys before logout
  clearLocalStorageExcept(['i18nextLng']);

  // Proceed with logout
  await instance.logoutRedirect({
    account: instance.getAccountByHomeId(homeAccountId),
  });
};

function SignOutButton({ marginBottom = '15px' }) {
  const { instance, accounts } = useMsal();
  const { t } = useTranslation();

  return (
    <Button
      marginBottom={marginBottom}
      leftIcon={<LogOutIcon />}
      variant="sidebarItem"
      justifyContent="flex-start"
      onClick={() => {
        signOutClickHandler(instance, accounts[0]?.homeAccountId);
      }}
    >
      <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.logOut')}</span>

    </Button>
  );
}

SignOutButton.propTypes = {
  marginBottom: PropTypes.string,
};

export default SignOutButton;
