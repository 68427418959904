import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function Tag(props) {
  const { children } = props;

  return (
    <Box
      display="inline-block"
      height="fit-content"
      padding="0.25rem 0.5rem"
      textAlign="center"
      justifyContent="center"
      backgroundColor="warningTransparency10"
      border="1px solid"
      borderColor="warning"
      fontWeight="500"
      textTransform="uppercase"
      color="error"
      fontSize="0.85rem"
    >
      {children}
    </Box>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tag;
