import { useState } from 'react';

export function useFilterState() {
  const [filters, setFilters] = useState({
    standards: [],
    locales: [],
    statuses: [],
  });

  const setStandards = (standards) => setFilters((prev) => (
    { ...prev, standards }));
  const setLocales = (locales) => setFilters((prev) => ({ ...prev, locales }));
  const setStatuses = (statuses) => setFilters((prev) => ({ ...prev, statuses }));

  const clearFilters = () => {
    setFilters({
      standards: [],
      locales: [],
      statuses: [],
    });
  };

  return {
    filters,
    setStandards,
    setLocales,
    setStatuses,
    clearFilters,
  };
}

export function useRowSelection() {
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  const toggleRow = (rowId) => {
    setSelectedIndexes((prevIndexes) => {
      const index = prevIndexes.indexOf(rowId);
      if (index > -1) {
        // Row is currently selected, remove it from the selection
        return prevIndexes.filter((id) => id !== rowId);
      }
      // Row is not selected, add it to the selection
      return [...prevIndexes, rowId];
    });
  };

  return [selectedIndexes, toggleRow];
}
