import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { useDeclineRoleRequest, useRevokeUserRole } from '../../../api/hooks';
import CloseIcon from '../../../icons/CloseIcon';
import {
  roles,
  userTypes,
} from '../../../utils/constants';
import { getTestID } from '../../../utils/utils';
import GeberitSpinner from '../../ui/GeberitSpinner';
import UserSummary from '../../ui/UserSummary';

function ModalRemoveUser(props) {
  const {
    user,
    type,
    open,
    setOpen,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: revokeUserRole, isLoading: isLoadingRevoke } = useRevokeUserRole();

  const handleRevokeUserRole = (userUuid) => {
    revokeUserRole(
      { uuid: userUuid },
      {
        onSuccess: () => {
          setOpen(false);
          queryClient.invalidateQueries(['usersList']);
        },
      },
    );
  };

  const { mutate: declineRoleRequest, isLoading: isLoadingDecline } = useDeclineRoleRequest();

  const handleDeclineRoleRequest = (userUuid) => {
    declineRoleRequest(
      { uuid: userUuid },
      {
        onSuccess: () => {
          setOpen(false);
          queryClient.invalidateQueries(['usersList']);
        },
      },
    );
  };

  return (
    <Modal
      data-testid={getTestID('ModalDeleteUser')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">
            {(type === userTypes.ACTIVE && user.role !== roles.DEFAULT)
              ? t('user.revokeRole')
              : t('user.declineRequest')}
          </Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <UserSummary user={user} />
          <Text>{t('user.deleting')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            mr={3}
            onClick={() => {
              if (type === userTypes.ACTIVE && user.role !== roles.DEFAULT) {
                handleRevokeUserRole(user?.uuid);
              } else {
                handleDeclineRoleRequest(user?.uuid);
              }
            }}
            isDisabled={isLoadingDecline || isLoadingRevoke}
            leftIcon={(isLoadingDecline || isLoadingRevoke) && <GeberitSpinner />}
          >
            {t('yesDelete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalRemoveUser.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    uuid: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalRemoveUser;
