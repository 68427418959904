import {
  Box,
  useStyleConfig,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { getTestID } from '../../utils/utils';

function ClickableContainer({ children, onClick }) {
  const styles = useStyleConfig('Box', { variant: 'clickable' });

  return (
    <Box data-testid={getTestID('BlueCircle')} __css={styles} onClick={onClick}>
      {children}
    </Box>
  );
}

ClickableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ClickableContainer;
