const colors = {
  geberit: {
    50: '#376EB41A', // Geberit Main Blue with 10% opacity
    500: '#376EB4', // CL17 Geberit Main Blue
    700: '#0A5A96', // CL18 Dark Geberit Blue
    800: '#004673', // CL19 Darkest Geberit Blue - not used currently
  },
  /* GRAY SCALE */
  gray: {
    25: '#FCFCFC', // CL02
    50: '#F7F7F7', // CL03
    100: '#F5F5F5', // CL04
    150: '#F1F1F1', // CL05
    200: '#E6E6E6', // CL06
    250: '#E3E3E3', // CL07
    300: '#D9D9D9', // CL08 - not used currently
    350: '#CCCCCC', // CL09
    400: '#B4B4B4', // CL10
    500: '#999999', // CL11
    600: '#707070', // CL12
    700: '#666666', // CL13
    800: '#5C5C5C', // CL14  - not used currently
    900: '#3D3D3D', // CL15
  },
  /* SIGNAL COLORS */
  success: '#008800', // CL20
  successTransparency10: '#0088001A', // CL20 with 10% transparency
  warning: '#F07800', // CL21
  warningTransparency10: '#F078001A', // CL21 with 10% transparency
  error: '#CE0000', // CL22
  errorTransparency10: '#CE00001A', // CL22 with 10% transparency

  white: '#FFFFFF', // CL01
  black: '#000000',
};

export default colors;
