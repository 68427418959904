import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';
import ChevronLeftIcon from '../../icons/ChevronLeftIcon';

function Back({
  to, text = '', catalogueName = '', handleClick = null,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      data-testid={getTestID('Back')}
      onClick={() => (handleClick ? handleClick() : navigate(to))}
      paddingLeft={0}
      paddingRight={2}
      md={{ width: '20px !important', height: '20px !important' }}
      leftIcon={<ChevronLeftIcon sx={{ width: '20px !important', height: '20px !important' }} />}
    >
      {text || (catalogueName ? `${t('backTo')} ${catalogueName}` : t('backToCatalogue'))}
    </Button>
  );
}

Back.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  catalogueName: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Back;
