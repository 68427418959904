import {
  Button,
  Box,
  Link,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../../utils/utils';

function DownloadSummaryReport({ summaryReport = null }) {
  const { t } = useTranslation();

  return (
    <Box
      data-testid={getTestID('DownloadSummaryReport')}
      marginTop="8"
    >
      {summaryReport && (
        <Link
          href={summaryReport?.fileLink}
          target="_blank"
          color="black"
          _hover={{ textDecoration: 'none' }}
        >
          <Button variant="secondary">
            {t('contextMenu.downloadSummaryReportFile')}
          </Button>
        </Link>
      )}
    </Box>
  );
}

DownloadSummaryReport.propTypes = {
  summaryReport: PropTypes.shape({
    fileLink: PropTypes.string,
  }),
};

export default DownloadSummaryReport;
