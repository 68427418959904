import { Container } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footerContainer">
        <Container variant="footer" as="footer" pt="4" id="footerContainer" pl="0">
          <span>
            &copy;
            {`${new Date().getFullYear()} ${t('footer.copyright')}`}
          </span>
          <nav />
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
