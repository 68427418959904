import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDate } from '../../../utils/utils';
import KeyValuePairs from '../../ui/KeyValuePairs';
import { useUser } from '../../../api/hooks';

function LastUpdated({ updatedOn, updatedBy }) {
  const { t } = useTranslation();

  const { data: user } = useUser(updatedBy);

  const keyValuePairs = [
    {
      key: t('lastUpdate'),
      value: formatDate(updatedOn),
    },
    {
      key: t('updatedBy'),
      value: user?.fullName,
    },
  ];

  return (
    <Box maxW="half" marginBottom="4">
      <KeyValuePairs
        list={keyValuePairs}
        templateColumns={{ base: '1fr' }}
        maxW="80%"
        gridGap="4"
        containerPadding="4"
      />
    </Box>
  );
}

LastUpdated.propTypes = {
  updatedOn: PropTypes.string.isRequired,
  updatedBy: PropTypes.string.isRequired,
};

export default LastUpdated;
