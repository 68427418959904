import {
  Box,
  Divider,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import DexAssetsCreate from './DexAssetsCreate';
import DexAssetsList from './DexAssetsList';
import SuccessMessage from './SuccessMessage';
import { getTestID } from '../../utils/utils';

function DexAssetsPortal() {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <Box data-testid={getTestID('DexAssetsPortal')}>
      <Heading as="h1" size="xl">
        {t('dexAssetsPortal.header')}
      </Heading>
      <Text fontSize="lg">
        {t('dexAssetsPortal.explanation')}
      </Text>
      <Divider />
      {showConfirmation
        ? <SuccessMessage showSuccessPage={setShowConfirmation} />
        : <DexAssetsCreate showSuccessPage={setShowConfirmation} /> }
      <DexAssetsList />
    </Box>
  );
}

export default DexAssetsPortal;
