import { Icon } from '@chakra-ui/react';

function FilterIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M6 15.62V9.66L.53 2h14.94L10 9.66v4.16l-4 1.8ZM2.47 3 7 9.34v4.74l2-.9V9.34L13.53 3H2.47Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default FilterIcon;
