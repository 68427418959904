import {
  Divider,
  Heading,
  Text,
  Input,
  FormLabel,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  IconButton,
} from '@chakra-ui/react';
import {
  Form,
  Formik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Required from '../ui/Required';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import { useUpdateCatalogue } from '../../api/hooks';

function ModalUpdateCatalogue(props) {
  const {
    catalogue,
    open,
    setOpen,
  } = props;
  const { t } = useTranslation();
  const { mutate: updateCatalogue } = useUpdateCatalogue(catalogue?.catalogueUuid);

  const handleUpdate = (updatedCatalogue) => {
    updateCatalogue(updatedCatalogue);
  };

  return (
    <Modal
      data-testid={getTestID('CatalogueUpdate')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('catalogue.rename')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={catalogue}
          onSubmit={(c) => {
            handleUpdate(
              { uuid: c.catalogueUuid, name: c.name, description: c.description },
            );
            setOpen(false);
          }}
        >
          {({ getFieldProps, errors, isSubmitting }) => (
            <Form>
              <ModalBody pb={6}>
                <Heading marginBottom="1">{catalogue.name}</Heading>
                <Text>{catalogue.exchangeStandard}</Text>
                <Divider />
                <FormLabel>
                  <Heading size="h3" as="h3">
                    {t('name')}
                    <Required />
                  </Heading>
                  <Text>{t('catalogue.change.name')}</Text>
                  <Input
                    id="name"
                    type="text"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getFieldProps('name')}
                    isInvalid={errors.name}
                    maxLength={255}
                    isRequired
                  />
                  {errors.name && (
                  <Text variant="error" size="sm">
                    {errors.name}
                  </Text>
                  )}
                </FormLabel>
                <Divider />
                <FormLabel>
                  <Heading size="h3" as="h3">
                    {t('description')}
                  </Heading>
                  <Text>{t('catalogue.change.description')}</Text>
                  <Textarea
                    data-testid={getTestID('ChangeDescriptionText')}
                    id="description"
                    type="text"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getFieldProps('description')}
                    isInvalid={errors.description}
                    maxLength={255}
                  />
                  {errors.description && (
                  <Text
                    variant="error"
                    size="sm"
                  >
                    {errors.description}
                  </Text>
                  )}
                </FormLabel>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outline"
                  mr={6}
                  onClick={() => setOpen(false)}
                >
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  isDisabled={isSubmitting}
                >
                  {t('save')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

ModalUpdateCatalogue.propTypes = {
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    exchangeStandard: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalUpdateCatalogue;
