import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Collapse,
  Td,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Toggler from '../../ui/Toggler';
import {
  CatalogueCountry,
  CatalogueDate,
  CatalogueExchangeStandard,
  CatalogueLanguage,
  CatalogueName,
  CatalogueStatus,
  CatalogueTooltipIcons,
} from '../Cells';
import RevisionTable from '../RevisionTable/RevisionTable';

function CatalogueTableLarge({ data, selectedIndexes, toggleRow }) {
  const { t } = useTranslation();
  const maxWidthCatalogueName = ['3xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'];

  return (
    <Table variant="main">
      <Thead>
        <Tr>
          <Th />
          <Th>{t('catalogue.name')}</Th>
          <Th>{t('status')}</Th>
          <Th />
          <Th>{t('country')}</Th>
          <Th>{t('language')}</Th>
          <Th>{t('exchangeStandard')}</Th>
          <Th>{t('lastRevision')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.pages.map((page) => (
          page.catalogues.map((catalogue) => (
            <Fragment key={catalogue.catalogueUuid}>
              <Tr
                className={(selectedIndexes.includes(catalogue.catalogueUuid) && catalogue.revisions?.length > 0) ? 'selected' : 'normal'}
              >
                {catalogue.revisions?.length > 0 ? (
                  <Td>
                    <Toggler
                      expand={selectedIndexes.includes(catalogue.catalogueUuid)}
                      handleClick={() => toggleRow(catalogue.catalogueUuid)}
                    />
                  </Td>
                ) : <Td />}
                <CatalogueName
                  name={catalogue.name}
                  uuid={catalogue.catalogueUuid}
                  maxWidth={maxWidthCatalogueName}
                />
                <CatalogueStatus status={catalogue.status} />
                <CatalogueTooltipIcons
                  className="tooltip-icons"
                  isShared={catalogue.isShared}
                  assetsConfiguredFlag={catalogue.assetsConfiguredFlag}
                />
                <CatalogueCountry region={catalogue.region} />
                <CatalogueLanguage language={catalogue.language} />
                <CatalogueExchangeStandard exchangeStandard={catalogue.exchangeStandard} />
                <CatalogueDate date={catalogue.lastRevisionOn} />
              </Tr>
              {catalogue.revisions?.length > 0 && (
                <Tr className={selectedIndexes.includes(catalogue.catalogueUuid) ? 'nested' : 'hidden'}>
                  <Td colSpan={8} className="single-row-data" pb="0" pr="0">
                    <Collapse in={selectedIndexes.includes(catalogue.catalogueUuid)} animateOpacity>
                      <RevisionTable
                        catalogue={catalogue}
                        revisions={catalogue.revisions}
                      />
                    </Collapse>
                  </Td>
                </Tr>
              )}
            </Fragment>
          ))
        ))}
      </Tbody>
    </Table>
  );
}

CatalogueTableLarge.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      catalogues: PropTypes.arrayOf(PropTypes.shape({
        catalogueUuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
  selectedIndexes: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleRow: PropTypes.func.isRequired,
};

export default CatalogueTableLarge;
