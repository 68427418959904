import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useUnsubscribeFromCatalogue } from '../../api/hooks';
import { getTestID } from '../../utils/utils';
import CloseIcon from '../../icons/CloseIcon';
import ModalIcon from '../ui/ModalIcon';

function ModalUnsubscribe(props) {
  const {
    open,
    setOpen,
    collaborationUuid,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: unsubscribeFromCatalogue } = useUnsubscribeFromCatalogue();

  const handleUnsubscribe = () => {
    unsubscribeFromCatalogue(
      { uuid: collaborationUuid },
      {
        onSuccess: async () => {
          /* Use window.location.assign to directly change the page location and reload the app,
           which ensures there won't be any ongoing queries refetching in the current component. */
          window.location.assign('/');
          queryClient.invalidateQueries(['catalogueList']);
        },
      },
    );
  };

  return (
    <Modal
      data-testid={getTestID('ModalUnsubscribe')}
      closeOnOverlayClick={false}
      isOpen={open}
      variant="warning"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalIcon type="warning" />
          <Text as="span">{t('warning')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {t('catalogue.unsubscribe')}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            mr={3}
            onClick={handleUnsubscribe}
          >
            {t('confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalUnsubscribe.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  collaborationUuid: PropTypes.string.isRequired,
};

export default ModalUnsubscribe;
