import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import GeberitSpinner from '../ui/GeberitSpinner';
import {
  formatDateTime,
  getLocale,
  getTestID,
  sortLocalesAlphabetically,
} from '../../utils/utils';
import useUserStore from '../../services/UserStore';
import { roles } from '../../utils/constants';
import { useSyncData } from '../../api/hooks';

const SPARE_PARTS_FILE = 'sparePartsFile';

function SyncStatuses() {
  const { t } = useTranslation();
  const user = useUserStore((s) => s.user);

  const { data: syncData, loading } = useSyncData();

  const userHasPermissions = user.role === roles.MDM || user.role === roles.ADMIN;

  return (
    <>
      {loading && <GeberitSpinner />}
      {Array.isArray(syncData) && (
      <TableContainer data-testid={getTestID('SyncStatuses')}>
        <Table variant="basic">
          <Thead>
            <Tr>
              <Th>{t('market')}</Th>
              {userHasPermissions && (
              <Th>
                {t('syncStatus.lastSyncToCtp')}
              </Th>
              )}
              <Th>
                {userHasPermissions ? t('syncStatus.lastSyncToFileExport') : t('syncStatus.mostRecent')}
              </Th>
              <Th>{t('syncStatus.lastUpdatedOn')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {syncData
              .sort((a, b) => sortLocalesAlphabetically(a.locale, b.locale))
              .map((entry) => (
                entry.syncType !== SPARE_PARTS_FILE ? (
                  <Tr key={entry.uuid}>
                    <Td>{getLocale(entry.locale) || entry.locale}</Td>
                    {userHasPermissions && (
                    <Td data-testid={getTestID('lastSyncToCtpTime')}>
                      {formatDateTime(entry.lastSyncToCtp)}
                    </Td>
                    )}
                    <Td data-testid={getTestID('lastSyncToFileExportTime')}>
                      {formatDateTime(entry.lastSyncToFileExport)}
                    </Td>
                    <Td data-testid={getTestID('lastUpdatedTime')}>
                      {formatDateTime(entry.lastUpdated)}
                    </Td>
                  </Tr>
                ) : null))}
          </Tbody>
        </Table>
      </TableContainer>
      )}
    </>
  );
}

export default SyncStatuses;
