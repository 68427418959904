import { useState } from 'react';
import {
  Box,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DownloadIcon from '../../icons/DownloadIcon';
import ModalDownloadPrices from './ModalDownloadPrices';
import { getTestID } from '../../utils/utils';

function DownloadOutputFileWithPrices({ outputFile = null }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Box data-testid={getTestID('DownloadOutputFileWithPrices')}>
      {outputFile && (
        <Button
          leftIcon={<DownloadIcon />}
          variant="primary"
          onClick={() => setOpen(true)}
        >
          {t('download')}
        </Button>
      )}
      {open && (
        <ModalDownloadPrices
          fileLink={outputFile?.fileLink}
          open={open}
          setOpen={setOpen}
        />
      )}
    </Box>
  );
}

DownloadOutputFileWithPrices.propTypes = {
  outputFile: PropTypes.shape({
    fileLink: PropTypes.string,
  }),
};

export default DownloadOutputFileWithPrices;
