import { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  VStack,
  IconButton,
  useDisclosure,
  Box,
  Text,
  Flex,
  Stack,
  Checkbox,
  DrawerFooter,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCatalogueFilters } from '../../api/hooks';
import FilterIcon from '../../icons/FilterIcon';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import CloseIcon from '../../icons/CloseIcon';
import ChevronLeftIcon from '../../icons/ChevronLeftIcon';
import { sortAlphabetically } from '../../utils/utils';
import CustomCheckIcon from '../../icons/CustomCheckIcon';

function Header({ onClose, isCategoryOpen }) {
  const { t } = useTranslation();
  return (
    <Flex
      as="header"
      pl={{ base: '0.5', sm: '2.5' }}
      pr={{ base: '4', sm: '6' }}
      minH="15"
      gap="2"
      fontSize="sm"
      bgColor="gray.200"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton
        icon={isCategoryOpen ? <ChevronLeftIcon /> : <CloseIcon />}
        bg="transparent"
        boxSize="12"
        onClick={onClose}
      />
      <Box>
        <Text as="span" mr="1">
          {t('catalogues.filterCataloguesBy')}
        </Text>
        {isCategoryOpen && (
          <Text as="span" fontWeight="bold">
            {t(`catalogues.${isCategoryOpen}`)}
          </Text>
        )}
      </Box>
    </Flex>
  );
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  isCategoryOpen: PropTypes.string,
};

function CategoryButton({ category, openCategoryDrawer, getSelectedCount }) {
  const { t } = useTranslation();
  return (
    <Button
      key={category.key}
      onClick={() => openCategoryDrawer(category.key)}
      rightIcon={<ChevronRightIcon />}
      justifyContent="space-between"
      alignItems="flex-start"
      p={4}
      w="100%"
      minH="auto"
      h="auto"
      background="gray.100"
      _hover={{ background: 'gray.200' }}
    >
      <Flex direction="column" align="flex-start" w="100%">
        <Text>{category.label}</Text>
        <Text fontSize="sm" color="gray.500">
          {`${getSelectedCount(category.key)} ${t('selected')}`}
        </Text>
      </Flex>
    </Button>
  );
}

CategoryButton.propTypes = {
  category: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  openCategoryDrawer: PropTypes.func.isRequired,
  getSelectedCount: PropTypes.func.isRequired,
};

function FiltersDrawer({
  options, onChange, selectedValues, getOptionLabel,
  maxWidth = '',
}) {
  const handleSelect = (value) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value];

    onChange(newSelectedValues);
  };

  return (
    <Box width={maxWidth || '250px'}>
      <Stack spacing={2}>
        {options
          .sort(sortAlphabetically)
          .map((option) => (
            <Box
              key={option}
              display="flex"
              alignItems="center"
              padding={2}
              gap={2}
            >
              <Checkbox
                icon={<CustomCheckIcon />}
                isChecked={selectedValues?.includes(option)}
                onChange={() => handleSelect(option)}
                mr={2}
                padding={2}
              />
              {getOptionLabel(option)}
            </Box>
          ))}
      </Stack>
    </Box>
  );
}

FiltersDrawer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

function FiltersMobile(props) {
  const {
    setStandards, setLocales, setStatuses, filters,
    setTempStatuses, setTempStandards, tempLocales, tempStandards, tempStatuses, setTempLocales,
  } = props;
  const { standards, locales, statuses } = filters;
  const { t } = useTranslation();
  const { data } = useCatalogueFilters();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCategoryOpen, setIsCategoryOpen] = useState(null);

  const openCategoryDrawer = (category) => setIsCategoryOpen(category);
  const closeCategoryDrawer = () => setIsCategoryOpen(null);

  const getSelectedCount = (category) => {
    switch (category) {
      case 'exchangeStandard':
        return tempStandards.length;
      case 'locale':
        return tempLocales.length;
      case 'status':
        return tempStatuses.length;
      default:
        return 0;
    }
  };

  const applyFilters = () => {
    setStandards(tempStandards);
    setLocales(tempLocales);
    setStatuses(tempStatuses);
    closeCategoryDrawer();
  };

  const resetTempFilters = () => {
    setTempStandards(standards);
    setTempLocales(locales);
    setTempStatuses(statuses);
    closeCategoryDrawer();
  };

  return (
    <>
      <IconButton
        icon={<FilterIcon />}
        aria-label={t('filters.label')}
        onClick={onOpen}
      />

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent height="100vh" width="100%">
          <Header onClose={onClose} isCategoryOpen={isCategoryOpen} />
          <DrawerBody px={{ base: '4', sm: '6' }} pt="4" pb="12" fontSize="sm">
            <Flex direction="column" gap={8}>
              {data && (
                <VStack spacing={2} align="stretch">
                  {[
                    { key: 'exchangeStandard', label: t('catalogues.exchangeStandard') },
                    { key: 'locale', label: t('catalogues.locale') },
                    ...(data?.statuses?.length > 1 ? [{ key: 'status', label: t('catalogues.status') }] : []),
                  ].map((category) => (
                    <CategoryButton
                      key={category.key}
                      category={category}
                      openCategoryDrawer={openCategoryDrawer}
                      getSelectedCount={getSelectedCount}
                    />
                  ))}
                </VStack>
              )}
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex direction="column" gap={2} width="100%">
              <Button variant="secondary" onClick={onClose}>
                {t('catalogues.backToCatalogueList')}
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Drawer isOpen={Boolean(isCategoryOpen)} placement="right" onClose={resetTempFilters} size="full">
        <DrawerContent>
          <Header onClose={resetTempFilters} isCategoryOpen={isCategoryOpen} />
          <DrawerBody
            overflowY="scroll"
            maxH={{ base: '80vh', sm: '80vh' }}
          >
            <Flex direction="column" gap={8}>
              {isCategoryOpen === 'exchangeStandard' && (
                <FiltersDrawer
                  onChange={setTempStandards}
                  options={data?.standards}
                  selectedValues={tempStandards}
                  getOptionLabel={(o) => t(`transformations.${o}.pretty_name`)}
                  label={t('exchangeStandard')}
                  closeOnSelect={false}
                />
              )}
              {isCategoryOpen === 'locale' && (
                <FiltersDrawer
                  onChange={setTempLocales}
                  options={data?.locales}
                  selectedValues={tempLocales}
                  getOptionLabel={(o) => t(`locales.${o}`)}
                  label={t('locale.heading')}
                  closeOnSelect={false}
                />
              )}
              {isCategoryOpen === 'status' && (
                <FiltersDrawer
                  onChange={setTempStatuses}
                  options={data?.statuses}
                  selectedValues={tempStatuses}
                  getOptionLabel={(o) => t(`statuses.options.${o}`)}
                  label={t('statuses.heading')}
                  closeOnSelect={false}
                />
              )}
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex direction="column" gap={2} width="100%">
              <Button variant="primary" onClick={applyFilters}>
                {`${t('catalogues.applySelected', { count: getSelectedCount(isCategoryOpen) })}`}
              </Button>
              <Button variant="secondary" onClick={resetTempFilters}>
                {t('cancel')}
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

FiltersMobile.propTypes = {
  filters: PropTypes.shape({
    standards: PropTypes.arrayOf(PropTypes.string).isRequired,
    locales: PropTypes.arrayOf(PropTypes.string).isRequired,
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  setStandards: PropTypes.func.isRequired,
  setLocales: PropTypes.func.isRequired,
  setStatuses: PropTypes.func.isRequired,
  setTempStandards: PropTypes.func.isRequired,
  setTempLocales: PropTypes.func.isRequired,
  setTempStatuses: PropTypes.func.isRequired,
  tempStandards: PropTypes.arrayOf(PropTypes.string).isRequired,
  tempLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  tempStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FiltersMobile;
