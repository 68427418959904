const Tabs = {
  parts: ['tab', 'tabpanels', 'tabpanel'],
  variants: {
    default: {
      tab: {
        padding: '0 0 8px 0',
        borderBottom: '4px solid',
        borderColor: 'transparent',
        fontWeight: 700,
        marginBottom: 10,
        '&:not(:first-child)': {
          marginLeft: 8,
        },
        _hover: {
          borderColor: 'gray.400',
        },
        _selected: {
          borderColor: 'geberit.500',
        },
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          borderColor: 'gray.900',
        },
      },
      tabpanel: {
        padding: 0,
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

export default Tabs;
