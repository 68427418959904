import {
  Box,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

function AvailabilityStatusBox(props) {
  const {
    testId,
    children,
    messageTestId = '',
  } = props;
  const { t } = useTranslation();

  return (
    <Box data-testid={getTestID(testId)}>
      <Grid gridGap="8">
        <GridItem>
          <Heading as="h1" size="h1">
            {t('articleAvailability.articleAvailabilityTitle')}
          </Heading>
          <Divider />
          <Text data-testid={messageTestId}>
            {children}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

AvailabilityStatusBox.propTypes = {
  testId: PropTypes.string.isRequired,
  messageTestId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default AvailabilityStatusBox;
