import { Icon } from '@chakra-ui/react';

function EllipsisIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M2,6 C3.1045695,6 4,6.8954305 4,8 C4,9.1045695 3.1045695,10 2,10 C0.8954305,10 0,9.1045695 0,8 C0,6.8954305 0.8954305,6 2,6 Z M14,6 C15.1045695,6 16,6.8954305 16,8 C16,9.1045695 15.1045695,10 14,10 C12.8954305,10 12,9.1045695 12,8 C12,6.8954305 12.8954305,6 14,6 Z M8,6 C9.1045695,6 10,6.8954305 10,8 C10,9.1045695 9.1045695,10 8,10 C6.8954305,10 6,9.1045695 6,8 C6,6.8954305 6.8954305,6 8,6 Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default EllipsisIcon;
