import { createIcon } from '@chakra-ui/react';

const DownloadIcon = createIcon({
  displayName: 'Download',
  viewBox: '0 0 20 20',

  path: (
    <path
      d="M0 20h19v-1H0v1zM9 0v13.329l-3.328-3.328-.708.707L9.5 15.244l4.537-4.536-.708-.707L10 13.329V0H9z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export default DownloadIcon;
