import { Icon } from '@chakra-ui/react';

function CategoriesIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M15 1v5h-5V1h5M6 1v5H1V1h5m9 9v5h-5v-5h5m-9 0v5H1v-5h5M16 0H9v7h7V0zM7 0H0v7h7V0zm9 9H9v7h7V9zM7 9H0v7h7V9z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default CategoriesIcon;
