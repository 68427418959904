import { Icon } from '@chakra-ui/react';

function BellIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M9,0 L9.00113275,2.0710167 C11.9566165,2.51114349 13.4853339,4.98856625 13.5937883,9.26676093 L13.5937883,9.26676093 L13.9230769,10 L15,11 L15,13 L10.9387547,13.0013506 C10.692915,14.8871298 9.70787714,16 8,16 C6.29212286,16 5.30708501,14.8871298 5.06124531,13.0013506 L1,13 L1,11 L2.07692308,10 L2.40621172,9.26676093 C2.5146738,4.98826279 4.04360042,2.51079204 6.99949618,2.07092307 L7,0 L9,0 Z M8.929,13 L7.07,13 L7.0801206,13.0581483 C7.20522307,13.7052888 7.47792487,13.9648912 7.90590944,13.9966287 L7.90590944,13.9966287 L8,14 C8.48236467,14 8.78584104,13.7515132 8.9198794,13.0581483 L8.9198794,13.0581483 L8.929,13 Z M8,4 C6.33333333,4 4.11111111,4.56775858 4.11111111,9 L4.11111111,9 L4.11111111,10 L3,11 L13,11 L11.8888889,10 L11.8888889,9 C11.8372019,4.73369736 9.66666667,4 8,4 Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default BellIcon;
