import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Divider,
  FormLabel,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Error from '../ui/Error';
import GeberitSpinner from '../ui/GeberitSpinner';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import {
  useAddCollaborator,
  useAssignableCollaborators,
} from '../../api/hooks';
import SingleSelectDropdown from '../ui/SingleSelectDropdown';

function ModalAddCollaborator(props) {
  const {
    open,
    setOpen,
    catalogue,
  } = props;
  const { t } = useTranslation();

  const [collaborator, setCollaborator] = useState();

  // Reset state when modal is closed or catalogue changes
  useEffect(() => {
    if (!open) {
      setCollaborator(undefined);
    }
  }, [open, catalogue]);

  const {
    data: collaborators,
    loading,
    error,
  } = useAssignableCollaborators(catalogue.catalogueUuid);

  const { mutate: addCollaborator } = useAddCollaborator(catalogue?.catalogueUuid);

  return (
    <Modal
      data-testid={getTestID('ModalAddCollaborator')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('collaborator.add')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <Heading marginBottom="1">{catalogue.name}</Heading>
          <Text margin={0}>{catalogue.exchangeStandard}</Text>
          <Divider />
          {loading && <GeberitSpinner />}
          {!loading && collaborators && (
            <>
              <FormLabel>{t('collaborator.select')}</FormLabel>
              <SingleSelectDropdown
                autoFocus
                value={collaborator}
                onChange={(e) => setCollaborator(e.target.value)}
                maxWidth="100%"
                label={t('inputSelectValues.select')}
                searchbox
              >
                {collaborators.map((c) => (
                  <option
                    key={c.user?.uuid}
                    value={c.user?.uuid}
                    disabled={!c.catalogueVisibleFlag}
                  >
                    {`${c.user?.fullName} (${c.user?.role})`}
                  </option>
                ))}
              </SingleSelectDropdown>
            </>
          )}
          {error && <Error error={error} />}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            mr={6}
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            isDisabled={loading || !collaborator}
            onClick={(event) => {
              event.preventDefault();
              addCollaborator({
                collaboratorUuid: collaborator,
                catalogueUuid: catalogue.catalogueUuid,
              });
              setOpen(false);
            }}
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalAddCollaborator.propTypes = {
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    exchangeStandard: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalAddCollaborator;
