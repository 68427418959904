import {
  Heading,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import GeberitSpinner from '../ui/GeberitSpinner';
import {
  useUserData,
  useUpdateUserStatus,
} from '../../api/hooks';
import TeaserBox from './TeaserBox';

function Overview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, isLoading } = useUserData();
  const { refetch } = useUpdateUserStatus();

  if (isLoading) {
    return <GeberitSpinner />;
  }

  return (
    <>
      <Heading as="h2" size="lg">{t('overview.heading')}</Heading>
      <Flex wrap="wrap" gap="8">
        <TeaserBox
          title={t('overview.teaser.dexAssetsPortal.title')}
          body={t('overview.teaser.dexAssetsPortal.body')}
          buttonLabel={t('overview.teaser.dexAssetsPortal.button')}
          onClick={() => navigate('/assets-portal')}
        />
        <TeaserBox
          title={t('overview.teaser.articleAvailability.title')}
          body={t('overview.teaser.articleAvailability.body')}
          buttonLabel={t('overview.teaser.articleAvailability.button')}
          onClick={() => navigate('/article-availability')}
          backgroundColor="geberit.500"
          color="white"
        />
        {user && !user.pending && (
          <TeaserBox
            title={t('overview.teaser.dexRoleRequest.title')}
            body={t('overview.teaser.dexRoleRequest.body')}
            buttonLabel={t('overview.teaser.dexRoleRequest.button')}
            onClick={refetch}
            color="black"
            backgroundColor="gray.150"
          />
        )}
        {user?.pending && (
          <TeaserBox
            title={t('overview.teaser.dexRoleRequestPending.title')}
            body={t('overview.teaser.dexRoleRequestPending.body')}
            backgroundColor="gray.150"
            color="black"
          />
        )}
      </Flex>
    </>
  );
}

export default Overview;
