import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  useEffect,
  useState,
} from 'react';

import useApiInstances from './ApiContext';
import useUserStore from '../services/UserStore';
import { roles, userTypes } from '../utils/constants';
import {
  formatFilters,
  formatSyncData,
  sortLocalesAlphabetically,
} from '../utils/utils';

const PAGE_SIZE_CATALOGUES = 10;
const PAGE_SIZE_USERS = 20;

export const useCatalogueList = (uuid, filters) => {
  const { integration } = useApiInstances();

  return useInfiniteQuery({
    queryKey: ['catalogueList', uuid, filters.standards, filters.locales, filters.statuses],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await integration('catalogues', {
        params: { size: PAGE_SIZE_CATALOGUES, page: pageParam, ...formatFilters(filters) },
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.rowTotal === lastPage.rowCount) {
        return undefined;
      }
      const nextPages = parseInt(lastPage.pageStart, 10) + 1;
      const hasNext = Math.floor(lastPage.rowTotal / lastPage.pageSize) > lastPage.pageStart;
      return hasNext ? nextPages : undefined;
    },
  });
};

export const useCatalogueFilters = () => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['catalogueFilters'],
    queryFn: async () => {
      const { data } = await integration.get('catalogue/filters');
      return data;
    },
  });
};

export const useCatalogueCollaborators = (catalogueUuid) => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['collaborators', catalogueUuid],
    queryFn: async () => {
      const { data } = await integration.get(`catalogue/${catalogueUuid}/collaborators`);
      return data;
    },
    enabled: !!catalogueUuid,
  });
};

export function useCurrentUserData() {
  const { integration } = useApiInstances();
  const { uuid } = useUserStore((s) => s.user);

  return useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const { data } = await integration(`users/${uuid}`);
      return data;
    },
  });
}

export function useUser(uuid) {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['user', uuid],
    queryFn: async () => {
      const { data } = await integration(`users/${uuid}`);
      return data;
    },
    enabled: !!uuid,
  });
}

export function useUpdateUserStatusToPending() {
  const { integration } = useApiInstances();
  const queryClient = useQueryClient();
  const { uuid } = useUserStore((s) => s.user);

  return useQuery({
    queryKey: ['user/pending'],
    queryFn: async () => {
      const data = await integration.put(
        `users/${uuid}/status?status=${userTypes.PENDING}`,
      );
      return data;
    },
    enabled: false,
    onSuccess: () => queryClient.invalidateQueries(['currentUser']),
  });
}

export const useArticlesAvailability = (articleField) => {
  const { integration } = useApiInstances();
  const articleFieldValue = articleField?.value?.trim();

  return useQuery({
    queryKey: ['articlesAvailability', articleFieldValue],
    queryFn: async () => {
      const { data } = await integration.get(`article/${articleFieldValue}`);
      return data;
    },
    retry: 0,
    enabled: !!articleFieldValue,
  });
};

export const useCatalogue = (uuid) => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['catalogue', uuid],
    queryFn: async () => {
      const { data } = await integration.get(`catalogue/${uuid}`);
      return data;
    },
    enabled: !!uuid,
  });
};

export const useAssignableOwners = (catalogueUuid) => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['assignableOwners', catalogueUuid],
    queryFn: async () => {
      const { data } = await integration.get(`catalogue/${catalogueUuid}/ownership/assignable`);
      return data;
    },
    enabled: !!catalogueUuid,
  });
};

export const useChangeOwner = (catalogueUuid) => {
  const { integration } = useApiInstances();

  const mutation = useMutation({
    mutationFn: ({ newOwnerUuid }) => integration.patch(`catalogue/${catalogueUuid}/ownership/${newOwnerUuid}`),
  });

  return mutation;
};

export const useAssignableCollaborators = (catalogueUuid) => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['assignableCollaborators', catalogueUuid],
    queryFn: async () => {
      const { data } = await integration.get(`catalogue/${catalogueUuid}/collaborators/assignable`);
      return data;
    },
    enabled: !!catalogueUuid,
  });
};

export const useAddCollaborator = (catalogueUuid) => {
  const queryClient = useQueryClient();
  const { portal } = useApiInstances();

  const mutation = useMutation({
    mutationFn: ({ collaboratorUuid }) => portal.post('collaboration/catalogueCollaborator', {
      collaborator: collaboratorUuid,
      catalogue: catalogueUuid,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['collaborators', catalogueUuid]);
    },
  });

  return mutation;
};

export const useArticleAssetsAnonymousList = () => {
  const { integration } = useApiInstances();

  const query = useInfiniteQuery({
    queryKey: ['articleAssetsAnonymousList'],
    queryFn: async ({ pageParam = 0 }) => {
      const { data: res } = await integration.get('dexAssetsPortal/search', {
        params: {
          pageSize: PAGE_SIZE_CATALOGUES,
          pageStart: pageParam,
        },
      });
      return res;
    },
    getNextPageParam: (lastPage) => {
      const nextPage = parseInt(lastPage.pageStart, 10) + 1;
      const hasNext = Math.floor(lastPage.rowTotal / lastPage.pageSize) > lastPage.pageStart;
      return hasNext ? nextPage : undefined;
    },
  });

  return query;
};

export const useReportSummary = (uuid, revision) => {
  const { integration } = useApiInstances();

  const query = useQuery({
    queryKey: ['summary', uuid],
    queryFn: async () => {
      const { data } = await integration.get(`summary/${uuid}`);
      return data;
    },
    enabled: !!revision,
  });

  return query;
};

export const useFileExports = () => {
  const { integration } = useApiInstances();

  const query = useQuery({
    queryKey: ['fileExports'],
    queryFn: async () => {
      const { data } = await integration.get('sync-data/file-exports');
      return data
        ?.map((d) => d.exports)
        ?.map((e) => e.flat())
        .flat()
        .sort((a, b) => sortLocalesAlphabetically(a.locale, b.locale));
    },
  });

  return query;
};

export const useUserRole = (accessToken) => {
  const { integration } = useApiInstances();
  const setUser = useUserStore((s) => s.setUser);

  const query = useQuery({
    queryKey: ['registration', accessToken],
    queryFn: async () => {
      const { data } = await integration.put('users/registration');
      setUser(data);
      return data.role || roles.DEFAULT;
    },
    enabled: !!accessToken,
    retry: 1,
  });

  return query;
};

export const useLocales = () => {
  const { integration } = useApiInstances();

  const query = useQuery({
    queryKey: ['locales'],
    queryFn: async () => {
      const { data } = await integration.get('locales');
      return data;
    },
  });

  return query;
};

export const useAddUserLocale = (userId) => {
  const queryClient = useQueryClient();
  const { integration } = useApiInstances();

  const mutation = useMutation({
    mutationFn: ({ userUuid, code }) => integration.put(`users/${userUuid}/locales/${code}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['userLocales', userId]);
    },
  });

  return mutation;
};

export const usePendingUsers = (user) => {
  const { integration } = useApiInstances();

  const query = useQuery({
    queryKey: ['usersList', 'pending'],
    queryFn: async () => {
      const { data } = await integration.get(`users?statuses=${userTypes.PENDING}`);
      return data;
    },
    enabled: user?.role === roles.ADMIN,
  });

  return query;
};

export const useUserLocales = (user, type) => {
  const { integration } = useApiInstances();

  const query = useQuery({
    queryKey: ['userLocales', user.uuid],
    queryFn: async () => {
      const { data } = await integration.get(`users/${user.uuid}/locales`);
      if (typeof data === 'string') {
        return null;
      }
      return data;
    },
    enabled: !!(user.role === roles.LDES && type === userTypes.ACTIVE && user?.uuid),
  });

  return query;
};

export const useCurrentUserLocales = () => {
  const { integration } = useApiInstances();
  const { uuid, role } = useUserStore((s) => s.user);

  return useQuery({
    queryKey: ['currUserLocales', uuid],
    queryFn: async () => {
      const { data } = await integration(`users/${uuid}/locales`);
      return data;
    },
    enabled: role === 'LDES',
  });
};

export const useRoleState = (initialRole) => {
  const [role, setRole] = useState(initialRole);

  useEffect(() => {
    setRole(initialRole);
  }, [initialRole]);

  return [role, setRole];
};

export const useSetUserToActive = () => {
  const queryClient = useQueryClient();
  const { integration } = useApiInstances();

  const mutation = useMutation({
    mutationFn: ({ userUuid }) => integration.put(`users/${userUuid}/status?status=${userTypes.ACTIVE}`),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['usersList', 'active']);
      await queryClient.invalidateQueries(['usersList', 'pending']);
    },
  });

  return mutation;
};

export const useEditUserRole = (user, role, locale) => {
  const queryClient = useQueryClient();
  const { integration } = useApiInstances();
  const setUserToActive = useSetUserToActive();
  const addUserLocale = useAddUserLocale(user);

  const mutation = useMutation({
    mutationFn: ({ userUuid, userRole }) => integration.put(`users/${userUuid}/role?role=${userRole}`),
    onSuccess: async (res) => {
      if (res.data.pending) {
        setUserToActive.mutate({ userUuid: user.uuid });
      }
      if (role === roles.LDES) {
        addUserLocale.mutate({ userUuid: user.uuid, code: locale });
      }
      await queryClient.invalidateQueries(['usersList', 'active']);
      await queryClient.invalidateQueries(['usersList', 'pending']);
    },
  });

  return mutation;
};

export function useUserData() {
  const { uuid } = useUserStore((s) => s.user);
  const { integration } = useApiInstances();

  const { data: user, isLoading } = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const { data } = await integration(`users/${uuid}`);
      return data;
    },
    enabled: !!uuid,
  });

  return { user, isLoading };
}

export function useUpdateUserStatus() {
  const { integration } = useApiInstances();
  const queryClient = useQueryClient();
  const { uuid } = useUserStore((s) => s.user);

  const { refetch, isSuccess } = useQuery({
    queryKey: ['user/pending'],
    queryFn: async () => {
      const response = await integration.put(
        `users/${uuid}/status?status=${userTypes.PENDING}`,
      );
      return response?.data;
    },
    enabled: false, // Ensure the query only runs on refetch
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(['currentUser']);
    }
  }, [isSuccess, queryClient]);

  return { refetch };
}

export const useUsersList = (type) => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['usersList', type],
    queryFn: async () => {
      const { data } = await integration(`users?statuses=${type}`);
      return data;
    },
  });
};

export const usePaginatedUsersList = (type, filters) => {
  // Filters: [&roles][&page][&size][&sort]
  const { integration } = useApiInstances();
  return useInfiniteQuery({
    queryKey: ['usersList', type, filters?.roles],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await integration(`users?statuses=${type}`, {
        params: { size: PAGE_SIZE_USERS, page: pageParam, ...filters },
      });
      return data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.rowTotal === lastPage.rowCount) {
        return undefined;
      }
      const nextPages = parseInt(lastPage.pageStart, 10) + 1;
      const hasNext = Math.floor(lastPage.rowTotal / lastPage.pageSize) > lastPage.pageStart;
      return hasNext ? nextPages : undefined;
    },
  });
};

export const useNotifications = (uuid) => {
  const { integration } = useApiInstances();

  return useQuery({
    queryKey: ['notifications', uuid],
    queryFn: async () => {
      const { data } = await integration(`users/${uuid}/notification-settings`);
      const res = Object.fromEntries(Object.entries(data).filter(([key]) => key.includes('notify')));
      return res;
    },
    enabled: !!uuid,
  });
};

export const useUpdateNotifications = (uuid) => {
  const { integration } = useApiInstances();

  return useMutation({
    mutationFn: (notification) => integration.put(`users/${uuid}/notification-settings`, notification),
  });
};

export const useDeleteUserLocale = () => {
  const { integration } = useApiInstances();

  return useMutation({
    mutationFn: ({ userUuid, code }) => integration.delete(`users/${userUuid}/locales/${code}`),
  });
};

export const useRevokeUserRole = () => {
  const { integration } = useApiInstances();

  return useMutation({
    mutationFn: ({ uuid }) => integration.put(`users/${uuid}/role?role=${roles.DEFAULT}`),
  });
};

export const useDeclineRoleRequest = () => {
  const { integration } = useApiInstances();

  return useMutation({
    mutationFn: ({ uuid }) => integration.put(`users/${uuid}/status?status=${userTypes.ACTIVE}`),
  });
};

export const useTransformation = (exchangeStandard, enabled = true) => {
  const { standards } = useApiInstances();

  return useQuery({
    queryKey: ['transformation', exchangeStandard],
    queryFn: async () => {
      const { data } = await standards(`${exchangeStandard}/parameters`);
      return data[exchangeStandard];
    },
    enabled: !!exchangeStandard && enabled,
  });
};

export const useTransformations = () => {
  const { standards } = useApiInstances();

  return useQuery({
    queryKey: ['transformations'],
    queryFn: async () => {
      const { data } = await standards();
      return data;
    },
  });
};

export const useLogExists = (revisionUuid) => {
  const { revisions } = useApiInstances();

  return useQuery({
    queryKey: ['logExists', revisionUuid],
    queryFn: async () => {
      const { data } = await revisions.head(`revisions/${revisionUuid}/logfile`);
      return data;
    },
    enabled: !!revisionUuid,
  });
};

export const useDownloadTransformationLog = (revisionUuid, fileName) => {
  const { revisions } = useApiInstances();

  const [progress, setProgress] = useState(false);
  const [error, setError] = useState();

  const downloadTransformationLog = async () => {
    setProgress(true);
    try {
      const response = await revisions(`revisions/${revisionUuid}/logfile`, {
        responseType: 'blob',
        onDownloadProgress() {
          setProgress(true);
        },
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError(err);
    } finally {
      setProgress(false);
    }
  };

  return { downloadTransformationLog, progress, error };
};

export const useDownloadDataExport = () => {
  const { dataExports } = useApiInstances();
  const [progress, setProgress] = useState();
  const [error, setError] = useState();

  const downloadDataExport = async (fileName, locale) => {
    setProgress(0);
    try {
      const response = await dataExports(`export/${locale}/${fileName}`, {
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        },
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError(err);
    } finally {
      setProgress(0);
    }
  };

  return { downloadDataExport, progress, error };
};

export const useStatistics = (uuid, revision) => {
  const { statistics } = useApiInstances();

  return useQuery({
    queryKey: ['statistics', uuid],
    queryFn: async () => {
      const statisticTypes = ['articlesExported', 'articlesSkipped'];
      const { data } = await statistics(`statistics/${uuid}`);
      const statisticsType = data?.filter(
        (o) => o && statisticTypes?.includes(o.statistic_type),
      );
      return statisticsType || null;
    },
    enabled: !!revision,
  });
};

export const useRequestCheck = (uuid) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['requestCheck', uuid],
    queryFn: async () => {
      const { data } = await portal('requestCheck', { params: { request: uuid } });
      return data;
    },
    enabled: !!uuid,
  });
};

export const useConfiguration = (uuid, requestCheck) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['configuration', uuid],
    queryFn: async () => {
      const { data } = await portal('request/configuration', { params: { request: uuid } });
      return data;
    },
    enabled: !!uuid && !!(requestCheck && !requestCheck.isExchangeStandardDeprecated),
  });
};

export const useContextMenu = (uuid) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['contextMenu', uuid],
    queryFn: async () => {
      const { data } = await portal('contextMenu', { params: { request: uuid } }).then((r) => ({
        ...r,
        ...{
          // convert data object to array for menu component
          data: Object.keys(r.data)
            .filter((option) => option !== 'uuid')
            .map((option) => ({ [option]: r.data[option] })),
        },
      }));
      return data;
    },
    enabled: !!uuid,
  });
};

export const useDeleteCatalogue = () => {
  const { portal } = useApiInstances();

  return useMutation({
    mutationFn: async (uuid) => portal.delete('request/catalogue', { params: { uuid } }),
  });
};

export const useArchiveCatalogue = (catalogueUuid, setOpen) => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  const {
    data: archiveCatalogue, refetch, isLoading, fetchStatus,
  } = useQuery({
    queryKey: ['status/archiveCatalogue'],
    queryFn: async () => {
      const { data } = await portal('status/archiveCatalogue', { params: { catalogue: catalogueUuid } });
      return data;
    },
    enabled: false,
  });

  useEffect(() => {
    if (archiveCatalogue?.status === 'archived') {
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['catalogue'] });
      queryClient.invalidateQueries({ queryKey: ['contextMenu'] });
      queryClient.invalidateQueries({
        queryKey: ['catalogueList'],
        refetchActive: true,
        refetchInactive: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveCatalogue]);

  return {
    archiveCatalogue, refetch, isLoading, fetchStatus,
  };
};

export const useUpdateCatalogue = (catalogueUuid) => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (c) => portal.put('request/updateCatalogueNameAndDescription', { ...c }),
    onSuccess: () => {
      queryClient.invalidateQueries(['catalogue', catalogueUuid]);
      queryClient.invalidateQueries(['revisionList', catalogueUuid]);
    },
  });

  return mutation;
};

export const useCreateCatalogue = () => {
  const { portal } = useApiInstances();

  const mutation = useMutation({
    mutationFn: ({ catalogue }) => portal.post('requestDetail', { ...catalogue }),
  });

  return mutation;
};

export const useCatalogueAvgTime = (uuid) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['catalogueAvgTime', uuid],
    queryFn: async () => {
      const { data } = await portal(`request/time?request=${uuid}`);
      return data;
    },
    enabled: !!uuid,
  });
};

export const useUpdateDescription = (uuid) => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (description) => portal.put(
      `request/updateRevisionDescription?request=${description.request}&description=${description.description}`,
      description,
    ),
    onSuccess: () => {
      queryClient.invalidateQueries(['revision', uuid]);
    },
  });

  return mutation;
};

export const useDeleteRevision = () => {
  const { portal } = useApiInstances();

  const mutation = useMutation({
    mutationFn: (uuid) => portal.delete('request/revision', { params: { uuid } }),
  });

  return mutation;
};

export const useRevisionsList = (revisionParent, enabled) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['revisionList', revisionParent],
    queryFn: async () => {
      const { data } = await portal('request/revisionList', {
        params: { uuid: revisionParent },
      });
      return data;
    },
    enabled: !!enabled,
  });
};

export const useRevision = (uuid) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['revision', uuid],
    queryFn: async () => {
      const { data } = await portal('request', { params: { uuid } });
      return data;
    },
  });
};

export const useFileLinks = (uuid) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['fileLinks', uuid],
    queryFn: async () => {
      const { data } = await portal('request/fileDownloadLinks', {
        params: {
          request: uuid,
        },
      });
      return data;
    },
    enabled: !!uuid,
  });
};

export const useEventLog = (uuid) => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['eventLogList', uuid],
    queryFn: async () => {
      const { data } = await portal('eventLog/list', { params: { request: uuid } });
      return data;
    },
    enabled: !!uuid,
  });
};

export const useUpdateStatus = (uuid) => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (st) => portal.put('status', { ...st }),
    onSuccess: () => {
      queryClient.invalidateQueries(['revision', uuid]);
    },
  });

  return mutation;
};

export const useUploadFile = (uuid, setProgress) => {
  const { portal } = useApiInstances();

  const mutation = useMutation({
    mutationFn: (formData) => portal.post('request/createRevisionWithFileUpload', formData, {
      params: { request: uuid },
      onUploadProgress(progressEvent) {
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    }),
  });

  return mutation;
};

export const useCreateRevision = () => {
  const { portal } = useApiInstances();

  return useMutation({
    mutationFn: ({ revision }) => portal.post('request/revision', revision),
  });
};

export const useSyncData = () => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['syncData'],
    queryFn: async () => {
      const { data } = await portal('syncData/search');
      return formatSyncData(data);
    },
  });
};

export const useDownloadUsageLog = () => {
  const { portal } = useApiInstances();
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState();

  const downloadUsageLog = async () => {
    setProgress(true);
    try {
      const response = await portal('usageLog', { responseType: 'blob' });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'usageLog');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setProgress(false);
    } catch (err) {
      setError(err);
      setProgress(false);
    }
  };

  return {
    downloadUsageLog, progress, setProgress, error, setError,
  };
};

export const useTransformationsRunningDetail = () => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['transformationsRunning/detail'],
    queryFn: async () => {
      const { data } = await portal('request/runningTransformations/detail');
      return data;
    },
  });
};

export const useTransformationsRunning = () => {
  const { portal } = useApiInstances();

  return useQuery({
    queryKey: ['transformationsRunning'],
    queryFn: async () => {
      const { data } = await portal('request/runningTransformations');
      return data;
    },
  });
};

export const useRemoveCollaborator = () => {
  const { portal } = useApiInstances();

  return useMutation({
    mutationFn: ({ uuid }) => portal.delete('collaboration/catalogueCollaborator', { params: { uuid } }),
  });
};

export const useUnsubscribeFromCatalogue = () => {
  const { portal } = useApiInstances();

  return useMutation({
    mutationFn: ({ uuid }) => portal.delete('collaboration/catalogueCollaborator', { params: { uuid } }),
  });
};

export const useCreateAnonymousCatalogue = () => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ catalogue }) => portal.post('requestDetail/articleAssetsAnonymous', { ...catalogue }),
    onSuccess: () => {
      queryClient.invalidateQueries(['articleAssetsAnonymousList']);
    },
  });
};

export const useDeleteAssortment = () => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) => portal.delete('request/catalogue', { params: { uuid } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['articleAssetsAnonymousList']);
    },
  });
};

export const useDeleteFileExport = () => {
  const { portal } = useApiInstances();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) => portal.delete('syncData', { params: { uuid } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['fileExports']);
    },
  });
};
