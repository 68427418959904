import { Icon } from '@chakra-ui/react';

function SharedIcon() {
  return (
    <Icon width="5" viewBox="0 0 32 32" mr="2px">
      <path
        d="M25.563 10.828q1.016 0.516 1.836 1.273t1.398 1.688 0.891 2 0.313
        2.211h-2q0-1.234-0.477-2.328t-1.289-1.906-1.906-1.289-2.328-0.477-2.328 0.477-1.906
        1.289-1.289 1.906-0.477 2.328q0 1.422-0.648 2.703t-1.789 2.125q1.016 0.516 1.836
        1.273t1.398 1.688 0.891
        2 0.313 2.211h-2q0-1.234-0.477-2.328t-1.289-1.906-1.906-1.289-2.328-0.477-2.328
        0.477-1.906 1.289-1.289 1.906-0.477 2.328h-2q0-1.141 0.313-2.211t0.891-2 1.398-1.688
        1.836-1.273q-1.141-0.844-1.789-2.125t-0.648-2.703q0-1.234 0.477-2.328t1.289-1.906 1.906-1.289
        2.328-0.477q1.422 0 2.703 0.648t2.125 1.789q0.594-1.172
        1.516-2.094t2.094-1.516q-1.141-0.844-1.789-2.125t-0.648-2.703q0-1.234
        0.477-2.328t1.289-1.906 1.906-1.289 2.328-0.477 2.328 0.477 1.906 1.289
        1.289 1.906 0.477 2.328q0 1.422-0.648 2.703t-1.789 2.125zM10 22q0.813 0
        1.547-0.32t1.273-0.859 0.859-1.266
        0.32-1.555q0-0.813-0.32-1.547t-0.859-1.273-1.273-0.859-1.547-0.32q-0.828 0-1.555 0.32t-1.266
        0.859-0.859 1.273-0.32 1.547q0 0.828 0.32 1.555t0.859 1.266 1.266 0.859 1.555 0.32zM18 6q0
        0.828 0.32 1.555t0.859 1.266 1.266 0.859 1.555 0.32q0.813 0 1.547-0.32t1.273-0.859
        0.859-1.266 0.32-1.555q0-0.813-0.32-1.547t-0.859-1.273-1.273-0.859-1.547-0.32q-0.828 0-1.555
        0.32t-1.266 0.859-0.859 1.273-0.32 1.547z"
      />
    </Icon>
  );
}

export default SharedIcon;
