import PropTypes from 'prop-types';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import WarningIcon from '../../icons/WarningIcon';
import TickIcon from '../../icons/TickIcon';

function ModalIcon(props) {
  const { type } = props;
  const pick = () => {
    switch (type) {
      case 'warning':
        return <WarningIcon />;
      case 'success':
        return <TickIcon />;
      case 'info':
        return <InfoOutlineIcon />;
      default:
        return null;
    }
  };

  return <>{pick()}</>;
}

ModalIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ModalIcon;
