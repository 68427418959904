import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ModalIcon from '../ui/ModalIcon';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';

function ModalDownloadPrices(props) {
  const {
    fileLink,
    open,
    setOpen,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      data-testid={getTestID('ModalDownloadPrices')}
      closeOnOverlayClick={false}
      isOpen={open}
      variant="info"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalIcon type="info" />
          <Text as="span">{t('info')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {t('revision.download.sure')}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            marginRight="5"
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid={getTestID('downloadOutputWithPricesButton')}
            as="a"
            variant="primary"
            href={fileLink}
            onClick={() => setOpen(false)}
            target="_blank"
          >
            {t('download')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalDownloadPrices.propTypes = {
  fileLink: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalDownloadPrices;
