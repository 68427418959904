import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import TranslatedErrorMessage from '../TranslatedErrorMessage';

function Integer({
  fieldName, fieldValue, onBlur, setValue, autoFocus = false, disabled = false,
}) {
  // Handler for manual input changes
  const handleInputChange = (value) => {
    if (Number.isNaN(value)) {
      setValue(0);
      return;
    }
    setValue(value);
  };

  return (
    <>
      <NumberInput
        autoFocus={autoFocus}
        type="number"
        name={fieldName}
        onBlur={onBlur}
        value={fieldValue}
        allowMouseWheel
        min={0}
        onChange={(_, value) => handleInputChange(value)}
        disabled={disabled}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <TranslatedErrorMessage name={fieldName} />
    </>
  );
}

Integer.propTypes = {
  // Field properties
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBlur: PropTypes.func.isRequired,

  // Other props
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  // Helpers
  setValue: PropTypes.func.isRequired,
};

export default Integer;
