import { Input } from '@chakra-ui/react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { inputTypes } from '../../../../utils/constants';
import DateField from './DateField';
import Decimal from './Decimal';
import Integer from './Integer';
import MultiLine from './MultiLine';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import Text from './Text';

function GenericInput({
  data, searchbox = false, autoFocus = false, disabled = false,
}) {
  const {
    name, type, style, values, precision,
  } = data;
  const [field, , helpers] = useField(name);

  switch (style || type) {
    case inputTypes.EMAIL:
    case inputTypes.TEXT:
      return (
        <Text
          fieldName={field?.name}
          fieldValue={field?.value}
          onBlur={field?.onBlur}
          onChange={field?.onChange}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      );

    case inputTypes.SELECT:
      return (
        <SingleSelect
          fieldName={field?.name}
          fieldValue={field?.value}
          onBlur={field?.onBlur}
          onChange={(e) => {
            helpers?.setValue(e.target.value);
            helpers?.setTouched(e.target.name, true, true);
          }}
          autoFocus={autoFocus}
          disabled={disabled}
          values={values}
          searchbox={searchbox}
        />
      );

    case inputTypes.MULTISELECT:
      return (
        <MultiSelect
          fieldName={field?.name}
          fieldValue={field?.value}
          onChange={(newValues) => {
            helpers.setValue(newValues);
          }}
          values={values}
        />
      );

    case inputTypes.MULTILINE:
      return (
        <MultiLine
          fieldName={field?.name}
          fieldValue={field?.value}
          onBlur={field?.onBlur}
          onChange={field?.onChange}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      );

    case inputTypes.DATE:
      return (
        <DateField
          fieldName={field?.name}
          fieldValue={field?.value}
          onBlur={field?.onBlur}
          onChange={field?.onChange}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      );

    case inputTypes.INTEGER:
      return (
        <Integer
          fieldName={field?.name}
          fieldValue={field?.value}
          onBlur={field?.onBlur}
          setValue={helpers?.setValue}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      );

    case inputTypes.NUMBER:
      return (
        <Decimal
          fieldName={field?.name}
          fieldValue={field?.value}
          onBlur={field?.onBlur}
          setValue={helpers?.setValue}
          dataPrecision={precision}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      );

    default:
      return <Input disabled />;
  }
}

GenericInput.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    style: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    precision: PropTypes.string,
  }).isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  searchbox: PropTypes.bool,
};

export default GenericInput;
