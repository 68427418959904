import {
  Container,
  Image,
  Flex,
  Grid,
  GridItem,
  Tooltip,
  Box,
  Avatar,
  AvatarBadge,
  useTheme,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePendingUsers } from '../../api/hooks';
import logo from '../../assets/img/geberit-logo.png';
import useUserStore from '../../services/UserStore';
import { useError } from '../../context/NetworkErrorContext';
import {
  roles,
  routes,
} from '../../utils/constants';
import Badge from '../ui/Badge';
import ClickableContainer from '../ui/ClickableContainer';
import CombinedErrorAlert from '../ui/CombinedErrorAlert';
import Footer from './Footer';
import MobileMenuDrawer from './MobileMenuDrawer';
import SidebarNavigation from './SidebarNavigation';
import UserLoggedIn from './UserLoggedIn';

function Layout(props) {
  const { children } = props;
  const user = useUserStore((s) => s.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const topNavBar = useBreakpointValue({
    base: 'mobile',
    xs: 'mobile',
    sm: 'desktop',
  });

  const { data: users } = usePendingUsers(user);

  const { errors } = useError(); // Access global errors

  return (
    <Grid
      templateAreas={{
        base: `"header" 
              "main"
              "footer"`,
        sm: `"nav header" 
            "nav main"
            "nav footer"`,
      }}
      gridTemplateRows="120px 1fr auto"
      gridTemplateColumns={{ base: '1fr', sm: '60px 1fr' }}
      minHeight="100vh"
    >
      <GridItem area="nav" display={{ base: 'none', sm: 'block' }}>
        <SidebarNavigation />
      </GridItem>
      <GridItem area="header">
        <Container variant="header">
          <Flex justifyContent="space-between" alignContent="center">
            <Image
              src={logo}
              alt="Geberit logo"
              height={{ base: '20px', sm: '25px' }}
              marginY={{ base: '6', sm: '0' }}
              marginX="0"
            />
            <Flex gap={2} alignItems="center">
              {(topNavBar !== 'mobile' && user?.role === roles.ADMIN && users?.users?.length > 0) && (
                <ClickableContainer onClick={() => navigate(`${routes.SETTINGS}/userManagement`)}>
                  <Tooltip
                    label={t('roleRequestsTooltip', { count: users.users.length })}
                    placement="top-start"
                    hasArrow
                  >
                    <span><Badge value={users.users.length} size="standard" type="info" /></span>
                  </Tooltip>
                </ClickableContainer>
              )}
              {(topNavBar === 'mobile' && user?.fullName) ? (
                <>
                  <ClickableContainer onClick={() => ((user?.role === roles.ADMIN
                    && users?.users?.length > 0)
                    ? navigate(`${routes.SETTINGS}/userManagement`)
                    : navigate(`${routes.SETTINGS}/profile`))}
                  >
                    <Avatar name={user.fullName} size="sm" bg={theme.colors.geberit[500]}>
                      {users?.users?.length > 0 && <AvatarBadge boxSize="1.25em" bg={theme.colors.success} />}
                    </Avatar>
                  </ClickableContainer>
                  <MobileMenuDrawer />
                </>
              ) : (
                <UserLoggedIn fullName={user.fullName} />
              )}
            </Flex>
          </Flex>
        </Container>
      </GridItem>
      <GridItem area="main">
        <Container variant="main" as="main" pt="4" id="mainContainer" flex="1" display="flex" flexDirection="column" minHeight="100%">
          {errors.length > 0 && <CombinedErrorAlert errors={errors} />}
          <Box flex="1">{children}</Box>
        </Container>
      </GridItem>
      <GridItem area="footer" pl="4">
        <Footer />
      </GridItem>
    </Grid>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
