/* eslint-disable consistent-return */
import { StrictMode, useEffect, useRef } from 'react';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import {
  BrowserRouter, Route, Routes,
  useLocation,
} from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import customTheme from './theme/theme';
import { msalConfig } from './config/msal';
import routesConfig from './config/routesConfig';
import { ApiProvider } from './api/ApiContext';
import { appInsights, reactPlugin } from './config/applicationInsights';
import customHistory from './config/history';
import { NetworkErrorProvider, useError } from './context/NetworkErrorContext';

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

/**
 * RouteChangeListener listens for route changes using useLocation.
 * It clears all errors in the NetworkErrorContext whenever the user navigates to a different route.
 * This ensures that errors from the previous route are cleared when a new route is loaded,
 * but errors persist while the user remains on the current route.
 */
function RouteChangeListener() {
  const location = useLocation();
  const { clearErrors } = useError();
  const previousLocation = useRef(location.pathname); // Store the previous route

  useEffect(() => {
    // Only clear errors if we have moved to a different route
    if (previousLocation.current !== location.pathname) {
      clearErrors();
      previousLocation.current = location.pathname; // Update the current route
    }
  }, [location, clearErrors]); // Trigger when location changes

  return null;
}

function AppRouting() {
  return (
    <BrowserRouter history={customHistory}>
      <RouteChangeListener />
      <Routes>
        {routesConfig.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const toast = useToast();

  useEffect(() => {
    async function initializeMsal() {
      try {
        await msalInstance.initialize();
      } catch (error) {
        toast({
          title: 'Authentication Error',
          description: 'Failed to initialize authentication services. Please try again later.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        appInsights?.trackException({
          error: new Error(`Failed to initialize authentication services. ${error.response.status}: ${error.config.url}`),
          severityLevel: 3, // Warning
          properties: {
            url: error.config.url,
            method: error.config.method,
            status: error.response.status,
            response: error.response.data,
          },
        });
      }
    }

    initializeMsal();
  }, [toast]);

  return (
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <ChakraProvider theme={customTheme}>
          {/* reactPlugin is passed to AppInsightsContext.Provider in order to provide access
            to the Application Insights instance throughout the React component tree,
            enabling the use of error boundaries and custom telemetry tracking
            within components. */}
          <AppInsightsContext.Provider value={reactPlugin}>
            <NetworkErrorProvider>
              <QueryClientProvider client={queryClient}>
                <ApiProvider>
                  <AppRouting />
                </ApiProvider>
                {/* ReactQueryDevtools is not imported when
                  process.env.NODE_ENV === 'production' */}
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </NetworkErrorProvider>
          </AppInsightsContext.Provider>
        </ChakraProvider>
      </MsalProvider>
    </StrictMode>
  );
}

export default App;
