/* eslint-disable no-extra-boolean-cast */
import {
  useState, useMemo, useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useCatalogue, useConfiguration, useRequestCheck, useTransformation,
} from '../../api/hooks';
import {
  formatDate,
  formatParametersPresetsData,
  getTestID,
} from '../../utils/utils';
import { routes } from '../../utils/constants';
import RevisionTable from '../CatalogueList/RevisionTable/RevisionTable';
import RevisionTableMobile from '../CatalogueList/RevisionTable/RevisionTableMobile';
import SecondsAsEstimatedTime from '../common/SecondsAsEstimatedTime';
import Alert from '../ui/Alert';
import Back from '../ui/Back';
import GeberitSpinner from '../ui/GeberitSpinner';
import KeyValuePairs from '../ui/KeyValuePairs';
import Tag from '../ui/Tag';
import Collaborators from './Collaborators';
import EllipsisMenu from './EllipsisMenu';
import TimerIcon from '../../icons/TimerIcon';
import PlusIcon from '../../icons/PlusIcon';

function HeadingSection({
  title = '',
  toggleVisibility,
  isVisible,
  subtitle = '',
  tags = [],
}) {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" marginBottom="15px">
      <Flex gap="2" alignItems="center">
        <Heading margin={0} alignSelf="center">
          {title}
          &nbsp;
          <Text as="span" fontWeight="normal">
            {subtitle}
          </Text>
        </Heading>
        {tags?.filter(Boolean).map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Flex>
      <Button variant="link" alignSelf="center" onClick={toggleVisibility}>
        {isVisible ? t('hide') : t('show')}
      </Button>
    </Flex>
  );
}

HeadingSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  toggleVisibility: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

function AlertMessage({
  condition,
  variant,
  message,
  link = null,
  button = null,
}) {
  if (!condition) return null;
  return (
    <Alert variant={variant}>
      <>
        {message}
        &nbsp;
        {link && <Link href={link.href}>{link.text}</Link>}
        {button && (
          <Button
            variant="inlineLink"
            href={button.href}
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        )}
      </>
    </Alert>
  );
}

AlertMessage.propTypes = {
  condition: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  button: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

function CollapsibleSection({ isVisible, items }) {
  if (!items?.length) return null;
  return (
    <Collapse in={isVisible}>
      <KeyValuePairs list={items} />
    </Collapse>
  );
}

CollapsibleSection.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

function Catalogue() {
  const [presetsVisible, setPresetsVisible] = useState(false);
  const [parametersVisible, setParametersVisible] = useState(true);
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data: catalogue, loading } = useCatalogue(uuid);
  const { data: requestCheck } = useRequestCheck(uuid);
  const { data: transformation } = useTransformation(
    catalogue?.exchangeStandard,
    !!(requestCheck && !requestCheck?.isExchangeStandardDeprecated),
  );
  const { data: configuration } = useConfiguration(uuid, requestCheck);

  const tableView = useBreakpointValue({
    base: 'mobile',
    md: 'desktop',
  });

  const isCatalogueArchived = catalogue?.statuses?.includes('archived');
  const isExchangeStandardDeprecated = !isCatalogueArchived
    && requestCheck?.isExchangeStandardDeprecated;
  // Explicit boolean conversion ensures that any non-boolean falsy values
  // (like null, 0, or undefined) are treated as `false`.
  const isRevisionForbidden = !isCatalogueArchived
    && !Boolean(requestCheck?.isExchangeStandardDeprecated)
    && !Boolean(requestCheck?.canCreateRevisionFlag);

  const [mergedCatalogues, setMergedCatalogues] = useState({});

  useEffect(() => {
    if (catalogue && configuration) {
      setMergedCatalogues({
        ...catalogue,
        parameters: configuration?.parameters || [],
        presets: configuration?.presets || [],
      });
    }
  }, [configuration, catalogue]);

  const parameterKeyValues = useMemo(
    () => catalogue?.parameters?.map((param) => ({
      key: t(`parameters.${param?.name}.label`),
      value: formatParametersPresetsData(param, t('notSpecified')),
    })) || [],
    [catalogue, t],
  );

  const presetKeyValues = useMemo(
    () => catalogue?.presets?.map((preset) => ({
      key: t(`presets.${catalogue?.exchangeStandard}.${preset?.name}.label`),
      value: formatParametersPresetsData(preset, t('notSpecified')),
    })) || [],
    [catalogue, t],
  );

  const catalogueInfoValuePairs = [
    {
      keyIcon: TimerIcon,
      key: t('estimatedTime'),
      value: (<SecondsAsEstimatedTime
        totalSeconds={catalogue?.averageTimeTakenSec || 0}
        showLabel={false}
      />),
    },
    { key: t('catalogue.createdBy'), value: catalogue?.requestedBy?.fullName || t('notSpecified') },
    { key: t('catalogue.ownedBy'), value: catalogue?.owner?.fullName || catalogue?.requestedBy?.fullName || t('notSpecified') },
    { key: t('createdOn'), value: formatDate(catalogue?.createdOn) || t('notSpecified') },
  ];

  const handleCloneClick = () => {
    if (mergedCatalogues && transformation) {
      navigate(routes.CLONE_CATALOGUE, {
        state: { catalogue: mergedCatalogues, transformation: transformation || {} },
      });
    } else {
      console.error('mergedCatalogues or transformation is null or undefined');
    }
  };

  const handleCreateRevisionClick = useCallback(() => {
    navigate(`/create-revision?parentUuid=${uuid}`);
  }, [navigate, uuid]);

  return (
    <Box data-testid={getTestID('Catalogue')}>
      <Back to="/" text={t('backToOverview')} />
      {loading && <GeberitSpinner />}
      {catalogue && (
        <>
          <Flex justifyContent="space-between">
            <Heading as="h1" size="h1">
              {catalogue?.name || t('notSpecified')}
            </Heading>
            <Flex justifyContent="flex-end" gap={2}>
              {tableView !== 'mobile' && (
                <Button
                  data-testid={getTestID('NewRevisionButton')}
                  variant="primary"
                  minW={{ base: '100%', sm: 'max-content' }}
                  onClick={handleCreateRevisionClick}
                  isDisabled={isCatalogueArchived
                    || isRevisionForbidden
                    || isExchangeStandardDeprecated}
                >
                  <PlusIcon marginRight="2" />
                  <Text as="span">{t('revision.create')}</Text>
                </Button>
              )}
              <EllipsisMenu
                catalogue={mergedCatalogues || {}}
                transformation={transformation || {}}
              />
            </Flex>
          </Flex>
          {tableView === 'mobile' && (
            <Flex justifyContent="center" marginTop="4">
              <Button
                mb={8}
                w={{ base: '100%', sm: '50%' }}
                variant="primary"
                onClick={handleCreateRevisionClick}
                isDisabled={isCatalogueArchived
                  || isRevisionForbidden
                  || isExchangeStandardDeprecated}
              >
                <PlusIcon marginRight="2" />
                <Text as="span">{t('revision.create')}</Text>
              </Button>
            </Flex>
          )}
          <Box flex={{ base: '0 0 100%', md: '0 0 60%' }}>
            <KeyValuePairs
              list={catalogueInfoValuePairs}
              templateColumns={{ base: '1fr' }}
              gridGap="4"
            />
          </Box>
          <Text
            data-testid={getTestID('DescriptionText')}
            marginBottom="8"
          >
            {catalogue?.description}
          </Text>
          <AlertMessage
            condition={isCatalogueArchived}
            variant="warning"
            message={t('catalogue.isArchived')}
          />
          <AlertMessage
            condition={isExchangeStandardDeprecated}
            variant="warning"
            message={t('catalogue.exchangeStandardNotSupported')}
            link={{ href: routes.TRANSFORMATIONS, text: t('catalogue.pleaseCreateNew') }}
          />
          <AlertMessage
            condition={isRevisionForbidden}
            variant="warning"
            message={t('catalogue.cannotCreateRevision')}
            button={{
              href: routes.CLONE_CATALOGUE,
              text: t('catalogue.pleaseDuplicate'),
              onClick: handleCloneClick,
            }}
          />
          <Divider my="12" />
          {parameterKeyValues.length > 0 && (
            <>
              <HeadingSection
                title={t('inputParameters')}
                subtitle={t(`transformations.${catalogue?.exchangeStandard}.pretty_name`)}
                tags={[
                  isExchangeStandardDeprecated && t('catalogue.deprecated'),
                  isCatalogueArchived && t('archived'),
                ].filter(Boolean)}
                toggleVisibility={() => setParametersVisible(!parametersVisible)}
                isVisible={parametersVisible}
              />
              <CollapsibleSection
                isVisible={parametersVisible}
                items={parameterKeyValues}
              />
              <Divider my="12" />
            </>
          )}
          {presetKeyValues.length > 0 && (
            <>
              <HeadingSection
                title={t('transformationPresets')}
                toggleVisibility={() => setPresetsVisible(!presetsVisible)}
                isVisible={presetsVisible}
              />
              <CollapsibleSection isVisible={presetsVisible} items={presetKeyValues} />
              <Divider my="12" />
            </>
          )}
          <Flex justifyContent="space-between" alignItems="center" fontWeight="700" marginBottom="8">
            {tableView !== 'mobile' && (
              <>
                <Heading margin={0}>
                  {t('revisions.name')}
                  &nbsp;
                  {catalogue?.revisions?.length >= 0 && (
                    <Text as="span">{`(${catalogue.revisions.length})`}</Text>
                  )}
                </Heading>
                <Button
                  variant="secondary"
                  onClick={handleCreateRevisionClick}
                  isDisabled={isCatalogueArchived
                    || isRevisionForbidden
                    || isExchangeStandardDeprecated}
                >
                  <PlusIcon marginRight="2" />
                  <Text as="span">{t('revision.create')}</Text>
                </Button>
              </>
            )}
          </Flex>
          {tableView === 'mobile' ? (
            <Flex justifyContent="center" direction="column">
              <Heading margin={0}>
                {t('revisions.name')}
                &nbsp;
                {catalogue?.revisions?.length >= 0 && (
                  <Text as="span">{`(${catalogue.revisions.length})`}</Text>
                )}
              </Heading>
              <RevisionTableMobile catalogue={catalogue} revisions={catalogue?.revisions || []} />
              <Flex justifyContent="center" marginTop="4">
                <Button
                  w={{ base: '100%', sm: '50%' }}
                  variant="secondary"
                  onClick={handleCreateRevisionClick}
                  isDisabled={isCatalogueArchived
                    || isRevisionForbidden
                    || isExchangeStandardDeprecated}
                >
                  <PlusIcon marginRight="2" />
                  <Text as="span">{t('revision.create')}</Text>
                </Button>
              </Flex>
            </Flex>
          ) : (
            <RevisionTable catalogue={catalogue} revisions={catalogue?.revisions || []} />
          )}
          <Divider my="12" borderColor="white" />
          <Collaborators catalogue={catalogue} />
        </>
      )}
      {!catalogue && !loading && !uuid && (
        <>
          <Heading as="h1" size="h1">{t('catalogue.notFound')}</Heading>
          <Text>{t('catalogue.notFoundBlurb')}</Text>
        </>
      )}
    </Box>
  );
}

export default Catalogue;
