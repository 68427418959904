import {
  Box,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Greet from './Greet';
import { routes } from '../../utils/constants';
import { getTestID } from '../../utils/utils';

function UserLoggedIn(props) {
  const { fullName } = props;
  const navigate = useNavigate();

  return (
    <Box
      data-testid={getTestID('UserLoggedIn')}
      alignItems="center"
      textAlign="right"
      onClick={() => navigate(routes.SETTINGS)}
      cursor="pointer"
    >
      <Text size="sm" margin={0}>
        <Greet name={fullName} />
      </Text>
    </Box>
  );
}

UserLoggedIn.propTypes = {
  fullName: PropTypes.string.isRequired,
};

export default UserLoggedIn;
