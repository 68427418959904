import {
  TableContainer,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import RevisionTableSmall from './RevisionTableSmall';
import RevisionTableLarge from './RevisionTableLarge';

function RevisionTable({ revisions = [] }) {
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  return (
    <TableContainer>
      {isLargeScreen
        ? <RevisionTableLarge revisions={revisions} />
        : <RevisionTableSmall revisions={revisions} />}
    </TableContainer>
  );
}

RevisionTable.propTypes = {
  revisions: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      createdOn: PropTypes.string.isRequired,
    }),
  ),
};

export default RevisionTable;
