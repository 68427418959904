import { Tooltip, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function Required() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Tooltip label={t('required')} placement="top-start" hasArrow>
      <span style={{ color: theme.colors.error }}>&nbsp;&#42;</span>
    </Tooltip>
  );
}

export default Required;
