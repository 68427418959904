import {
  Box,
  Flex,
  Heading,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFileLinks, useRevision, useTransformation } from '../../api/hooks';
import { revisionStatus } from '../../utils/constants';
import { getTestID } from '../../utils/utils';

import Alert from '../ui/Alert';
import Back from '../ui/Back';
import GeberitSpinner from '../ui/GeberitSpinner';
import Status from '../ui/Status';

import Details from './Details';
import DownloadAssetsFile from './DownloadAssetsFile';
import DownloadOutputFile from './DownloadOutputFile';
import DownloadOutputFileWithPrices from './DownloadOutputFileWithPrices';
import ElipsisMenu from './ElipsisMenu';
import NotFound from './NotFound';
import OtherRevisions from './OtherRevisions';
import TabActivities from './TabActivities/TabActivities';
import TabDetails from './TabDetails/TabDetails';

function Revision() {
  const { t } = useTranslation();
  const { uuid } = useParams();

  const {
    isSuccess,
    isLoading,
    data: revision,
  } = useRevision(uuid);

  const { data: transformation } = useTransformation(revision?.exchangeStandard);

  const { data: fileLinks } = useFileLinks(uuid);

  const outputFile = fileLinks?.downloadLinks.find((fl) => fl.fileCategory === 'outputFile');
  const assetsFiles = fileLinks?.downloadLinks.filter((fl) => fl.fileCategory === 'assetsFile');
  const summaryReport = fileLinks?.downloadLinks.find((fl) => fl.fileCategory === 'summaryReport');

  if (isLoading) {
    return <GeberitSpinner />;
  }

  return (
    <Box data-testid={getTestID('Revision')}>
      {revision && (
        <>
          <Back to={`/catalogue/${revision?.revisionParent}`} />
          <Flex justifyContent="space-between">
            <Heading as="h1" size="h1">
              {revision?.name}
            </Heading>
            <Flex gap={2}>
              {transformation?.hasPrices ? (
                <DownloadOutputFileWithPrices outputFile={outputFile} />
              ) : (
                <DownloadOutputFile outputFile={outputFile} />
              )}
              <DownloadAssetsFile assetsFiles={assetsFiles} />
              <ElipsisMenu revision={revision} />
            </Flex>
          </Flex>
          <Status type={revision?.status} />
          {revision?.status === revisionStatus.APPROVED && (
            <Alert variant="success">{t('revision.approval.approved')}</Alert>
          )}
          {revision?.status === revisionStatus.DECLINED && (
            <Alert variant="warning">{t('revision.approval.declined')}</Alert>
          )}
          <Text data-testid={getTestID('RevisionText')} marginBottom="40px">
            {revision?.description}
          </Text>
          <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" gap={16}>
            <Box flex={{ base: '0 0 100%', md: '0 0 60%' }}>
              <Tabs isLazy>
                <TabList border="none">
                  <Tab>{t('details')}</Tab>
                  <Tab>{t('activity')}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <TabDetails
                      revision={revision}
                      acceptedFileType={transformation?.fileType}
                      summaryReport={summaryReport}
                    />
                  </TabPanel>
                  <TabPanel>
                    <TabActivities revision={revision} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
            <Box flex={{ base: '0 0 100%', md: '0 0 35%' }} alignSelf={{ base: 'auto', md: 'auto' }}>
              <Details revision={revision} />
              <OtherRevisions revision={revision} />
            </Box>
          </Flex>
        </>
      )}
      {isSuccess && !revision && <NotFound />}
    </Box>
  );
}

export default Revision;
