import {
  Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup,
} from '@chakra-ui/react';
import i18next from 'i18next';
import { getTestID } from '../../utils/utils';
import { languageLabel } from '../../utils/constants';

function LanguageMenu() {
  const changeLanguage = (lng) => i18next.changeLanguage(lng);

  return (
    <Menu gutter={0}>
      <MenuButton
        as={Button}
        variant="sidebarItem"
        data-testid={getTestID('LanguageSwitch')}
      >
        {languageLabel[i18next.language] || languageLabel[i18next.resolvedLanguage]}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type="radio"
          defaultValue={i18next.resolvedLanguage}
          onChange={changeLanguage}
        >
          <MenuItemOption
            key="de"
            value="de"
            data-testid={getTestID('LanguageSwitchToDe')}
          >
            DE - Deutsch
          </MenuItemOption>
          <MenuItemOption
            key="en"
            value="en"
            data-testid={getTestID('LanguageSwitchToEn')}
          >
            EN - English
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export default LanguageMenu;
