import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getTestID } from '../../../utils/utils';

/*
  This component is used to display the translated form validation error messages.
  It ensures that error messages are updated automatically when the form is touched
  or the language changes.
 */
function TranslatedErrorMessage({ name }) {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext();
  const [error, setError] = useState('');

  useEffect(() => {
    const translateError = () => {
      if (touched[name] && errors[name]) {
        // Handles interpolated values in error messages
        if (typeof errors[name] === 'object') {
          setError(t(errors[name].key, errors[name].params));
        } else {
          setError(t(errors[name]));
        }
      } else {
        setError('');
      }
    };

    translateError();
  }, [t, errors, touched, name, error]);

  if (!error) {
    return null;
  }

  return <div data-testid={getTestID('ErrorMessage')} className="error-message" style={{ color: '#f00' }}>{error}</div>;
}

TranslatedErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TranslatedErrorMessage;
