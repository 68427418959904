import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

function SidebarButton({
  icon, label, isActive, onClick, testID,
}) {
  return (
    <Button
      data-testid={getTestID(testID)}
      leftIcon={icon}
      variant="sidebarItem"
      justifyContent="flex-start"
      isActive={isActive}
      onClick={onClick}
    >
      <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{label}</span>
    </Button>
  );
}

SidebarButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  testID: PropTypes.string.isRequired,
};

export default SidebarButton;
