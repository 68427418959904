import { extendTheme } from '@chakra-ui/react';
import Box from './components/box';
import Button from './components/buttons';
import Container from './components/container';
import Divider from './components/divider';
import FileExports from './components/file-exports';
import Heading from './components/headings';
import { Input } from './components/input';
import Label from './components/label';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import NumberInput from './components/number-input';
import Table from './components/table';
import Tabs from './components/tabs';
import Text from './components/text';
import Textarea from './components/textarea';
import colors from './foundation/colours';
import breakpoints from './foundation/breakpoints';
import fontSizes from './foundation/fontSizes';
import sizes from './foundation/sizes';
import Checkbox from './components/checkbox';

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        color: 'black',
      },
      html: {
        fontSize: '14px',
        mozOsxFontSmoothing: 'grayscale',
        webkitFontSmoothing: 'antialiased',
        outline: 'none',
      },
      'option[disabled]': {
        color: 'gray.200',
      },
      '.footerContainer': {
        height: '63px',
        margin: '0 auto',
        borderColor: 'gray.200',
        span: {
          maxW: 'container.xl',
        },
      },
    },
  },
  fonts: {
    heading: 'aktiv, Arial, sans-serif',
    body: 'aktiv, Arial, sans-serif',
  },
  fontSizes,
  breakpoints,
  colors,
  sizes,
  components: {
    Text,
    Table,
    Button,
    Heading,
    Link,
    Input,
    Textarea,
    NumberInput,
    Modal,
    Menu,
    Tabs,
    Label,
    FileExports,
    Divider,
    Box,
    Container,
    Checkbox,
  },
});

export default customTheme;
