import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import PropTypes from 'prop-types';

import { useCatalogueList } from '../../api/hooks';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import { getTestID } from '../../utils/utils';
import GeberitSpinner from '../ui/GeberitSpinner';
import CatalogueTable from './CatalogueTable/CatalogueTable';
import Filters from './Filters';
import FiltersMobile from './FiltersMobile';
import { useFilterState } from './hooks';

function MobileHeadingSection({
  heading, hasFilters, subHeadingSmall, clearFilters,
}) {
  const { t } = useTranslation();

  return (
    <Flex justify="space-between" align="top" width="100%" paddingY={6}>
      <Flex direction="column" justify="flex-end">
        <Heading as="h1" size="h2" m="0">{heading}</Heading>
        {hasFilters && (
          <Flex justify="space-between" align="center" width="100%" padding={0} gap={2}>
            <Heading as="h6" size="h6" color="gray" margin={0}>{subHeadingSmall}</Heading>
            <Button variant="link" onClick={clearFilters} padding={0}>
              {t('catalogues.resetFilters')}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

MobileHeadingSection.propTypes = {
  heading: PropTypes.string.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  subHeadingSmall: PropTypes.string.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

function NoCataloguesMessage({
  clearFilters, noCataloguesForUser, noCataloguesReturnedByFilter,
}) {
  const { t } = useTranslation();

  return (
    <>
      {noCataloguesForUser && (
        <>
          <Text data-testid={getTestID('NoCatalogueAvailableText')} as="span">
            {t('catalogues.none')}
          </Text>
          <Link to="/transformations" as={NavLink}>
            {t('catalogue.create')}
          </Link>
        </>
      )}
      {noCataloguesReturnedByFilter && (
        <>
          <Text as="span">{t('catalogues.noneForFilter')}</Text>
          <Button variant="link" onClick={clearFilters}>
            {t('catalogues.resetFilters')}
          </Button>
        </>
      )}
    </>
  );
}

NoCataloguesMessage.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  noCataloguesForUser: PropTypes.bool.isRequired,
  noCataloguesReturnedByFilter: PropTypes.bool.isRequired,
};

function CatalogueList() {
  const { t } = useTranslation();
  const [uuid] = useLocalStorage('uuid');
  const {
    filters, setStandards, setLocales, setStatuses,
  } = useFilterState();
  const {
    isLoading, isSuccess, data, fetchNextPage, hasNextPage, isFetchingNextPage,
  } = useCatalogueList(uuid, filters);

  const [tempStandards, setTempStandards] = useState(filters.standards);
  const [tempLocales, setTempLocales] = useState(filters.locales);
  const [tempStatuses, setTempStatuses] = useState(filters.statuses);

  const clearFilters = () => {
    setStandards([]);
    setLocales([]);
    setStatuses([]);
    setTempStandards([]);
    setTempLocales([]);
    setTempStatuses([]);
  };

  const cataloguesShown = data?.pages[0] !== '' && data?.pages?.reduce((count, curr) => count + curr.catalogues.length, 0);
  const cataloguesTotal = data?.pages[0]?.rowTotal;
  const hasCatalogues = isSuccess && data?.pages[0]?.catalogues?.length > 0;
  const noCataloguesForUser = isSuccess && cataloguesTotal === '0';
  const noCataloguesReturnedByFilter = isSuccess && data?.pages[0] === '';

  const cataloguesCount = `${cataloguesShown} ${t('catalogues.pagination.of')} ${cataloguesTotal}`;
  const heading = hasCatalogues ? `${t('catalogues.heading')} (${cataloguesCount})` : t('catalogues.heading');
  const hasFilters = filters && Object.values(filters)?.some((arr) => arr.length > 0);
  const totalFilterCount = filters
    && Object.values(filters)?.reduce((acc, arr) => acc + arr.length, 0);

  const headingSmall = hasCatalogues ? `${t('catalogues.headingSmall')} (${cataloguesCount})` : t('catalogues.heading');
  const subHeadingSmall = hasFilters ? `${t('Active filters')} (${totalFilterCount})` : '';

  const tableView = useBreakpointValue({
    base: 'mobile',
    xs: 'mobile',
    sm: 'desktop',
    md: 'desktop',
  });

  return (
    <Box width="inherit">
      {tableView === 'mobile' ? (
        <Flex justify="space-between" align="center" width="100%" padding={0} gap={2}>
          <MobileHeadingSection
            heading={headingSmall}
            hasFilters={hasFilters}
            subHeadingSmall={subHeadingSmall}
            clearFilters={clearFilters}
          />
          <FiltersMobile
            catalogueUuid={uuid}
            filters={filters}
            setStandards={setStandards}
            setLocales={setLocales}
            setStatuses={setStatuses}
            clearFilters={clearFilters}
            tempStandards={tempStandards}
            setTempStandards={setTempStandards}
            tempLocales={tempLocales}
            setTempLocales={setTempLocales}
            tempStatuses={tempStatuses}
            setTempStatuses={setTempStatuses}
          />
        </Flex>
      ) : (
        <>
          <Heading data-testid={getTestID('H1HeaderForCatalogueOverview')} as="h1" size="h1">
            {heading}
          </Heading>
          <Filters
            catalogueUuid={uuid}
            filters={filters}
            setStandards={setStandards}
            setLocales={setLocales}
            setStatuses={setStatuses}
          />
        </>
      )}
      <NoCataloguesMessage
        clearFilters={clearFilters}
        noCataloguesForUser={noCataloguesForUser}
        noCataloguesReturnedByFilter={noCataloguesReturnedByFilter}
      />
      {isLoading && <GeberitSpinner />}
      {hasCatalogues && <CatalogueTable data={data} />}
      <Flex>
        <Spacer />
        {hasNextPage && (
          <Button
            onClick={fetchNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
            leftIcon={<ArrowDownIcon />}
          >
            {isFetchingNextPage ? t('loadingMore') : t('loadMore')}
          </Button>
        )}
        <Spacer />
        {hasCatalogues && (
          <Text data-testid={getTestID('CatalogueCounter')} alignSelf="center">
            {cataloguesCount}
          </Text>
        )}
      </Flex>
    </Box>
  );
}

export default CatalogueList;
