import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import UserSummary from '../ui/UserSummary';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import { useRemoveCollaborator } from '../../api/hooks';

function ModalRemoveCollaborator(props) {
  const {
    open,
    setOpen,
    catalogueUuid,
    collaborator,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: removeCollaborator } = useRemoveCollaborator();

  const handleRemoveCollaborator = (uuid) => {
    removeCollaborator(
      { uuid },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['collaborators', catalogueUuid]);
          setOpen(false);
        },
      },
    );
  };

  return (
    <Modal
      data-testid={getTestID('CollaboratorRemove')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('collaborator.remove')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <UserSummary user={collaborator.user} />
          <Text margin={0}>{t('collaborator.removeSure')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            mr={3}
            onClick={() => handleRemoveCollaborator(collaborator?.uuid)}
          >
            {t('yesDelete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalRemoveCollaborator.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  catalogueUuid: PropTypes.string.isRequired,
  collaborator: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    user: PropTypes.shape({
      uuid: PropTypes.string,
      role: PropTypes.string,
    }),
  }).isRequired,
};

export default ModalRemoveCollaborator;
