import { Icon } from '@chakra-ui/react';

function CloseIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M9,1 L9,7 L15,7 L15,9 L9,9 L9,15 L7,15 L7,9 L1,9 L1,7 L7,7 L7,1 L9,1 Z"
        fill="currentColor"
        transform="translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000) "
      />
    </Icon>
  );
}

export default CloseIcon;
