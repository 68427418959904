import {
  Box,
  Button,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ModalDeleteRevision from './ModalDeleteRevision';
import ModalUpdateDescription from './ModalUpdateDescription';
import EllipsisIcon from '../../icons/EllipsisIcon';
import { getTestID } from '../../utils/utils';
import { useContextMenu, useUpdateDescription } from '../../api/hooks';

function ElipsisMenu(props) {
  const { revision } = props;
  const { uuid } = revision;
  const { t } = useTranslation();

  const [deleteRevision, setDeleteRevision] = useState(false);
  const [descriptionUpdate, setDescriptionUpdate] = useState(false);

  const { data: contextMenu } = useContextMenu(uuid);

  const { mutate: updateDescription } = useUpdateDescription(uuid);

  const handleUpdate = (description) => {
    updateDescription(description);
  };

  return (
    <Box data-testid={getTestID('Menu')}>
      <Box marginLeft="2">
        <ChakraMenu>
          <MenuButton
            data-testid={getTestID('RevisionContextMenu')}
            as={Button}
            variant="filledRound"
          >
            <EllipsisIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => setDescriptionUpdate(true)}
              isDisabled={!contextMenu?.filter((item) => item.updateRevisionDescription)?.[0]}
            >
              {t('revision.changeDescription')}
            </MenuItem>
            <MenuItem
              data-testid={getTestID('deleteRevisionButton')}
              color="error"
              _hover={{ background: 'errorTransparency10' }}
              onClick={() => setDeleteRevision(true)}
              isDisabled={!contextMenu?.filter((item) => item.deleteRevision)?.[0]}
            >
              {t('delete')}
            </MenuItem>
          </MenuList>
        </ChakraMenu>
      </Box>
      <ModalDeleteRevision
        revision={revision}
        open={deleteRevision}
        setOpen={setDeleteRevision}
      />
      <ModalUpdateDescription
        handleUpdate={handleUpdate}
        revision={revision}
        open={descriptionUpdate}
        setOpen={setDescriptionUpdate}
      />
    </Box>
  );
}

ElipsisMenu.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

export default ElipsisMenu;
