import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MultiSelectDropdown from '../../../ui/MultiSelectDropdown';

function MultiSelect({
  fieldValue, onChange, values,
}) {
  const { t, i18n } = useTranslation();

  return (
    <MultiSelectDropdown
      onChange={onChange}
      options={values}
      selectedValues={fieldValue}
      getOptionLabel={(o) => (i18n.exists(o) ? t(o) : o)}
      label={t('inputSelectValues.select')}
      closeOnSelect={false}
      maxWidth="100%"
      matchWidth
    />
  );
}

MultiSelect.propTypes = {
  // Field properties
  fieldValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,

  // Data properties
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MultiSelect;
