import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ModalIcon from '../ui/ModalIcon';
import CloseIcon from '../../icons/CloseIcon';

function ModalDeleteAssets(props) {
  const {
    open,
    setOpen,
    handleDeleteAssortment,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      data-testid="ModalDeleteAssets"
      closeOnOverlayClick={false}
      isOpen={open}
      variant="warning"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalIcon type="warning" />
          <Text as="span">{t('warning')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {t('dexAssetsPortal.delete.sure')}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            marginRight="5"
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDeleteAssortment();
              setOpen(false);
            }}
          >
            {t('yes')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalDeleteAssets.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleDeleteAssortment: PropTypes.func.isRequired,
};

export default ModalDeleteAssets;
