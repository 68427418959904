import { Box } from '@chakra-ui/react';

import { getTestID } from '../../../utils/utils';
import UserList from './UserList/UserList';

function TabUserManagement() {
  return (
    <Box data-testid={getTestID('TabUserManagement')}>
      <UserList />
    </Box>
  );
}

export default TabUserManagement;
