import { Icon } from '@chakra-ui/react';

function TickIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <polygon
        fill="currentColor"
        points="12.2928932 3.29289322 13.7071068 4.70710678 6 12.4142136 2.29289322 8.70710678 3.70710678 7.29289322 6 9.585"
      />
    </Icon>
  );
}

export default TickIcon;
