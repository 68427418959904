import { create } from 'zustand';

/*
 * The msal flag navigateToLoginRequestUrl does not work in combination with our current set up.
 * The useInitRouteStore keeps track of the initial page the user was on and
 * redirects to it after login.
*/
const useInitRouteStore = create((set) => ({
  initRoute: '',
  setInitRoute: (initRoute) => set({ initRoute }),
}));

export default useInitRouteStore;
