import {
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID, sortAlphabetically } from '../../utils/utils';
import DownIcon from '../../icons/DownIcon';
import CustomCheckIcon from '../../icons/CustomCheckIcon';
import CustomDashIcon from '../../icons/CustomDashIcon';

function MultiSelectDropdown({
  options, onChange, selectedValues, getOptionLabel, label,
  maxWidth = '',
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showGradient, setShowGradient] = useState(false);
  const [dynamicMatchWidth, setDynamicMatchWidth] = useState(false);
  const menuListRef = useRef(null);
  const menuButtonRef = useRef(null);
  const { t } = useTranslation();

  const handleSelect = (value) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value];

    onChange(newSelectedValues);
  };

  const handleSelectAll = () => {
    if (selectedValues.length === options.length) {
      onChange([]); // Unselect all
    } else {
      onChange([...options]); // Select all
    }
  };

  const getButtonLabel = () => ((Array.isArray(selectedValues)
    && selectedValues?.map((es) => getOptionLabel(es))
      .filter((es) => es)
      .join(', ')) || label);

  const handleScroll = () => {
    if (menuListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuListRef.current;
      setShowGradient(scrollHeight > clientHeight && scrollTop + clientHeight < scrollHeight);
    }
    return true;
  };

  // Helper function to process selectedValues and determine Checkbox states
  const getCheckboxState = (selectedItems, optionsLength) => {
    const filteredValues = selectedItems.filter((value) => value !== '');
    const isFullySelected = filteredValues.length === optionsLength;
    const isPartiallySelected = filteredValues.length > 0 && filteredValues.length < optionsLength;

    let icon;
    if (isFullySelected) {
      icon = <CustomCheckIcon />;
    } else if (isPartiallySelected) {
      icon = <CustomDashIcon />;
    } else {
      icon = undefined; // default unselected state
    }

    return {
      icon,
      isChecked: isFullySelected,
      isIndeterminate: isPartiallySelected,
    };
  };

  // Get the checkbox state by passing selectedValues and options.length
  const checkboxState = getCheckboxState(selectedValues, options.length);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const currentMenuList = menuListRef.current;

    if (currentMenuList) {
      handleScroll(); // Check initial scroll position
      currentMenuList.addEventListener('scroll', handleScroll);
      return () => {
        currentMenuList.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (menuButtonRef.current && menuListRef.current) {
      const buttonWidth = menuButtonRef.current.offsetWidth;
      const itemWidths = Array.from(menuListRef.current.children).map((child) => child.offsetWidth);
      const maxItemWidth = Math.max(...itemWidths);

      if (maxItemWidth < buttonWidth) {
        setDynamicMatchWidth(true);
      } else {
        setDynamicMatchWidth(false);
      }
    }
  }, [options]);

  return (
    <Box width={maxWidth || '250px'}>
      <Menu
        isOpen={isOpen}
        onClose={onClose}
        gutter={0}
        closeOnSelect={false}
        matchWidth={dynamicMatchWidth}
      >
        <MenuButton
          data-testid={getTestID('MultiselectDropDown')}
          ref={menuButtonRef}
          as={Button}
          onClick={onOpen}
          variant="multiselect"
          rightIcon={<DownIcon className={`${isOpen ? 'rotateUp' : 'rotateDown'}`} />}
          width="100%"
          borderColor="gray.900"
        >
          {getButtonLabel()}
        </MenuButton>
        <Box position="relative">
          <MenuList
            ref={menuListRef}
            maxHeight="250px"
            overflowY="auto"
            pb={4}
            pt="0"
          >
            <MenuItem
              display="flex"
              alignItems="center"
              position="sticky"
              top="0"
              bg="white"
              zIndex="1"
              borderBottom="1px solid"
              borderColor="gray.200"
              onClick={handleSelectAll}
            >
              <Box onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  variant="selectAll"
                  icon={checkboxState.icon}
                  isChecked={checkboxState.isChecked}
                  isIndeterminate={checkboxState.isIndeterminate}
                  onChange={handleSelectAll}
                  mr={2}
                />
              </Box>
              {selectedValues.length === options.length ? t('unselectAll') : t('selectAll')}
            </MenuItem>
            {options
              .sort(sortAlphabetically)
              .map((option) => (
                <MenuItem
                  data-testid={getTestID('MultiselectListItem')}
                  key={option}
                  onClick={() => handleSelect(option)}
                  display="flex"
                  alignItems="center"
                >
                  <Box onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      icon={<CustomCheckIcon />}
                      isChecked={selectedValues?.includes(option)}
                      onChange={() => handleSelect(option)}
                      mr={2}
                    />
                  </Box>
                  {getOptionLabel(option)}
                </MenuItem>
              ))}
            {showGradient && (
              <Box
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                height="30px"
                bgGradient="linear(to-t, white, rgba(255, 255, 255, 0.3))"
                pointerEvents="none"
              />
            )}
          </MenuList>
        </Box>
      </Menu>
    </Box>
  );
}

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
};

export default MultiSelectDropdown;
