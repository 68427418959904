import { createIcon } from '@chakra-ui/icons';

const InfoIcon = createIcon({
  displayName: 'Info',
  viewBox: '0 0 20 20',

  path: (
    <path
      d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0zm0 1c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm1 8v7H9V9h2zm0-4v2H9V5h2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export default InfoIcon;
