import PropTypes from 'prop-types';
import { useTheme } from '@chakra-ui/react';

const getSize = (size) => {
  switch (size) {
    case 'big':
      return 24;
    case 'standard':
      return 20;
    case 'small':
      return 10;
    default:
      return 20;
  }
};

const getColor = (type, theme) => {
  switch (type) {
    case 'info':
      return { backgroundColor: theme.colors.geberit[500], color: 'white' };
    case 'warning':
      return { backgroundColor: theme.colors.waring, color: 'white' };
    case 'error':
      return { backgroundColor: theme.colors.error, color: 'white' };
    default:
      return { backgroundColor: theme.colors.gray[500], color: 'black' };
  }
};

function Badge({ value, size, type }) {
  const theme = useTheme();
  const sizePx = getSize(size);
  const colors = getColor(type, theme);

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `${sizePx}px`,
    height: `${sizePx}px`,
    borderRadius: '50%',
    backgroundColor: colors.backgroundColor,
    color: colors.color,
    fontSize: `${sizePx / 2}px`,
    textAlign: 'center',
  };

  return <div style={style}>{value}</div>;
}

Badge.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'standard', 'big']).isRequired,
  type: PropTypes.oneOf(['info', 'warning', 'error', 'default']).isRequired,
};

export default Badge;
