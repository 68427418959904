import { Icon } from '@chakra-ui/react';

function UnknownProductIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M6,8H5V7.43a1.73,1.73,0,0,1,.48-1.26l.91-.9A1.54,1.54,0,0,0,7,4c0-.83-.68-1-1.25-1S4.5,3.17,4.5,4.5h-1C3.5,2.91,4.32,2,5.75,2A2,2,0,0,1,8,4a2.54,2.54,0,0,1-.93,2l-.89.87A.74.74,0,0,0,6,7.43Z"
        fill="currentColor"
      />
      <rect x="5" y="9" width="1" height="1" fill="currentColor" />
      <path
        d="M15.35,14.65,10.58,9.87a6,6,0,1,0-.71.71l4.78,4.77ZM6,11a5,5,0,1,1,5-5A5,5,0,0,1,6,11Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default UnknownProductIcon;
