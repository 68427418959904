import { Icon } from '@chakra-ui/react';

function ProductImagesIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M16 4v12H4v-1h11V4h1zm-2 10H0V0h14v14zM1 1v6.34l2.5-2.55 2.49 2.49 4.51-4.49 2.5 2.5V1H1zm12 12V6.71l-2.5-2.5-3.8 3.78 1.16 1.16-.71.71-3.64-3.64-2.5 2.55v4.24H13z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default ProductImagesIcon;
