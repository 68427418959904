/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  Button,
  List,
  ListItem,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../utils/utils';
import Alert from './Alert';

function CombinedErrorAlert({ errors, variant = 'error' }) {
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  const toggleDetails = () => setShowDetails(!showDetails);

  if (errors.length === 1) {
    const error = errors[0];
    return (
      <Box mt="4" mb="8">
        <Alert variant={variant} data-testid={getTestID('Error')}>
          <strong>{`${error.description}: `}</strong>
          {`${error.message}`}
        </Alert>
      </Box>
    );
  }

  return (
    <Box mt="4" mb="8">
      <Alert variant={variant}>
        <Box display="flex" alignItems="center" justifyContent="space-between" w="100%">
          <AlertTitle>{t('multipleErrorsTitle')}</AlertTitle>
          <Button size="sm" onClick={toggleDetails} color="black" variant="link" p={0}>
            {showDetails ? t('hideDetails') : t('showDetails')}
          </Button>
        </Box>
        <Collapse in={showDetails}>
          <Box mt="4">
            <List spacing={3}>
              {errors.map((error, index) => (
                <ListItem key={index + error.status}>
                  <AlertDescription>
                    <strong>{`${error.description}: `}</strong>
                    {`${error.message}`}
                  </AlertDescription>
                </ListItem>
              ))}
            </List>
          </Box>
        </Collapse>
      </Alert>
    </Box>
  );
}

CombinedErrorAlert.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      status: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  variant: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
};

export default CombinedErrorAlert;
