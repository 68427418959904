import {
  Box,
  Button,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';

import { routes } from '../../../utils/constants';
import RightArrowIcon from '../../../icons/RightArrowIcon';
import { getTestID } from '../../../utils/utils';

function SuccessPage() {
  const {
    locale,
    articleNumber,
  } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const countryName = t(`locales.${locale}`);

  const handleClick = () => {
    navigate(routes.DEX_ASSETS_PORTAL);
  };

  return (
    <Box data-testid={getTestID('DexAssetsConfirmation')}>
      <VStack spacing="8" align="flex-start">
        <VStack align="flex-start">
          <Text>
            {t('dexAssetsPortal.generationInProgress', { article: articleNumber, locale: countryName })}
          </Text>
          <Text>
            {t('dexAssetsPortal.gatherFiles')}
          </Text>
        </VStack>
        <Text>
          {t('dexAssetsPortal.createAndDownload')}
        </Text>
      </VStack>
      <Flex
        justifyContent="flex-start"
        marginTop="8"
      >
        <Button
          variant="link"
          paddingLeft="0"
          onClick={handleClick}
          leftIcon={<RightArrowIcon fill="geberit.500" />}
        >
          {t('dexAssetsPortal.open')}
        </Button>
      </Flex>
    </Box>
  );
}

export default SuccessPage;
