/* eslint-disable no-underscore-dangle */
import {
  Button,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';
import Alert from './Alert';
import Back from './Back';

function Error({
  error, retry = null, backTo = null, backText = null, retryParams = null, margin = null,
}) {
  const { t } = useTranslation();

  return (
    <Box data-testid={getTestID('Error')} margin={margin}>
      {backTo && (
        <Back text={backText || t('back')} to={backTo || '/'} paddingLeft={0} />
      )}
      <Alert variant="error">
        {error?.response?.data?._errorMessage
          || error?.message
          || JSON.stringify(error)}
      </Alert>
      {retry && (
        <Button variant="primary" onClick={() => retry({ ...retryParams })}>
          {t('tryAgain')}
        </Button>
      )}
    </Box>
  );
}

Error.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({
        _errorMessage: PropTypes.string,
      }),
    }),
    message: PropTypes.string,
  }).isRequired,
  retry: PropTypes.string,
  backTo: PropTypes.string,
  backText: PropTypes.string,
  retryParams: PropTypes.string,
  margin: PropTypes.string,
};

export default Error;
