import { cloneElement, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import {
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import PropTypes from 'prop-types';

import useInitRouteStore from '../../services/InitRouteStore';
import { useUserRole } from '../../api/hooks';
import { routes } from '../../utils/constants';

function ProtectedRoute({ children }) {
  const [accessToken] = useLocalStorage('accessToken');
  const redirectUri = localStorage?.getItem('redirect');
  const location = useLocation();
  const navigate = useNavigate();
  const setInitRoute = useInitRouteStore((state) => state.setInitRoute);
  setInitRoute(location);
  const {
    data: role, isLoading, isError, error,
  } = useUserRole(accessToken);

  useEffect(() => {
    if (isError) {
      console.error(error);
      navigate(routes.WELCOME);
    }
  }, [isError, error, navigate]);

  if (!accessToken) {
    return (<UnauthenticatedTemplate><Navigate to="/welcome" replace /></UnauthenticatedTemplate>);
  }
  if (!isLoading && role && redirectUri) {
    localStorage.removeItem('redirect');
    window.location.href = decodeURI(redirectUri);
  }

  return (
    (!isLoading && role) && <>{cloneElement(children, { role })}</>
  );
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
