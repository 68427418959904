import { Icon } from '@chakra-ui/react';

function PlusIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <polygon
        points="15 8 8 8 8 1 7 1 7 8 0 8 0 9 7 9 7 16 8 16 8 9 15 9 15 8"
        fill="currentColor"
      />
    </Icon>
  );
}

export default PlusIcon;
