import {
  Box, Text, Image, VStack, Heading,
  useBreakpointValue, Flex, Menu,
  MenuButton, MenuList, MenuOptionGroup,
  MenuItemOption, Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import image from '../../assets/img/img-einblicke-acanto.jpg';
import logo from '../../assets/img/geberit-logo.png';
import { shortLanguageLabel } from '../../utils/constants';
import { getTestID } from '../../utils/utils';
import Footer from '../Layout/Footer';
import SignInButton from './SignInButton';

function LanguageSwitch() {
  const changeLanguage = (lng) => i18next.changeLanguage(lng);

  return (
    <Flex
      position="fixed"
      flexDirection="column"
      width="60px"
      minHeight="100vh"
      backgroundColor="gray.50"
      zIndex={10}
      justifyContent="flex-end"
      alignItems="center"
      pb={14}
    >
      <Menu>
        <MenuButton as={Button} variant="sidebarItem" data-testid={getTestID('LanguageSwitch')}>
          {shortLanguageLabel[i18next.language] || shortLanguageLabel[i18next.resolvedLanguage]}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type="radio"
            defaultValue={i18next.resolvedLanguage}
            onChange={changeLanguage}
          >
            <MenuItemOption key="de" value="de" data-testid={getTestID('LanguageSwitchToDe')}>
              DE - Deutsch
            </MenuItemOption>
            <MenuItemOption key="en" value="en" data-testid={getTestID('LanguageSwitchToEn')}>
              EN - English
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
}

function LogoAndHeading({ isMobile = false }) {
  const { t } = useTranslation();
  return (
    <VStack spacing={8} w="full" alignItems="baseline">
      <Image data-testid={getTestID('Logo')} src={logo} alt="Geberit" height="25px" m={6} />
      {isMobile && (
        <Text as="h1" fontSize="40px" fontWeight="bold" textTransform="uppercase" noOfLines={3}>
          {t('welcomeHeading')}
        </Text>
      )}
    </VStack>
  );
}

LogoAndHeading.propTypes = {
  isMobile: PropTypes.bool,
};

function LeftSection({ isMobile = false }) {
  const { t } = useTranslation();
  return (
    <VStack
      position="absolute"
      bg="white"
      left="0"
      top="0"
      bottom="0"
      minW="fit-content"
      justifyContent="space-between"
      p={8}
      zIndex={2}
      ml={{ base: 0, sm: '60px' }}
    >
      <LogoAndHeading isMobile={isMobile} />
      <VStack spacing={4} maxW="300px" w="full" p={2}>
        <SignInButton />
        <Text fontSize="md">{t('onlyAvailable')}</Text>
        <Box w="100%">
          <Footer />
        </Box>
      </VStack>
    </VStack>
  );
}

LeftSection.propTypes = {
  isMobile: PropTypes.bool,
};

function RightSection() {
  const { t } = useTranslation();
  return (
    <Box position="absolute" right="0" top="0" bottom="0" width="80%" zIndex={1}>
      <Image src={image} alt="Welcome Image" objectFit="cover" width="100%" height="100%" />
      <Box
        position="fixed"
        top={{
          base: 0, sm: '65%', md: '70%', lg: '70%',
        }}
        left={{
          base: 0, sm: '53%', md: '42%', lg: '32%',
        }}
        zIndex={2}
        color="black"
        textAlign="left"
      >
        <Heading data-testid={getTestID('welcomeText')} fontSize="46px" fontWeight="normal" textTransform="uppercase">
          {t('dex')}
          <Text fontWeight="bold" fontSize="46px">
            {t('welcome')}
          </Text>
        </Heading>
      </Box>
    </Box>
  );
}

function Welcome() {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <Box data-testid={getTestID('Welcome')} position="relative" height="100vh" overflow="hidden">
      {!isMobile && <LanguageSwitch />}
      <LeftSection isMobile={isMobile} />
      {!isMobile && <RightSection />}
    </Box>
  );
}

export default Welcome;
