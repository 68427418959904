import { input } from './input';

const NumberInput = {
  variants: {
    outline: {
      field: input,
    },
  },
};

export default NumberInput;
