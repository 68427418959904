import { Icon } from '@chakra-ui/react';

function MenuIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect x="1" y="3" width="14" height="1" fill="currentColor" />
      <rect x="1" y="8" width="14" height="1" fill="currentColor" />
      <rect x="1" y="13" width="14" height="1" fill="currentColor" />
    </Icon>
  );
}

export default MenuIcon;
