import * as Yup from 'yup';

const transformationsSchema = Yup.object().shape({
  exchangeStandard: Yup.string().required('inputValidationMessages.required'),
});

const assortmentSchema = Yup.object().shape({
  name: Yup.string().required('inputValidationMessages.required').max(225),
  description: Yup.string().max(225),
  articleList: Yup.string()
    .when('assortment', {
      is: (assortment) => !(assortment !== 'custom'),
      then: () => Yup.string()
        .required('inputValidationMessages.requiredArticleList'),
    }),
});

export const duplicationValidations = [assortmentSchema];

export default [transformationsSchema, assortmentSchema];
