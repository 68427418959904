export const input = {
  borderRadius: 0,
  fontSize: '14px',
  _hover: {
    borderColor: 'black',
  },
  _focus: {
    boxShadow: 'none',
    borderColor: 'geberit.500',
  },
  _disabled: {
    background: 'gray.350',
    borderColor: 'gray.350',
    _hover: {
      borderColor: 'gray.350',
    },
  },
  _invalid: {
    boxShadow: 'none',
    borderColor: 'error',
    _focus: {
      borderColor: 'error',
    },
  },
};

export const Input = {
  variants: {
    outline: {
      field: input,
    },
  },
};
