import { useState } from 'react';

import FormStepper from './FormStepper';
import ArticleInput from './FormSteps/ArticleInput';
import AvailabilityList from './FormSteps/AvailabilityList';
import validationSchema from './validationSchema';

const initialValues = {
  article: '',
};

function ArticleAvailability() {
  const [listVisible, setListVisible] = useState(false);

  return (
    <FormStepper
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formValues) => formValues}
      duplicateFormButton={listVisible}
    >
      <ArticleInput />
      <AvailabilityList onListVisibilityChange={setListVisible} />
    </FormStepper>
  );
}

export default ArticleAvailability;
