import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import useUserStore from '../../services/UserStore';
import { roles, routes } from '../../utils/constants';
import { getTestID } from '../../utils/utils';
import FileExports from './FileExports';
import RunningTransformationsTotal from './RunningTransformationsTotal';
import RunningTransformationsTable from './RunningTransformationsTable';
import SyncStatuses from './SyncStatuses';

const tabs = [
  'syncStatus',
  'fileExports',
];

function SystemStatus() {
  const { t } = useTranslation();
  const user = useUserStore((s) => s.user);
  const { tab } = useParams();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(tabs.indexOf(tab) === -1 ? 0 : tabs.indexOf(tab));

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${routes.SYSTEM_STATUS}/${tabs[index]}`);
  };

  useEffect(() => {
    setTabIndex(tabs.indexOf(tab) === -1 ? 0 : tabs.indexOf(tab));
  }, [tab]);

  return (
    <Box data-testid={getTestID('SystemStatus')}>
      <Heading as="h1" size="h1">
        {t('systemStatus')}
      </Heading>
      <RunningTransformationsTotal />
      {user?.role === roles.ADMIN && <RunningTransformationsTable />}
      <Tabs isLazy index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>{t('syncStatus.heading')}</Tab>
          {user && (user.role === roles.MDM || user.role === roles.ADMIN) && <Tab>{t('fileExports.heading')}</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <SyncStatuses />
          </TabPanel>
          {user && (user.role === roles.MDM || user.role === roles.ADMIN) && (
          <TabPanel>
            <FileExports />
          </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default SystemStatus;
