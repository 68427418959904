import { Icon } from '@chakra-ui/react';

function ChevronLeftIcon(props) {
  return (
    <Icon
      viewBox="0 0 16 16"
      {...props}
    >
      <polygon points="5.71 8 11.21 2.5 10.5 1.79 4.29 8 10.5 14.21 11.21 13.5 5.71 8" fill="currentColor" />
    </Icon>
  );
}

export default ChevronLeftIcon;
