import {
  Box,
  Divider,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import Input from '../Form/Input';
import GeberitSpinner from '../../ui/GeberitSpinner';
import { getTestID } from '../../../utils/utils';

function Parameters({
  transformationData = {},
  transformationDataLoading = false,
}) {
  const { t, i18n } = useTranslation();

  const [nameField] = useField('name', 'text');
  const [descriptionField] = useField('description', 'text');
  const [exchangeStandard] = useField('exchangeStandard', 'text');

  return (
    <Box data-testid={getTestID('Transformations')}>
      <Heading as="h1" size="h1">
        {nameField.value}
      </Heading>
      <Text fontSize="lg">
        {descriptionField.value}
      </Text>
      {transformationDataLoading && <GeberitSpinner />}
      {transformationData && (
        <>
          {transformationData?.parameters?.length > 0 && (
            <>
              <Divider />
              <Heading>
                {`4. ${t('inputParameters')}`}
              </Heading>
              <Text marginBottom="15px">
                {t('inputParametersExplanation')}
              </Text>
              <SimpleGrid columns={[1, 1, 2, 3, 4, 4]} spacing={6}>
                {transformationData.parameters
                  .filter((param) => !(param.name === 'assortment' || param.name === 'articleList' || param.name === 'locale'))
                  .map((param, i) => (
                    <Input
                      autoFocus={i === 0}
                      name={`parameters[${i}].${param.name}`}
                      key={param.name}
                      type="parameter"
                      label={`parameters.${param.name}.label`}
                      data={param}
                      transformation={exchangeStandard.value}
                      hint={(i18n.exists(`parameters.${param.name}.hint`) && t(`parameters.${param.name}.hint`)) || ''}
                    />
                  ))}
              </SimpleGrid>
            </>
          )}
          <Divider />
          {transformationData?.presets?.length > 0 && (
            <>
              <Heading>
                {`5. ${t('transformationPresets')}`}
              </Heading>
              <Text marginBottom="15px">
                {t(`presets.${exchangeStandard.value}.explanation`)}
              </Text>
              <SimpleGrid columns={[1, 1, 2, 3, 4, 4]} spacing={6}>
                {transformationData.presets.map((preset, i) => (
                  <Input
                    name={`presets[${i}].name`}
                    key={preset.name}
                    type="preset"
                    data={preset}
                    transformation={exchangeStandard.value}
                    label={`presets.${exchangeStandard.value}.${preset.name}.label`}
                    hint={(i18n.exists(`presets.${exchangeStandard.value}.${preset.name}.hint`) || '')
                      && t(`presets.${exchangeStandard.value}.${preset.name}.hint`)}
                  />
                ))}
              </SimpleGrid>
            </>
          )}
        </>
      )}
    </Box>
  );
}

Parameters.propTypes = {
  transformationData: PropTypes.shape({
    name: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    presets: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }),
  transformationDataLoading: PropTypes.bool,
};

export default Parameters;
