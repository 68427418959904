import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { useDeleteFileExport } from '../../api/hooks';
import CloseIcon from '../../icons/CloseIcon';
import { formatDate, getTestID } from '../../utils/utils';
import KeyValuePairs from '../ui/KeyValuePairs';

function ModalDeleteFileExport(props) {
  const { fileExport, open, setOpen } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const detailsList = [
    { key: t('market'), value: fileExport?.locale },
    { key: t('filename'), value: fileExport?.filename },
    { key: t('updatedOn'), value: formatDate(fileExport?.lastUpdatedOn) },
  ];

  const { mutate: deleteFileExport } = useDeleteFileExport();

  const handleDeleteFileExport = (uuid) => {
    deleteFileExport(
      uuid,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['fileExports']);
        },
      },
    );
  };

  return (
    <Modal
      data-testid={getTestID('ModalDeleteFileExport')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('fileExports.delete')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <KeyValuePairs list={detailsList} templateColumns={{ base: '1fr' }} gridGap="1" />
          <Text>{t('fileExports.deleteSure')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            mr={3}
            onClick={() => {
              handleDeleteFileExport(fileExport.uuid);
              setOpen(false);
            }}
          >
            {t('delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalDeleteFileExport.propTypes = {
  fileExport: PropTypes.shape({
    uuid: PropTypes.string,
    locale: PropTypes.string,
    filename: PropTypes.string,
    lastUpdatedOn: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalDeleteFileExport;
