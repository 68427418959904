import { Icon } from '@chakra-ui/react';

function ArrowDownIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" sx={{ width: '20px', height: '20px' }} {...props}>
      <polygon points="13.71 9.79 13 9.09 8 14.09 8 0 7 0 7 14.09 2 9.09 1.29 9.79 7.5 16 13.71 9.79" fill="currentColor" />
    </Icon>
  );
}

export default ArrowDownIcon;
