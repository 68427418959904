import { Icon } from '@chakra-ui/react';

function RightArrowIcon(props) {
  const { fill } = props;
  return (
    <Icon viewBox="0 0 20 20">
      <path
        d="M12.646 17.007l-.707-.707 6.158-6.293H0v-1h18.075l-6.136-6 .707-.707L20 9.507l-7.354 7.5z"
        fill={fill}
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default RightArrowIcon;
