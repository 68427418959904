import { Icon } from '@chakra-ui/react';

function LogOutIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <polygon
        points="3.54 12.04 4.24 11.33 1.91 9 12 9 12 8 1.91 8 4.24 5.67 3.54 4.96 0 8.5 3.54 12.04"
        fill="currentColor"
      />
      <polygon
        points="6 16 16 16 16 1 6 1 6 6 7 6 7 2 15 2 15 15 7 15 7 11 6 11 6 16"
        fill="currentColor"
      />
    </Icon>
  );
}

export default LogOutIcon;
