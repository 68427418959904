import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { generateDecimal } from '../../../../utils/utils';
import TranslatedErrorMessage from '../TranslatedErrorMessage';

function Decimal({
  fieldName, fieldValue, onBlur, dataPrecision, setValue, autoFocus = false, disabled = false,
}) {
  // Generate step value based on precision
  const precision = parseInt(dataPrecision, 10);
  const stepValue = generateDecimal(precision);

  // Normalize input by replacing comma with dot
  const normalizeValue = (valueString) => valueString.replace(',', '.');

  // Handler for manual input changes
  const handleInputChange = (valueString) => {
    const normalizedValueString = normalizeValue(valueString);
    setValue(normalizedValueString);
  };

  // Handle formatting only on blur
  const handleBlur = (event) => {
    const normalizedValueString = normalizeValue(event.target.value);
    const parsedValue = parseFloat(normalizedValueString);

    if (!Number.isNaN(parsedValue)) {
      // Format the value to the correct precision on blur
      setValue(parsedValue.toFixed(precision));
    } else {
      setValue(''); // Handle case where the input is invalid or empty
    }

    // Trigger the onBlur passed down from Formik
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <>
      <NumberInput
        autoFocus={autoFocus}
        type="text"
        name={fieldName}
        value={fieldValue}
        allowMouseWheel
        step={stepValue}
        min={0.000}
        onChange={handleInputChange}
        onBlur={handleBlur}
        disabled={disabled}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <TranslatedErrorMessage name={fieldName} />
    </>
  );
}

Decimal.propTypes = {
  // Field properties
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBlur: PropTypes.func.isRequired,

  // Data properties
  dataPrecision: PropTypes.string.isRequired,

  // Other props
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  // Helpers
  setValue: PropTypes.func.isRequired,
};

export default Decimal;
