import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isServerError } from '../utils/utils';

function ErrorPage({ errorCode, errorMessage }) {
  const errorImgSrc = '/geberit_error_generic.svg';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (isServerError(errorCode)) {
      window.history.back();
    } else {
      // For other errors, navigate to the home page
      navigate('/');
    }
  };

  return (
    <Flex
      flexDirection={{ base: 'column-reverse', md: 'row' }}
      gap={{ base: 8, md: 18 }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        flexDirection="column"
        mb={{ base: 4, sm: 6, md: 8 }}
        maxW={{ base: '100%', sm: '75%' }}
        w={{ base: '100%', sm: '75%' }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Heading as="h1" size="4xl" mb={0} textTransform="uppercase" lineHeight="2.25">
            {errorCode}
          </Heading>
          <Text noOfLines={3} fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }} mt={10}>
            {t(errorMessage)}
          </Text>
          <Button
            px={6}
            mt={10}
            border="1px solid"
            colorScheme="blue"
            variant="outline"
            onClick={handleButtonClick}
          >
            {isServerError(errorCode) ? t('refreshPage') : t('backToOverview')}
          </Button>
        </Flex>
      </Flex>
      <Image
        src={errorImgSrc}
        boxSize={{ base: '72', sm: '87.5', md: '125' }}
        alt="Error message image"
        objectFit="cover"
      />
    </Flex>
  );
}

ErrorPage.propTypes = {
  errorCode: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorPage;
