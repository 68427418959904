const Modal = {
  baseStyle: {
    dialog: {
      borderRadius: 0,
    },
    header: {
      padding: '20px',
      '> svg': {
        marginRight: '15px',
      },
      span: {
        verticalAlign: 'bottom',
        fontSize: '18px',
        lineHeight: '18px',
      },
      button: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: 'black',
      },
    },
    body: {
      padding: '20px',
    },
    footer: {
      padding: '20px',
    },
  },
  variants: {
    warning: {
      header: {
        background: 'warningTransparency10',
        '> svg': {
          '*': {
            color: 'warning',
          },
        },
        span: {
          color: 'warning',
        },
      },
    },
    success: {
      header: {
        background: 'successTransparency10',
      },
    },
    info: {
      header: {
        background: 'gray.250',
        '> svg': {
          verticalAlign: 'bottom',
        },
      },
    },
    default: {
      header: {
        background: 'none',
      },
      dialog: {
        borderRadius: 0,
        maxW: 'xl',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

export default Modal;
