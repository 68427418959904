import { Icon } from '@chakra-ui/react';

function SettingsIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M14.18,10a5.73,5.73,0,0,1-.4,1l1.29,1.28-2.83,2.83L11,13.78a5.73,5.73,0,0,1-1,.4V16H6V14.18a5.73,5.73,0,0,1-1-.4L3.76,15.07.93,12.24,2.22,11a5.73,5.73,0,0,1-.4-1H0V6H1.82a5.73,5.73,0,0,1,.4-1L.93,3.76,3.76.93,5,2.22a5.73,5.73,0,0,1,1-.4V0h4V1.82a5.73,5.73,0,0,1,1,.4L12.24.93l2.83,2.83L13.78,5a5.73,5.73,0,0,1,.4,1H16v4ZM15,9V7H13.4a5.51,5.51,0,0,0-.87-2.12l1.13-1.12L12.24,2.34,11.12,3.47A5.51,5.51,0,0,0,9,2.6V1H7V2.6a5.51,5.51,0,0,0-2.12.87L3.76,2.34,2.34,3.76,3.47,4.88A5.51,5.51,0,0,0,2.6,7H1V9H2.6a5.51,5.51,0,0,0,.87,2.12L2.34,12.24l1.42,1.42,1.12-1.13A5.51,5.51,0,0,0,7,13.4V15H9V13.4a5.51,5.51,0,0,0,2.12-.87l1.12,1.13,1.42-1.42-1.13-1.12A5.51,5.51,0,0,0,13.4,9Z"
        fill="currentColor"
      />
      <path
        d="M12,8A4,4,0,1,1,8,4,4,4,0,0,1,12,8ZM8,5a3,3,0,1,0,3,3A3,3,0,0,0,8,5Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default SettingsIcon;
