import { Icon } from '@chakra-ui/react';

function CustomCheckIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M5 12.71 L1 8.71 L1.71 8 L5 11.29 L14.29 2 L15 2.71 Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default CustomCheckIcon;
