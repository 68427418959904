import { Icon } from '@chakra-ui/icons';

function LeftArrowIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M11.2971068,4.29289322 L15.0042136,8 L11.2971068,11.7071068 L9.88289322,10.2928932 L11.174,8.99989322 L0.59,9 L0.59,7 L11.174,6.99989322 L9.88289322,5.70710678 L11.2971068,4.29289322 Z"
        transform="translate(7.797107, 8.000000) rotate(-180.000000) translate(-7.797107, -8.000000) "
        fill="currentColor"
      />
    </Icon>
  );
}

export default LeftArrowIcon;
