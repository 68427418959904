/* eslint-disable react/jsx-filename-extension */
import { createRoot } from 'react-dom/client';

import App from './App';
import './i18n';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

// Workaround for an issue where the login popup doesn't close
// From: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5091#issuecomment-1342312690
if (window.location.hash !== '') {
  // eslint-disable-next-line no-console
  console.log(`hash found${window.location.hash}`);
} else {
  root.render(<App />);
}
