import { useTranslation } from 'react-i18next';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { getTestID } from '../../../utils/utils';
import {
  CatalogueDate,
  CatalogueName,
  RevisionArticleInfo,
  RevisionStatus,
  RevisionVersionBox,
} from '../Cells';

function RevisionTableLarge({ revisions = [] }) {
  const { t } = useTranslation();
  const maxWidthCatalogueName = ['3xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'];

  return (
    <Table data-testid={getTestID('UnavailableTable')}>
      <Thead>
        <Tr>
          <Th />
          <Th>{t('revisions.name')}</Th>
          <Th>{t('status')}</Th>
          <Th>{t('articles')}</Th>
          <Th>{t('createdOn')}</Th>
          <Th>{t('createdBy')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {revisions.map((revision) => (
          <Tr key={revision.uuid}>
            <RevisionVersionBox className=".first-cell" version={revision?.version} />
            <CatalogueName
              name={revision?.name}
              uuid={revision?.uuid}
              maxWidth={maxWidthCatalogueName}
              isRevision
            />
            <RevisionStatus status={revision?.status} />
            <RevisionArticleInfo
              articlesExported={revision?.statistics?.articlesExported}
              articlesSkipped={revision?.statistics?.articlesSkipped}
              articlesIncluded={revision?.statistics?.articlesIncluded}
            />
            <CatalogueDate date={revision?.createdOn} />
            <Td>
              {revision?.createdBy?.fullName}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

RevisionTableLarge.propTypes = {
  revisions: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      version: PropTypes.string,
      status: PropTypes.string.isRequired,
      statistics: PropTypes.shape({
        articlesExported: PropTypes.number,
        articlesSkipped: PropTypes.number,
        articlesIncluded: PropTypes.number,
      }),
      createdOn: PropTypes.string.isRequired,
      createdBy: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
};

export default RevisionTableLarge;
