import {
  Button,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAddUserLocale, useLocales } from '../../../api/hooks';
import CloseIcon from '../../../icons/CloseIcon';
import {
  getTestID,
  sortLocalesAlphabetically,
} from '../../../utils/utils';
import GeberitSpinner from '../../ui/GeberitSpinner';
import SingleSelectDropdown from '../../ui/SingleSelectDropdown';
import UserSummary from '../../ui/UserSummary';

function ModalAddLocale({
  user,
  open,
  setOpen,
  setUserLocale = null,
}) {
  const { t } = useTranslation();

  const [locale, setLocale] = useState();

  const { data: locales, loading } = useLocales();
  const { mutate: addUserLocale, isLoading: isAdding } = useAddUserLocale(user?.uuid);

  return (
    <Modal
      data-testid={getTestID('UserLocaleAdd')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('locale.create.heading')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => {
              setOpen(false);
              setUserLocale?.(true);
            }}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <UserSummary user={user} />
          {loading && <GeberitSpinner />}
          {!loading && locales?.length > 0 && (
            <>
              <FormLabel>{t('locale.create.select')}</FormLabel>
              <SingleSelectDropdown
                autoFocus
                value={locale}
                onChange={(e) => setLocale(e.target.value)}
                maxWidth="100%"
                label={t('inputSelectValues.select')}
                searchbox
              >
                {locales
                  .sort((a, b) => sortLocalesAlphabetically(a.localeCode, b.localeCode))
                  .map((l) => (
                    <option key={l.localeCode} value={l.localeCode}>
                      {t(`locales.${l.localeCode}`)}
                    </option>
                  ))}
              </SingleSelectDropdown>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid={getTestID('CancelButton')}
            variant="outline"
            mr={6}
            onClick={() => {
              setOpen(false);
              setUserLocale?.(true);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid={getTestID('SaveButton')}
            variant="primary"
            isDisabled={!locale || loading || isAdding}
            onClick={(e) => {
              e.preventDefault();
              addUserLocale({ userUuid: user.uuid, code: locale });
              setOpen(false);
              setUserLocale?.(true);
            }}
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalAddLocale.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    uuid: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setUserLocale: PropTypes.func,
};

export default ModalAddLocale;
