import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Alert from './Alert';
import Back from './Back';
import { getTestID } from '../../utils/utils';

function FallbackError({ error }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box data-testid={getTestID('Error')}>
      <Back text={t('back')} handleClick={() => navigate(-1)} paddingLeft={0} />
      <Alert variant="error">
        {error?.response?.data?.message
          || error?.message
          || JSON.stringify(error)}
      </Alert>
    </Box>
  );
}

FallbackError.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string,
    response: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string,
        status: PropTypes.number,
      }),
    }),
    message: PropTypes.string,
  }).isRequired,
};

export default FallbackError;
