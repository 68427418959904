import {
  Alert as ChakraAlert,
  Flex,
  Box,
  AlertDescription,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import TickIcon from '../../icons/TickIcon';
import WarningIcon from '../../icons/WarningIcon';
import InfoIcon from '../../icons/InfoIcon';
import ErrorIcon from '../../icons/ErrorIcon';
import { getTestID } from '../../utils/utils';

const getStyle = (variant) => {
  switch (variant) {
    case 'warning':
      return {
        colorSide: 'warning',
        colorMain: 'warningTransparency10',
        colorText: 'black',
        icon: <WarningIcon color="white" />,
      };

    case 'error':
      return {
        colorSide: 'error',
        colorMain: 'errorTransparency10',
        colorText: 'error',
        icon: <ErrorIcon color="white" />,
      };

    case 'success':
      return {
        colorSide: 'success',
        colorMain: 'successTransparency10',
        colorText: 'success',
        icon: <TickIcon color="white" />,
      };

    default:
      return {
        colorSide: 'geberit.500',
        colorMain: 'geberit.50',
        colorText: 'black',
        icon: <InfoIcon color="white" />,
      };
  }
};
function Alert(props) {
  const {
    variant,
    children,
  } = props;

  return (
    <ChakraAlert p="0" bgColor="transparent">
      {variant && (
      <Flex w="100%">
        <Flex alignItems="center" px={2} bgColor={getStyle(variant).colorSide}>
          {getStyle(variant).icon}
        </Flex>
        <Box p="4" bgColor={getStyle(variant).colorMain} w="100%">
          <AlertDescription
            data-testid={getTestID('RevisionStatusText')}
            color={getStyle(variant).colorText}
          >
            {children}
          </AlertDescription>
        </Box>
      </Flex>
      )}
    </ChakraAlert>
  );
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Alert;
