import {
  Box,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  formatDateTime,
  getTestID,
} from '../../utils/utils';
import { useRevisionsList } from '../../api/hooks';
import GeberitSpinner from '../ui/GeberitSpinner';
import KeyValuePairs from '../ui/KeyValuePairs';

function Entry({ revision, newer = false }) {
  const { t } = useTranslation();
  const linkToRevision = revision ? `/revision/${revision.uuid}` : null;

  const entryList = [
    {
      key: newer ? t('file.newerRevision') : t('file.olderRevision'),
      value: (
        <Link
          to={linkToRevision}
          as={NavLink}
        >
          {revision?.version}
        </Link>
      ),
    },
    {
      key: t('createdOn'),
      value: formatDateTime(revision?.createdOn),
    },
  ];

  return (
    <KeyValuePairs
      list={entryList}
      templateColumns={{ base: '1fr' }}
      maxW="100%"
      gridGap="4"
    />
  );
}

Entry.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  }).isRequired,
  newer: PropTypes.bool,
};

function NextPrev({ revisions, revisionUuid }) {
  const { t } = useTranslation();

  // there is only one revision
  if (revisions.length === 1) {
    return <Text paddingTop="8">{t('file.oneRevision')}</Text>;
  }

  const index = revisions.findIndex((i) => i.uuid === revisionUuid);

  // current revision is top of list
  if (index === 0) {
    return <Entry revision={revisions[1]} />;
  }

  // current revision is bottom of list
  if (index === revisions.length - 1) {
    return <Entry revision={revisions[revisions.length - 2]} newer />;
  }

  // current revision is middle of list
  if (revisions.length > 2) {
    return (
      <>
        <Entry revision={revisions[index - 1]} newer />
        <Entry revision={revisions[index + 1]} />
      </>
    );
  }

  return null;
}

NextPrev.propTypes = {
  revisionUuid: PropTypes.string.isRequired,
  revisions: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  })).isRequired,
};

function OtherRevisions(props) {
  const { revision } = props;
  const {
    uuid,
    revisionParent,
  } = revision;
  const { t } = useTranslation();

  const { isLoading, data: revisionsList } = useRevisionsList(revisionParent, !!revision);

  return (
    <Box
      data-testid={getTestID('Other')}
      paddingLeft="12"
      paddingRight="12"
      paddingBottom="12"
      background="gray.50"
    >
      <Heading margin="0">{t('file.otherRevisions')}</Heading>
      {isLoading && <GeberitSpinner />}
      {revisionsList && (
        <NextPrev revisions={revisionsList?.revisions} revisionUuid={uuid} />
      )}
    </Box>
  );
}

OtherRevisions.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    revisionParent: PropTypes.string.isRequired,
  }).isRequired,
};

export default OtherRevisions;
