import { useMemo } from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormStepper from './Form/FormStepper';
import Parameters from './FormSteps/Parameters';
import Assortment from './FormSteps/Assortment';
import {
  generateDuplicatedCatalogue,
  generateInitialDuplicationValues,
} from './utils';
import { generateValidationSchemaForParametersAndPresets } from './validationSchemaUtils';
import { duplicationValidations } from './validationSchema';
import { useCreateCatalogue } from '../../api/hooks';

function CatalogueDuplicate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const catalogueData = state?.catalogue || {};
  const transformationData = useMemo(() => state?.transformation || {}, [state]);

  const { mutate: createCatalogue } = useCreateCatalogue();

  const handleCreate = (catalogue) => {
    createCatalogue({ catalogue }, {
      onSuccess: ({ data }) => {
        navigate(`/catalogue/${data.uuid}`);
      },
    });
  };

  // Memoize the validation schema calculation
  const parametersAndPresetsSchema = useMemo(
    () => generateValidationSchemaForParametersAndPresets(transformationData, t),
    [transformationData, t],
  );

  // Memoize the initial values calculation only once on mount
  const initialData = useMemo(
    () => generateInitialDuplicationValues(catalogueData, t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FormStepper
      initialValues={initialData}
      validationSchema={[...duplicationValidations, parametersAndPresetsSchema]}
      onSubmit={(formValues) => {
        const catalogue = generateDuplicatedCatalogue(formValues, catalogueData);
        handleCreate(catalogue);
        return catalogue;
      }}
      initialSubmitButtonDisabled
    >
      <Assortment transformationData={transformationData} />
      {catalogueData?.presets?.length > 0 && (
        <Parameters transformationData={transformationData} />
      )}
    </FormStepper>
  );
}

export default CatalogueDuplicate;
