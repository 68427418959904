import { useState } from 'react';
import {
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import StepButton from './StepButton';

function FormStepper({
  children,
  initialValues,
  validationSchema = [],
  duplicateFormButton = false,
}) {
  const [step, setStep] = useState(0);
  const currentChild = children[step];
  const currentSchema = validationSchema[step];

  const isLastStep = () => step === children.length - 1;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentSchema}
      autoComplete="off"
      onSubmit={(_, actions) => {
        if (isLastStep()) {
          actions.resetForm({ values: initialValues });
          setStep(0);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          {(isLastStep() && duplicateFormButton) && (
            <StepButton
              isLastStep={isLastStep()}
              isDisabled={(!isValid || !dirty || isSubmitting) && !isLastStep()}
              onClick={() => {
                if (isLastStep()) {
                  setStep(0); // Reset to the first step
                } else {
                  setStep((prev) => prev + 1); // Go to the next step
                }
              }}
            />
          )}
          {currentChild}
          <StepButton
            isLastStep={isLastStep()}
            isDisabled={(!isValid || !dirty || isSubmitting) && !isLastStep()}
            onClick={() => {
              if (isLastStep()) {
                setStep(0); // Reset to the first step
              } else {
                setStep((prev) => prev + 1); // Go to the next step
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
}

FormStepper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  validationSchema: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.shape(),
  })),
  duplicateFormButton: PropTypes.bool,
};

export default FormStepper;
