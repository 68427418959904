import { createIcon } from '@chakra-ui/react';

const TrashIcon = createIcon({
  displayName: 'Trash',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M12 0v2h6v3h-1.001v15h-14V5H2V2h6V0h4zm3.999 5h-12v14h12V5zm-10 2v10H5V7h1zm9 0v10h-1V7h1zM9 7v10H8V7h1zm3 0v10h-1V7h1zm5-3.999h-14v1h14v-1zM11 1H9v1h2V1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});
export default TrashIcon;
