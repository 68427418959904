const FileExports = {
  baseStyle: {
    p: {
      margin: 0,
      padding: '15px',
    },
  },
};

export default FileExports;
