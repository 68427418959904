const SEPARATOR = '***';
const REGEX_PATTERN = /^[-_.0-9 A-Z]+$/;

function parseArticleKeys(text) {
  if (!text) {
    return {
      identifiedIDsAmount: 0,
      ignoredIDsAmount: 0,
      resultIDsAmount: 0,
      uniqueIDsAmount: 0,
      uniqueIDs: [],
    };
  }
  let identifiedIDs = [];
  let identifiedIDsAmount = [];
  let ignoredIDs = [];
  let uniqueIDs = [];
  const resultIDs = [];
  // purge arrays without new assignment from former execution
  ignoredIDs = [];

  // transform all IDs to uppercase characters
  let str = text.toUpperCase();

  // split all IDs by newline, semicolon and comma.
  str = str.split('\n').join(SEPARATOR);
  str = str.split(';').join(SEPARATOR);
  str = str.split(',').join(SEPARATOR);
  identifiedIDs = str.split(SEPARATOR);

  // trim leading and trailing whitespaces from each identified element
  identifiedIDs = identifiedIDs.map((id) => id.trim());

  // clean empty entries/lines
  identifiedIDs.forEach((element, index) => {
    // check for empty array element, i.e. in case of trailing newlines
    if (element === '') {
      identifiedIDs.splice(index, 1);
    }
  });

  // set amount of totally identified IDs:
  identifiedIDsAmount = identifiedIDs.length;

  // split out invalid elements from original array and assign valid ones to another
  identifiedIDs.forEach((element) => {
    // check for array entries with unsupported characters
    if (!REGEX_PATTERN.test(element)) {
      ignoredIDs.push(element);
    } else {
      resultIDs.push(element);
    }
  });

  // remove duplicate entries by creating a set
  uniqueIDs = [...new Set(resultIDs)];

  const resultIDsAmount = resultIDs.length;
  const ignoredIDsAmount = ignoredIDs.length;
  const uniqueIDsAmount = uniqueIDs.length;

  // output statistics
  return {
    identifiedIDsAmount,
    ignoredIDsAmount,
    resultIDsAmount,
    uniqueIDsAmount,
    uniqueIDs,
  };
}

export default parseArticleKeys;
