/* eslint-disable max-len */
import {
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useError } from '../context/NetworkErrorContext';
import { clearLocalStorageExcept } from '../utils/utils';
import {
  portalApi,
  statisticsApi,
  integrationApi,
  dataExportsApi,
  revisionsApi,
  standardsApi,
} from '../utils/constants';
import createAxiosInstance from './axiosConfig';

// Token function to add Authorization header
const token = (config) => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${JSON.parse(window.localStorage.getItem('accessToken'))}`,
  },
});

// Error handling function
const useErrorHandlers = () => {
  const toast = useToast();
  const hasShownUnauthorizedToast = useRef(false);
  const hasShownServerErrorRegistrationToast = useRef(false);
  const hasShownServerErrorAuthToast = useRef(false);

  const { addError } = useError();

  const handleUnauthorized = () => {
    if (!hasShownUnauthorizedToast.current) {
      hasShownUnauthorizedToast.current = true;
      localStorage.setItem('redirect', encodeURI(window.location.pathname));
      clearLocalStorageExcept(['i18nextLng', 'redirect']);
      toast({
        title: 'Unauthorized',
        description: 'You have been logged out. Please log in again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          hasShownUnauthorizedToast.current = false;
        },
      });
      setTimeout(() => {
        window.location.href = '/welcome';
      }, 100);
    }
  };

  const handlePageNotFound = () => {
    window.location.href = '/404';
  };

  const handleServerError = () => {
    window.location.href = '/500';
  };

  const handleServiceUnavailableError = () => {
    window.location.href = '/503';
  };

  const handleRegistration500Error = () => {
    if (!hasShownServerErrorRegistrationToast.current) {
      hasShownServerErrorRegistrationToast.current = true;
      localStorage.setItem('redirect', encodeURI(window.location.pathname));
      clearLocalStorageExcept(['i18nextLng', 'redirect']);
      toast({
        title: 'Server Error',
        description: 'There is a server error. Please try again later.',
        status: 'error',
        duration: 150000,
        isClosable: true,
        onCloseComplete: () => {
          hasShownServerErrorRegistrationToast.current = false;
        },
      });
    }
  };

  const handleRegistration404Error = () => {
    if (!hasShownServerErrorAuthToast.current) {
      hasShownServerErrorAuthToast.current = true;
      localStorage.setItem('redirect', encodeURI(window.location.pathname));
      clearLocalStorageExcept(['i18nextLng', 'redirect']);
      toast({
        title: 'Registration Error',
        description: 'There is an error in the registration process. Please try again later.',
        status: 'error',
        duration: 150000,
        isClosable: true,
        onCloseComplete: () => {
          hasShownServerErrorAuthToast.current = false;
        },
      });
    }
  };

  const onError = (error) => {
    if (error.response) {
      const { status, config } = error.response;
      const { url, method } = config;
      const isRegistrationPath = url.includes('registration');

      const noHandlingPaths = ['article', 'logfile', 'fileDownloadLinks', 'export', 'summary', 'parameters', 'statistics',
        'runningTransformations', 'syncData', 'usageLog', 'ownership', 'contextMenu', 'collaboration', 'collaborator', 'configuration',
      ];
      const shouldHandleError = !noHandlingPaths.some((path) => url.includes(path));

      if (isRegistrationPath) {
        switch (status) {
          case 401:
            handleUnauthorized();
            break;
          case 404:
            handleRegistration404Error();
            break;
          case 500:
          case 503:
            handleRegistration500Error();
            break;
          default:
            handleRegistration404Error();
            break;
        }
      } else if (shouldHandleError && method === 'get') {
        switch (status) {
          case 401:
            handleUnauthorized();
            break;
          case 404:
            handlePageNotFound();
            break;
          case 500:
            handleServerError();
            break;
          case 503:
            handleServiceUnavailableError();
            break;
          default:
            break;
        }
      }

      // Paths where error messages should not be displayed.
      const noErrorMessagePaths = ['fileDownloadLinks', 'ownership', 'article'];
      const shouldDisplayErrorMessage = !noErrorMessagePaths.some((path) => url.includes(path));

      // Add the error to the global error state using addError
      if (addError && shouldDisplayErrorMessage && method !== 'head') {
        addError({
          message: error?.response?.data?.message || error.message || 'An error occurred',
          status,
          description: `Error in ${url}`,
        });
      }
    }
  };

  return onError;
};

const ApiContext = createContext(null);

export function ApiProvider({ children }) {
  const onError = useErrorHandlers();

  const apiInstances = useMemo(() => ({
    dataExports: createAxiosInstance(dataExportsApi, token, onError),
    integration: createAxiosInstance(integrationApi, token, onError),
    portal: createAxiosInstance(portalApi, token, onError),
    revisions: createAxiosInstance(revisionsApi, token, onError),
    standards: createAxiosInstance(standardsApi, token, onError),
    statistics: createAxiosInstance(statisticsApi, token, onError),
  }), [onError]);

  return (
    <ApiContext.Provider value={apiInstances}>
      {children}
    </ApiContext.Provider>
  );
}

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useApiInstances = () => useContext(ApiContext);
export default useApiInstances;
