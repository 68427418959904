import { ActiveUsersTable, DefaultUsersTable, PendingUsersTable } from './UsersTables';

function UserListWrapper() {
  return (
    <>
      <PendingUsersTable />
      <ActiveUsersTable />
      <DefaultUsersTable />
    </>
  );
}

export default UserListWrapper;
