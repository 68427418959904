import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalIcon from '../ui/ModalIcon';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import { useDeleteRevision } from '../../api/hooks';

function ModalDeleteRevision(props) {
  const {
    revision,
    open,
    setOpen,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: deleteRevision } = useDeleteRevision();

  const handleDelete = (uuid) => {
    deleteRevision(uuid, {
      onSuccess: () => navigate(`/catalogue/${revision.revisionParent}`),
    });
  };

  return (
    <Modal
      data-testid={getTestID('ModalDeleteRevision')}
      closeOnOverlayClick={false}
      isOpen={open}
      variant="warning"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalIcon type="warning" />
          <Text as="span">{t('warning')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {t('revision.delete.sure')}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            marginRight="5"
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid={getTestID('approveDeleteRevision')}
            variant="primary"
            onClick={() => handleDelete(revision.uuid)}
          >
            {t('yes')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalDeleteRevision.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    revisionParent: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalDeleteRevision;
