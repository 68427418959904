import { useState } from 'react';
import {
  Box,
  Flex,
} from '@chakra-ui/react';
import ToggleSidebarButton from './ToggleSidebarButton';
import DrawerMenuContent from './DrawerMenuContent';

function SidebarNavigation() {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Flex
        position="fixed"
        flexDirection="column"
        width={isExpanded ? '280px' : '60px'}
        height="100vh"
        backgroundColor="gray.50"
        zIndex={999}
        transition="width 0.3s"
        transitionTimingFunction="cubic-bezier(0, 0, 0.25, 1)"
      >
        <ToggleSidebarButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />

        <DrawerMenuContent setIsExpanded={setIsExpanded} />
      </Flex>

      <Box
        id="overlay"
        position="fixed"
        display={isExpanded ? 'block' : 'none'}
        w="100%"
        h="100%"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0,0,0,0.2)"
        zIndex="998"
        onClick={() => setIsExpanded(false)}
      />
    </>
  );
}

export default SidebarNavigation;
