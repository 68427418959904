import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const calculateGreeting = () => {
  const date = new Date();
  const time = date.getHours();

  if (time > 17 || time < 6) {
    return 'evening';
  }

  if (time < 12) {
    return 'morning';
  }

  return 'afternoon';
};

function Greet({ name }) {
  const { t } = useTranslation();

  // returns Good morning/afternoon/evening, {username}
  return (
    <>
      {t(`${calculateGreeting()}`)}
      &#44;&nbsp;
      {name}
    </>
  );
}

Greet.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Greet;
