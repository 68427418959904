import {
  Flex,
  IconButton,
} from '@chakra-ui/react';
import {
  AddIcon,
  MinusIcon,
} from '@chakra-ui/icons';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

function Toggler({
  expand,
  handleClick,
  marginRight = '',
}) {
  return (
    <Flex
      data-testid={getTestID('Toggler')}
      marginRight={marginRight}
      justifyContent="center"
    >
      <IconButton
        variant="filled"
        size="xs"
        padding={0}
        onClick={handleClick}
        icon={expand ? <MinusIcon /> : <AddIcon />}
      />
    </Flex>
  );
}

Toggler.propTypes = {
  expand: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  marginRight: PropTypes.string,
};

export default Toggler;
