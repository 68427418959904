import { Icon } from '@chakra-ui/icons';

function ChevronRightIcon(props) {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path
        d="M4.293 19.293L5 20l10-10L4.707 0 4 .707 13.586 10z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  );
}

export default ChevronRightIcon;
