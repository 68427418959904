import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ModalIcon from '../ui/ModalIcon';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import GeberitSpinner from '../ui/GeberitSpinner';
import { useArchiveCatalogue } from '../../api/hooks';

function ModalArchiveCatalogue(props) {
  const {
    open,
    setOpen,
    catalogueUuid,
  } = props;
  const { t } = useTranslation();
  const { refetch, isLoading, fetchStatus } = useArchiveCatalogue(catalogueUuid, setOpen);

  const handleArchive = () => {
    refetch();
  };

  return (
    <Modal
      data-testid={getTestID('ModalArchiveCatalogue')}
      closeOnOverlayClick={false}
      isOpen={open}
      variant="warning"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalIcon type="warning" />
          <Text as="span">{t('warning')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {t('catalogue.archiveWarning')}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid={getTestID('archiveCatalogueButton')}
            variant="primary"
            mr={3}
            disabled={isLoading}
            onClick={handleArchive}
            leftIcon={(isLoading && fetchStatus !== 'idle') && <GeberitSpinner />}
          >
            {t('archive')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalArchiveCatalogue.propTypes = {
  catalogueUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalArchiveCatalogue;
