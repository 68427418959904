import {
  Td,
  Tooltip,
  Link,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  formatDateTime,
  getCountry,
  getLanguage,
  getTestID,
} from '../../utils/utils';
import SharedIcon from '../../icons/SharedIcon';
import PaperClip from '../../icons/PaperClip';
import Tag from '../ui/Tag';
import Status from '../ui/Status';

export function CatalogueName({
  name, uuid, maxWidth, isRevision = false,
}) {
  return (
    <Td>
      <Box maxWidth={maxWidth}>
        <Tooltip label={name} placement="top-start" hasArrow>
          <span>
            <Link
              variant="truncated"
              data-testid={getTestID('existingCatalogueLink')}
              to={isRevision ? `/revision/${uuid}?` : `catalogue/${uuid}`}
              as={NavLink}
            >
              {name}
            </Link>
          </span>
        </Tooltip>
      </Box>
    </Td>
  );
}

CatalogueName.propTypes = {
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  maxWidth: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRevision: PropTypes.bool,
};

export function CatalogueStatus({ status }) {
  const { t } = useTranslation();

  return (
    <Td data-testid={getTestID('existingCatalogueStatus')}>
      {status.includes('archived') && <Tag>{t('catalogue.archived')}</Tag>}
      {status === 'deprecated' && <Tag>{t('catalogue.deprecated')}</Tag>}
    </Td>
  );
}

CatalogueStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export function CatalogueCountry({ region }) {
  return <Td data-testid={getTestID('CountryText')}>{getCountry(region) || region}</Td>;
}

CatalogueCountry.propTypes = {
  region: PropTypes.string.isRequired,
};

export function CatalogueExchangeStandard({ exchangeStandard }) {
  const { t } = useTranslation();

  return <Td>{t(`transformations.${exchangeStandard}.pretty_name`)}</Td>;
}

CatalogueExchangeStandard.propTypes = {
  exchangeStandard: PropTypes.string.isRequired,
};

export function CatalogueLanguage({ language, withLabel = false }) {
  const { t } = useTranslation();

  return withLabel
    ? (
      <Td>
        <Flex alignItems="center">
          <Text fontWeight="bold">{`${t('language')}: `}</Text>
          <Text>{getLanguage(language)}</Text>
        </Flex>
      </Td>
    )
    : <Td>{getLanguage(language)}</Td>;
}

CatalogueLanguage.propTypes = {
  language: PropTypes.string.isRequired,
  withLabel: PropTypes.bool,
};

export function CatalogueDate({ date = null, link = '' }) {
  return (
    <Td>
      {link ? <Link as={NavLink} to={link}>{formatDateTime(date)}</Link> : formatDateTime(date)}
    </Td>
  );
}

CatalogueDate.propTypes = {
  date: PropTypes.string,
  link: PropTypes.string,
};

export function CatalogueTooltipIcons({ isShared, assetsConfiguredFlag }) {
  const { t } = useTranslation();

  return (
    <Td>
      {isShared && (
        <Tooltip label={t('catalogue.shared')} placement="top-start" hasArrow>
          <span><SharedIcon /></span>
        </Tooltip>
      )}
      {assetsConfiguredFlag && (
        <Tooltip data-testid={getTestID('AssetsTooltip')} label={t('catalogue.hasAssets')} placement="top-start" hasArrow>
          <span data-testid={getTestID('AssetsIcon')}><PaperClip /></span>
        </Tooltip>
      )}
    </Td>
  );
}

CatalogueTooltipIcons.propTypes = {
  isShared: PropTypes.bool.isRequired,
  assetsConfiguredFlag: PropTypes.bool.isRequired,
};

export function RevisionVersionBox({ version }) {
  return (
    <Td>
      <Flex justifyContent="center">
        <Flex
          width="8"
          height="8"
          border="1px solid"
          borderColor="geberit.500"
          color="geberit.500"
          bg="gray.100"
          fontSize="12px"
          lineHeight="8"
          fontWeight="700"
          justifyContent="center"
        >
          {version}
        </Flex>
      </Flex>
    </Td>
  );
}

RevisionVersionBox.propTypes = {
  version: PropTypes.string.isRequired,
};

export function RevisionArticleInfo({
  articlesIncluded, articlesExported = 0, articlesSkipped = 0,
}) {
  return (
    <Td>
      <Text data-testid={getTestID('RevisionArticleInfo')}>
        {articlesExported ? `${articlesExported}/` : '-/'}
        {articlesSkipped ? `${articlesSkipped}/` : '-/'}
        <Text as="span" fontWeight="700">
          {articlesIncluded ? `${articlesIncluded}` : '-'}
        </Text>
      </Text>
    </Td>
  );
}

RevisionArticleInfo.propTypes = {
  articlesExported: PropTypes.number,
  articlesSkipped: PropTypes.number,
  articlesIncluded: PropTypes.number.isRequired,
};

export function RevisionStatus({ status, noText = false }) {
  return <Td><Status type={status} noText={noText} margin="0" /></Td>;
}

RevisionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  noText: PropTypes.bool,
};
