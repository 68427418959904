import {
  Box,
  Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  formatDateTime,
  getLocale,
} from '../../utils/utils';
import {
  useCatalogueAvgTime,
  useReportSummary,
  useUser,
} from '../../api/hooks';
import GeberitSpinner from '../ui/GeberitSpinner';
import KeyValuePairs from '../ui/KeyValuePairs';
import SecondsAsEstimatedTime from '../common/SecondsAsEstimatedTime';

function Details(props) {
  const { revision } = props;
  const {
    uuid,
    exchangeStandard,
    requestedBy,
    createdOn,
    locale,
    version,
  } = revision;
  const { t } = useTranslation();

  const { data: reportSummary } = useReportSummary(uuid, revision);

  const { isLoading, data: estimatedTime } = useCatalogueAvgTime(uuid);

  const { data: user } = useUser(requestedBy);

  const detailsList = [
    {
      key: t('transformationType'),
      value: t(`transformations.${exchangeStandard}.pretty_name`),
    },
    {
      key: t('transformationBuild'),
      value: reportSummary?.parameters.find((p) => p.name === 'buildId')?.value,
    },
    {
      key: t('duration'),
      value: isLoading
        ? <GeberitSpinner />
        : estimatedTime && (
          <SecondsAsEstimatedTime
            totalSeconds={estimatedTime.timeTaken || estimatedTime.averageTime}
            showLabel={false}
          />
        ),
    },
    {
      key: t('version'),
      value: version,
    },
    {
      key: t('market'),
      value: getLocale(locale) || locale,
    },
    {
      key: t('transformationSyncDate'),
      value: formatDateTime(reportSummary?.syncData.find((s) => s.syncType === 'step')?.syncSyncedDate),
    },
    {
      key: t('createdBy'),
      value: user?.fullName,
    },
    {
      key: t('createdOn'),
      value: formatDateTime(createdOn),
    },
  ];

  return (
    <Box
      padding="12"
      background="gray.50"
    >
      <Heading margin="0">{t('file.details')}</Heading>
      <KeyValuePairs list={detailsList} templateColumns={{ base: '1fr' }} maxW="100%" gridGap="4" />
    </Box>
  );
}

Details.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired,
    exchangeStandard: PropTypes.string.isRequired,
    updatedBy: PropTypes.string.isRequired,
    requestedBy: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
  }).isRequired,
};

export default Details;
