import PropTypes from 'prop-types';
import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Layout from '../Layout/Layout';
import LimitedLayout from '../Layout/LimitedLayout';
import { roles } from '../../utils/constants';
import FallbackError from '../ui/FallbackError';

const renderAuthorizedRoutes = (role, location, children) => {
  const commonRoles = [roles.MDM, roles.ADMIN, roles.LDES];

  if (commonRoles.includes(role)) {
    return (
      <Layout>
        <ErrorBoundary
          FallbackComponent={FallbackError}
          resetKeys={[location]}
        >
          {children}
        </ErrorBoundary>
      </Layout>
    );
  }
  return (
    <LimitedLayout>
      <ErrorBoundary
        FallbackComponent={FallbackError}
        resetKeys={[location]}
      >
        {children}
      </ErrorBoundary>
    </LimitedLayout>
  );
};

function AuthorizedRoute({
  children, permissions, role = roles.DEFAULT,
}) {
  const location = useLocation();

  const isAllowed = permissions.some((p) => p === role);
  if (!isAllowed && role === roles.DEFAULT) {
    return (<Navigate to="/overview" replace />);
  }
  if (!isAllowed && role !== roles.DEFAULT) {
    return (<Navigate to="/welcome" replace />);
  }

  return (
    isAllowed && renderAuthorizedRoutes(role, location, children)
  );
}

AuthorizedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AuthorizedRoute;
