import {
  Box,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../utils/utils';
import { useTransformationsRunning } from '../../api/hooks';

function RunningTransformationsTotal() {
  const { t } = useTranslation();

  const { data: transformationsRunning } = useTransformationsRunning();

  return (
    <Box mb="4">
      {transformationsRunning && (
        <Text data-testid={getTestID('TransformationsRunning')}>
          {`${t('transformations.running')} ${transformationsRunning.total}`}
        </Text>
      )}
    </Box>
  );
}

export default RunningTransformationsTotal;
