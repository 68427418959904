/* eslint-disable radix */
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useStatistics, useUpdateStatus } from '../../../api/hooks';
import { getTestID } from '../../../utils/utils';
import Alert from '../../ui/Alert';
import FileUpload from './FileUpload';
import DownloadSummaryReport from './DownloadSummaryReport';
import ModalSetStatusDeclined from './ModalSetStatusDeclined';
import ModalSetStatusApproved from './ModalSetStatusApproved';
import ModalChangeRevisonStatus from './ModalChangeRevisonStatus';
import LastUpdated from './LastUpdated';

function TabDetails({
  revision,
  acceptedFileType = '',
  summaryReport = null,
}) {
  const {
    status,
    updatedOn,
    updatedBy,
    uuid,
    name,
  } = revision;
  const { t } = useTranslation();

  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [newRevisionLink, setNewRevisionLink] = useState();

  const { data: statistics } = useStatistics(uuid, revision);
  const { mutate: updateStatus } = useUpdateStatus(uuid);

  const handleUpdate = (s) => {
    updateStatus(s);
  };

  return (
    <Box data-testid={getTestID('TabDetails')}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading margin={0}>{t('transformation.name')}</Heading>
        <Flex data-testid={getTestID('Actions')} gap="4">
          {status === 'waitingForApproval' && (
            <>
              <Button
                data-testid={getTestID('SetTransformationToApproved')}
                variant="approve"
                onClick={() => setOpenApprove(true)}
              >
                {t('approve')}
              </Button>
              <Button
                data-testid={getTestID('SetTransformationToDeclined')}
                variant="decline"
                onClick={() => setOpenDecline(true)}
              >
                {t('decline')}
              </Button>
            </>
          )}
          {(status === 'approved' || status === 'declined') && (
            <Button variant="secondary" onClick={() => setOpenChange(true)}>
              {t('changeApproval')}
            </Button>
          )}
          <ModalSetStatusApproved
            handleUpdate={handleUpdate}
            revision={revision}
            open={openApprove}
            setOpen={setOpenApprove}
          />
          <ModalSetStatusDeclined
            handleUpdate={handleUpdate}
            revision={revision}
            open={openDecline}
            setOpen={setOpenDecline}
          />
          <ModalChangeRevisonStatus
            handleUpdate={handleUpdate}
            revision={revision}
            open={openChange}
            setOpen={setOpenChange}
          />
        </Flex>
      </Flex>
      <LastUpdated updatedOn={updatedOn} updatedBy={updatedBy} />
      {(status === 'waitingForApproval'
        || status === 'approved'
        || status === 'declined'
        || status === 'published')
        && (
          <FileUpload
            uuid={uuid}
            setNewRevisionLink={setNewRevisionLink}
            accept={acceptedFileType}
          />
        )}
      {newRevisionLink && (
        <Alert variant="success">
          {name}
          {t('fileUpload.success')}
          &nbsp;
          <Link to={`/revision/${newRevisionLink.createdRequest?.uuid}`} as={NavLink}>
            {newRevisionLink.createdRequest?.name}
          </Link>
        </Alert>
      )}
      {statistics && (
        statistics.map((statistic) => (
          <Flex
            data-testid={getTestID('ArticleExportAndSkip')}
            key={statistic.statistic_type}
            marginTop="4"
          >
            <Text>
              {statistic.statistic_value}
              &nbsp;
            </Text>
            <Text variant="label">{t(`revision.statistics.${statistic.statistic_type}`)}</Text>
          </Flex>
        ))
      )}
      <DownloadSummaryReport summaryReport={summaryReport} />
    </Box>
  );
}

TabDetails.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired,
    updatedBy: PropTypes.string.isRequired,
  }).isRequired,
  acceptedFileType: PropTypes.string,
  summaryReport: PropTypes.shape({
    fileLink: PropTypes.string,
  }),
};

export default TabDetails;
