import {
  Button,
  Divider,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  useState,
} from 'react';

import UserSummary from '../../ui/UserSummary';
import CloseIcon from '../../../icons/CloseIcon';
import {
  roles,
  userTypes,
} from '../../../utils/constants';
import {
  getTestID,
  sortLocalesAlphabetically,
} from '../../../utils/utils';
import {
  useEditUserRole,
  useLocales,
  useRoleState,
} from '../../../api/hooks';
import SingleSelectDropdown from '../../ui/SingleSelectDropdown';

function ModalEditUserRole(props) {
  const {
    user,
    type,
    open,
    setOpen,
  } = props;
  const { t } = useTranslation();
  const [role, setRole] = useRoleState(user.role);
  const [locale, setLocale] = useState();
  const { data: locales } = useLocales();
  const editUserRole = useEditUserRole(user, role, locale);

  return (
    <Modal
      data-testid={getTestID('UserRoleEdit')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">
            {type === userTypes.PENDING ? t('user.approve') : t('user.changeRole')}
          </Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <UserSummary user={user} />
          <Divider />
          <FormLabel margin="0 0 8px 0">{t('role')}</FormLabel>
          <SingleSelectDropdown
            autoFocus
            value={role !== roles.DEFAULT ? role : undefined}
            onChange={(e) => setRole(e.target.value)}
            onFocus={(e) => setRole(e.target.value)}
            maxWidth="100%"
            label={t('inputSelectValues.select')}
          >
            <option value="ADMIN">
              {t('admin')}
              {' '}
              (
              {roles.ADMIN}
              )
            </option>
            <option value="MDM">
              {t('mdm')}
              {' '}
              (
              {roles.MDM}
              )
            </option>
            <option value="LDES">
              {t('ldes')}
              {' '}
              (
              {roles.LDES}
              )
            </option>
          </SingleSelectDropdown>
          {(role === roles.LDES && locales?.length > 0) && (
            <>
              <FormLabel margin="16px 0 8px 0">{t('locale.create.select')}</FormLabel>
              <SingleSelectDropdown
                autoFocus
                value={locale}
                onChange={(e) => setLocale(e.target.value)}
                maxWidth="100%"
                label={t('inputSelectValues.select')}
                searchbox
              >
                {locales
                  .sort((a, b) => sortLocalesAlphabetically(a.localeCode, b.localeCode))
                  .map((l) => (
                    <option key={l.localeCode} value={l.localeCode}>
                      {t(`locales.${l.localeCode}`)}
                    </option>
                  ))}
              </SingleSelectDropdown>
            </>
          )}
          <Text>{type === userTypes.PENDING && t('user.approving')}</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" marginRight={6} onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            isDisabled={(role === roles.LDES && !locale) || role === roles.DEFAULT}
            onClick={(e) => {
              e.preventDefault();
              editUserRole.mutate({ userUuid: user.uuid, userRole: role });
              setOpen(false);
            }}
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalEditUserRole.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalEditUserRole;
