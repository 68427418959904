import { useState } from 'react';
import {
  Button,
  IconButton,
  Stack,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useDeleteAssortment } from '../../api/hooks';
import DownloadIcon from '../../icons/DownloadIcon';
import XlsxIcon from '../../icons/XlsxIcon';
import TrashIcon from '../../icons/TrashIcon';
import { revisionStatus } from '../../utils/constants';
import { formatDateTime, getTestID } from '../../utils/utils';
import GeberitSpinner from '../ui/GeberitSpinner';
import Status from '../ui/Status';
import ModalDeleteAssets from './ModalDeleteAssets';

const calculateValidityOfAsset = (from) => {
  const today = new Date();
  const date = new Date(from);

  const difference = new Date(date.setDate(date.getDate() + 30)).getTime() - Date.parse(today);
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return totalDays;
};

const assetParams = [
  {
    name: 'datasheetsIncludedAnonymous',
    tag: 'dexAssetsPortal.datasheetsIncludedAnonymous.label',
    showValues: false,
  },
  {
    name: 'documentsIncludedAnonymous',
    tag: 'dexAssetsPortal.documentsIncludedAnonymous.label',
    showValues: false,
  },
  {
    name: 'datasheetsIncluded',
    tag: 'dexAssetsPortal.datasheetsIncludedAnonymous.label',
    showValues: false,
  },
  {
    name: 'imageFormatsAnonymous',
    tag: 'dexAssetsPortal.imageFormatsAnonymous.label',
    showValues: true,
  },
  {
    name: 'preferredImageTypesIncluded',
    tag: 'dexAssetsPortal.imageTypes.label',
    showValues: true,
  },
  {
    name: 'cadFormatsAnonymous',
    tag: 'dexAssetsPortal.cadFormatsAnonymous.label',
    showValues: true,
  },
  {
    name: 'ambientImagesIncludedAnonymous',
    tag: 'dexAssetsPortal.ambientImagesIncludedAnonymous.label',
    showValues: false,
  },
];

const getScope = (parameters) => {
  const includedAssetsWithValues = parameters?.filter(
    (p) => assetParams.some((i) => i.name === p.name),
  )
    .filter((p) => p.value !== 'false' && p.value !== '');

  const tags = assetParams?.filter(
    (a) => includedAssetsWithValues?.some((i) => i.name === a.name),
  );
  return tags;
};

function DexAssetsTableRow(props) {
  const {
    catalogue,
    revision,
  } = props;
  const { t } = useTranslation();
  const [deleteAssets, setDeleteAssets] = useState(false);

  const assortment = catalogue.parameters?.filter(
    (p) => p.name === 'articleListAnonymous' || p.name === 'articleList',
  )[0]?.value;
  const outputFile = revision?.fileLinks?.filter((fl) => fl.fileCategory === 'outputFile')?.[0]?.fileLink;
  const assetsFile = revision?.fileLinks?.filter((fl) => fl.fileCategory === 'assetsFile')?.[0]?.fileLink;

  const { mutate: deleteAssortment, isLoading } = useDeleteAssortment();

  const handleDeleteAssortment = () => {
    if (catalogue?.uuid) {
      deleteAssortment(catalogue.uuid);
    }
  };

  return (
    <>
      {revision && (
        <Tr>
          <Td
            maxW="150px"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {assortment || t('dexAssetsPortal.countryAssortment')}
          </Td>
          <Td>{t(`countries.${catalogue?.region}`)}</Td>
          <Td>{t(`languages.${catalogue?.language}`)}</Td>
          <Td>{getScope(catalogue?.parameters)?.map((s) => t(s.tag))?.join(', ')}</Td>
          <Td>{formatDateTime(revision?.createdOn)}</Td>
          <Td>{`${t('dexAssetsPortal.daysLeft', { count: calculateValidityOfAsset(revision?.createdOn) })}`}</Td>
          <Td>
            <Stack direction={['column', 'row']} alignItems="flex-start">
              {revision?.status === revisionStatus.JOB_STARTED && <Status width="200px" type={revision?.status} margin="0" />}
              {outputFile && (
                <Button
                  data-testid={getTestID('AssetExcelDownload')}
                  as="a"
                  variant="link"
                  href={outputFile}
                  iconSpacing="0"
                  paddingLeft="0"
                  leftIcon={<XlsxIcon />}
                >
                  {t('dexAssetsPortal.xlsx')}
                </Button>
              )}
              {assetsFile && (
                <Button
                  data-testid={getTestID('AssetZIPDownload')}
                  as="a"
                  variant="link"
                  href={assetsFile}
                  iconSpacing="0"
                  paddingLeft="0"
                  leftIcon={<DownloadIcon />}
                >
                  {t('dexAssetsPortal.assets')}
                </Button>
              )}
            </Stack>
          </Td>
          <Td>
            <IconButton
              icon={isLoading ? <GeberitSpinner /> : <TrashIcon />}
              color="error"
              onClick={() => setDeleteAssets(true)}
              padding={0}
              isDisabled={(revision?.status === revisionStatus.REQUESTED
                || revision?.status === revisionStatus.JOB_STARTED)}
            />
          </Td>
        </Tr>
      )}
      <ModalDeleteAssets
        handleDeleteAssortment={handleDeleteAssortment}
        open={deleteAssets}
        setOpen={setDeleteAssets}
      />
    </>
  );
}

DexAssetsTableRow.propTypes = {
  catalogue: PropTypes.shape({
    uuid: PropTypes.string,
    language: PropTypes.string,
    region: PropTypes.string,
    parameters: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }).isRequired,
  revision: PropTypes.shape({
    status: PropTypes.string,
    createdOn: PropTypes.string,
    fileLinks: PropTypes.arrayOf(PropTypes.shape({
      fileCategory: PropTypes.string,
      fileLink: PropTypes.string,
    })),
  }).isRequired,
};

export default DexAssetsTableRow;
