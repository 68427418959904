const Box = {
  baseStyle: {},
  variants: {
    labelContainer: {
      display: 'flex',
      '> *': {
        lineHeight: '1.6',
      },
    },
    clickable: {
      '*': {
        cursor: 'pointer !important',
      },
    },
  },
  defaultProps: {
    variant: 'labelContainer',
  },
};

export default Box;
