import {
  Drawer, DrawerBody, DrawerContent, DrawerHeader,
  DrawerOverlay,
  IconButton, useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import MenuIcon from '../../icons/MenuIcon';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import DrawerMenuContent from './DrawerMenuContent';

function MobileMenuDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = useState('right');

  return (
    <>
      <IconButton icon={<MenuIcon />} onClick={onOpen} aria-label="Show menu" />
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader display="flex" justifyContent="flex-end" paddingY="14px" paddingX="28px">
            <IconButton onClick={onClose} icon={<ChevronRightIcon />} marginTop="30px" />
          </DrawerHeader>
          <DrawerBody p="0">
            <DrawerMenuContent setIsExpanded={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MobileMenuDrawer;
