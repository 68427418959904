import {
  Box,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import GeberitSpinner from '../ui/GeberitSpinner';
import {
  getTestID,
  sortLocalesAlphabetically,
} from '../../utils/utils';
import { useCurrentUserLocales } from '../../api/hooks';
import KeyValuePairs from '../ui/KeyValuePairs';

function TabPersonalData({ user }) {
  const { t } = useTranslation();

  const { data: locales, loading } = useCurrentUserLocales();

  const personalData = [
    { key: t('firstName'), value: user.firstName },
    { key: t('lastName'), value: user.surname },
    { key: t('fullName'), value: user.fullName },
    { key: t('emailAddress'), value: user.email },
    { key: t('role'), value: t(user?.role?.toLowerCase()) },
  ];

  if (user.role === 'LDES') {
    personalData.push({
      key: t('user.locales.assigned'),
      value: loading ? (
        <GeberitSpinner />
      ) : (
        <UnorderedList>
          {locales && locales
            .sort((a, b) => sortLocalesAlphabetically(a.localeCode, b.localeCode))
            .map((locale) => (
              <ListItem key={locale.locale}>{t(`locales.${locale.localeCode}`)}</ListItem>
            ))}
        </UnorderedList>
      ),
    });
  }

  return (
    <Box data-testid={getTestID('TabPersonalData')}>
      <KeyValuePairs list={personalData} templateColumns={{ base: '100%' }} maxW="3xl" />
    </Box>
  );
}

TabPersonalData.propTypes = {
  user: PropTypes.shape({
    uuid: PropTypes.string,
    fullName: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    firstName: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default TabPersonalData;
