import PropTypes from 'prop-types';

function Dot({ color = 'black' }) {
  const style = {
    height: '10px',
    width: '10px',
    backgroundColor: color,
    borderRadius: '50%',
    display: 'inline-block',
  };

  return (<span style={style} />);
}

Dot.propTypes = {
  color: PropTypes.string,
};

export default Dot;
