import { createIcon } from '@chakra-ui/react';

const DownIcon = createIcon({
  displayName: 'ArrowDown',
  viewBox: '0 0 16 16',

  path: (
    <path
      d="M8 10.29 L2.5 4.79 L1.79 5.5 L8 11.71 L14.21 5.5 L13.5 4.79 Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
});

export default DownIcon;
