import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CloseIcon from '../../../icons/CloseIcon';
import {
  formatDateTime,
  getTestID,
} from '../../../utils/utils';

const status = 'declined';

function ModalSetStatusDeclined(props) {
  const { t } = useTranslation();
  const {
    revision,
    open,
    setOpen,
    handleUpdate,
  } = props;

  return (
    <Modal
      data-testid={getTestID('ModalSetStatusDeclined')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">
            {t('revision.decline')}
          </Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <Text margin={0} fontWeight="700">
            {revision?.name}
          </Text>
          <Text size="sm">{formatDateTime(revision?.updatedOn)}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid={getTestID('DeclineRevisionButton')}
            variant="primary"
            mr={3}
            onClick={(event) => {
              event.preventDefault();
              handleUpdate({ request: revision.uuid, status });
              setOpen(false);
            }}
          >
            {t('revision.decline')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalSetStatusDeclined.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedOn: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleUpdate: PropTypes.shape({
    mutate: PropTypes.func.isRequired,
  }).isRequired,
};

export default ModalSetStatusDeclined;
