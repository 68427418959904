import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import GeberitSpinner from '../ui/GeberitSpinner';
import {
  formatDateTime,
  getLocale,
  getTestID,
} from '../../utils/utils';
import { useTransformationsRunningDetail } from '../../api/hooks';

function RunningTransformationsTable() {
  const { t } = useTranslation();

  const { data: transformationsRunningDetail, isLoading } = useTransformationsRunningDetail();

  return (
    <>
      {isLoading && <GeberitSpinner />}
      {transformationsRunningDetail?.revisions?.length > 0 && (
        <TableContainer
          marginBottom="4"
          data-testid={getTestID('TransformationsRunningDetail')}
        >
          <Table variant="basic">
            <Thead>
              <Tr>
                <Th>{t('exchangeStandard')}</Th>
                <Th>{t('market')}</Th>
                <Th>{t('transformations.includedArticles')}</Th>
                <Th>{t('transformations.runningSince')}</Th>
                <Th>{t('createdBy')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transformationsRunningDetail.revisions
                .sort((a, b) => (new Date(b.createdOn) - new Date(a.createdOn)))
                .map((entry) => (
                  <Tr key={entry.exchangeStandard + entry.createdOn}>
                    <Td data-testid={getTestID('TransformationsStatusExchangeStandard')}>
                      {t(
                        `transformations.${entry.exchangeStandard}.pretty_name`,
                      )}
                    </Td>
                    <Td>{getLocale(entry.locale) || entry.locale}</Td>
                    <Td>{entry.includedArticleCount || '-'}</Td>
                    <Td>{formatDateTime(entry.createdOn)}</Td>
                    <Td data-testid={getTestID('TransformationsStatusCreatedBy')}>{entry.requestedBy.fullName}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default RunningTransformationsTable;
