import React, {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../utils/utils';
import CustomCheckIcon from '../../icons/CustomCheckIcon';
import DownIcon from '../../icons/DownIcon';
import SearchIcon from '../../icons/SearchIcon';

function SingleSelectDropdown({
  name, onChange, children,
  label = '', value = undefined, maxWidth = '30%', onBlur = () => { },
  matchWidth = true, disabled = false, searchbox = false, autoFocus = false,
}) {
  const { t } = useTranslation();
  const [showGradient, setShowGradient] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const menuListRef = useRef(null);
  const searchInputRef = useRef(null);
  const menuButtonRef = useRef(null);
  const optionRefs = useRef([]); // Array of refs for each menu option
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Handle scrolling in the MenuList and show gradient if overflow exists
  const handleScroll = useCallback(() => {
    if (menuListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuListRef.current;
      setShowGradient(scrollHeight > clientHeight && scrollTop + clientHeight < scrollHeight);
    }
  }, []);

  // Get the label for the selected option or use the provided label
  const getOptionLabel = useCallback((v) => {
    if (v == null) return label;
    const selectedChild = React.Children.toArray(children)
      .find((child) => child.props.value === v);
    return selectedChild ? selectedChild.props.children : label || '';
  }, [children, label]);

  // Focus the MenuButton when the component is mounted when autoFocus prop is true
  useEffect(() => {
    if (autoFocus && menuButtonRef.current) {
      menuButtonRef.current.focus();
    }
  }, [autoFocus]);

  /* Focus the search input once the dropdown is open.
    The slight delay ensures that the dropdown is fully rendered before focusing the input.
    Without the delay, the focus might be attempted too early before
    the input is available in the DOM. */
  useEffect(() => {
    if (isOpen && searchbox && searchInputRef.current) {
      const focusTimer = setTimeout(() => {
        searchInputRef.current.focus();
      }, 20);
      return () => clearTimeout(focusTimer);
    }
    return () => { };
  }, [isOpen, searchbox, searchQuery]);

  // Attach scroll event to MenuList and handle scroll
  useEffect(() => {
    const currentMenuList = menuListRef.current;
    if (currentMenuList) {
      handleScroll();
      currentMenuList.addEventListener('scroll', handleScroll);
      return () => {
        currentMenuList.removeEventListener('scroll', handleScroll);
      };
    }
    return () => { };
  }, [isOpen, handleScroll]);

  // Filter options based on the search query
  const options = useMemo(() => {
    if (!searchbox) return React.Children.toArray(children);
    return React.Children.toArray(children)
      ?.filter((child) => child.props.children.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [children, searchQuery, searchbox]);

  // Handle key navigation (ArrowDown, ArrowUp, Enter)
  const handleKeyDown = (e) => {
    if (!isOpen) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex < options.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : options.length - 1));
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      // Select the highlighted item
      onChange({ target: { name, value: options[highlightedIndex].props.value } });
      onClose(); // Close the dropdown after selection
    }
  };

  // Focus the highlighted option when the index changes
  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      optionRefs.current[highlightedIndex].focus(); // Focus on the highlighted item
    }
  }, [highlightedIndex]);

  return (
    <Box width={maxWidth || '250px'}>
      <Menu
        isOpen={isOpen}
        onOpen={() => {
          onOpen();
          setHighlightedIndex(-1); // Reset highlighted index when menu opens
        }}
        onClose={onClose}
        matchWidth={matchWidth}
        closeOnSelect={false}
        gutter={0}
      >
        <MenuButton
          ref={menuButtonRef}
          data-testid={getTestID('StandardAndCountryListDropDown')}
          maxW={maxWidth}
          as={Button}
          variant="multiselect"
          rightIcon={<DownIcon className={`${isOpen ? 'rotateUp' : 'rotateDown'}`} />}
          name={name}
          onBlur={onBlur}
          disabled={disabled}
          borderColor="gray.900"
        >
          {getOptionLabel(value) || label}
        </MenuButton>
        <MenuList
          data-testid={getTestID('MenuList')}
          maxH="70"
          overflowY="auto"
          paddingBottom={4}
          paddingTop={0}
          ref={menuListRef}
          onKeyDown={handleKeyDown}
        >
          {searchbox && (
            <Box p="4" position="sticky" top="0" bg="white">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  ref={searchInputRef}
                  placeholder={t('search')}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  required={false}
                />
              </InputGroup>
            </Box>
          )}
          <MenuOptionGroup
            type="radio"
            value={value}
            onChange={(v) => onChange({ target: { name, value: v } })}
          >
            {options.map((child, index) => (
              <MenuItemOption
                ref={(el) => {
                  optionRefs.current[index] = el;
                }}
                data-testid={getTestID('DropDownElement')}
                type="radio"
                key={child.props.value}
                value={child.props.value}
                fontWeight="normal"
                icon={<CustomCheckIcon />}
                isDisabled={child.props.disabled}
                tabIndex={highlightedIndex === index ? 0 : -1}
                onClick={() => {
                  onChange({ target: { name, value: child.props.value } });
                  onClose();
                }}
              >
                {child.props.children}
              </MenuItemOption>
            ))}
            {showGradient && (
              <Box
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                height="8"
                bgGradient="linear(to-t, white, rgba(255, 255, 255, 0.3))"
                pointerEvents="none"
              />
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}

SingleSelectDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  matchWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  searchbox: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default SingleSelectDropdown;
