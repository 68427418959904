import {
  IconButton,
  Flex,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';
import ChevronLeftIcon from '../../icons/ChevronLeftIcon';
import MenuIcon from '../../icons/MenuIcon';

function ToggleSidebarButton(props) {
  const {
    isExpanded,
    setIsExpanded,
  } = props;

  return (
    <Flex
      alignItems="flex-start"
      w="100%"
      marginLeft="3"
    >
      <Flex
        paddingTop="16px"
        paddingBottom="16px"
      >
        {!isExpanded ? (
          <IconButton
            data-testid={getTestID('sidebarExpandButton')}
            w="40px"
            h="40px"
            icon={<MenuIcon />}
            alignItems="center"
            onClick={() => setIsExpanded(!isExpanded)}
          />
        ) : (
          <IconButton
            data-testid={getTestID('sidebarShrinkButton')}
            w="40px"
            h="40px"
            icon={<ChevronLeftIcon />}
            alignItems="center"
            onClick={() => setIsExpanded(!isExpanded)}
          />
        ) }
      </Flex>
    </Flex>
  );
}

ToggleSidebarButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
};

export default ToggleSidebarButton;
