import { Button, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function StepButton({ isLastStep, isDisabled, onClick }) {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="flex-end" gap="8" marginTop="8">
      <Button
        type="button"
        variant="primary"
        onClick={onClick}
        isDisabled={isDisabled}
      >
        {isLastStep ? t('articleAvailability.startNewCheck') : t('continue')}
      </Button>
    </Flex>
  );
}

StepButton.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StepButton;
