import { Icon } from '@chakra-ui/react';

function WarningIcon(props) {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        d="M12,-0.236067977 L24.118034,24 L-0.118033989,24 L12,-0.236067977 Z M12,4.236 L3.118,22 L20.881,22 L12,4.236 Z M13,18 L13,20 L11,20 L11,18 L13,18 Z M13,8 L13,16 L11,16 L11,8 L13,8 Z"
      />
    </Icon>
  );
}

export default WarningIcon;
