import axios from 'axios';
import { appInsights } from '../config/applicationInsights';

// Utility function to create Axios instance with common interceptors
const createAxiosInstance = (baseURL, token, onError) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(token, (error) => Promise.reject(error));

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      onError(error);
      appInsights?.trackException({
        error: new Error(`${error.response.status}: ${error.config.url}`),
        severityLevel: 3, // Warning
        properties: {
          url: error.config.url,
          method: error.config.method,
          status: error.response.status,
          response: error.response.data,
        },
      });
      return Promise.reject(error);
    },
  );

  return instance;
};

export default createAxiosInstance;
