import { Icon } from '@chakra-ui/react';

function ErrorIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,1a7,7,0,0,1,4.58,1.72L2.72,12.58A7,7,0,0,1,8,1ZM8,15a7,7,0,0,1-4.58-1.72l9.86-9.86A7,7,0,0,1,8,15Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default ErrorIcon;
