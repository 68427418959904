const Container = {
  variants: {
    main: {
      maxW: 'container.xl',
      paddingBottom: '90px',
    },
    header: {
      paddingTop: '30px',
      maxW: 'container.xl',
    },
    footer: {
      paddingTop: '30px',
      maxW: 'container.xl',
    },
  },
};

export default Container;
