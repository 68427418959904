import {
  Box,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';
import DownloadIcon from '../../icons/DownloadIcon';

function DownloadOutputFile({ outputFile = null }) {
  const { t } = useTranslation();

  return (
    <Box data-testid={getTestID('DownloadOutputFile')}>
      {outputFile && (
        <Button
          as="a"
          variant="primary"
          href={outputFile?.fileLink}
          target="_blank"
          leftIcon={<DownloadIcon />}
        >
          {t('download')}
        </Button>
      )}
    </Box>
  );
}

DownloadOutputFile.propTypes = {
  outputFile: PropTypes.shape({
    fileLink: PropTypes.string,
  }),
};

export default DownloadOutputFile;
