import {
  Button,
  Text,
  Divider,
  FormLabel,
  Heading,
  Textarea,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';

function ModalUpdateDescription({
  revision,
  open,
  setOpen,
  handleUpdate,
}) {
  const { t } = useTranslation();
  const [description, setDescription] = useState(revision?.description);

  const handleInputChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Modal
      data-testid={getTestID('RevisionDescriptionUpdate')}
      closeOnOverlayClick={false}
      isOpen={open}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="span">{t('revision.changeDescription')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          <Heading marginBottom="1">{revision?.name}</Heading>
          <Text margin={0}>
            {t(`transformations.${revision?.exchangeStandard}.pretty_name`)}
          </Text>
          <Divider />
          <FormLabel>
            <Text margin="0 0 10px 0">{t('revision.changeDescription')}</Text>
            <Textarea
              data-testid={getTestID('ChangeRevisionDescriptionText')}
              maxLength={255}
              onChange={handleInputChange}
              value={description}
            />
          </FormLabel>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            marginRight={6}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={(event) => {
              event.preventDefault();
              handleUpdate({ request: revision.uuid, description });
              setOpen(false);
            }}
          >
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalUpdateDescription.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    status: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired,
    exchangeStandard: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default ModalUpdateDescription;
