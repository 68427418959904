import { useTranslation } from 'react-i18next';
import { Wrap, WrapItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useCatalogueFilters } from '../../api/hooks';
import MultiSelectDropdown from '../ui/MultiSelectDropdown';

function Filters(props) {
  const {
    setStandards,
    setLocales,
    setStatuses,
    filters,
  } = props;
  const {
    standards,
    locales,
    statuses,
  } = filters;
  const { t } = useTranslation();

  const { data } = useCatalogueFilters();

  return (
    <Wrap spacing="4">
      {data && (
        <>
          <WrapItem>
            <MultiSelectDropdown
              onChange={setStandards}
              options={data?.standards}
              selectedValues={standards}
              getOptionLabel={(o) => t(`transformations.${o}.pretty_name`)}
              label={t('exchangeStandard')}
              closeOnSelect={false}
            />
          </WrapItem>

          <WrapItem>
            <MultiSelectDropdown
              onChange={setLocales}
              options={data?.locales}
              selectedValues={locales}
              getOptionLabel={(o) => t(`locales.${o}`)}
              label={t('locale.heading')}
              closeOnSelect={false}
            />
          </WrapItem>

          {data?.statuses?.length > 1 && (
            <WrapItem>
              <MultiSelectDropdown
                onChange={setStatuses}
                options={data.statuses}
                selectedValues={statuses}
                getOptionLabel={(o) => t(`statuses.options.${o}`)}
                label={t('statuses.heading')}
                closeOnSelect={false}
              />
            </WrapItem>
          )}
        </>
      )}
    </Wrap>
  );
}

Filters.propTypes = {
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  setStandards: PropTypes.func.isRequired,
  setLocales: PropTypes.func.isRequired,
  setStatuses: PropTypes.func.isRequired,
};

export default Filters;
