import { Icon } from '@chakra-ui/react';

function TimerIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M9,3 L9,7 L13,7 L13,9 L7,9 L7,3 L9,3 Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default TimerIcon;
