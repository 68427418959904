export const portalApi = process.env.REACT_APP_API_HOST;
export const statisticsApi = process.env.REACT_APP_STATISTICS_CONSUMER_API_HOST;
export const integrationApi = process.env.REACT_APP_INTEGRATION_API_HOST;
export const dataExportsApi = process.env.REACT_APP_DATA_EXPORTS_HOST;
export const revisionsApi = process.env.REACT_APP_REVISIONS_API_HOST;
export const standardsApi = process.env.REACT_APP_STANDARDS_API_HOST;
export const userManagementApi = process.env.REACT_APP_USER_MANAGEMENT_API_HOST;

export const roles = {
  DEFAULT: 'default',
  MDM: 'MDM',
  ADMIN: 'ADMIN',
  LDES: 'LDES',
};

export const routes = {
  WELCOME: '/welcome',
  BLANK: '/blank',
  ROOT: '/',
  SYSTEM_STATUS: '/system-status',
  SETTINGS: '/settings',
  CATALOGUE: '/catalogue',
  REVISION: '/revision',
  CREATE_REVISION: '/create-revision',
  TRANSFORMATIONS: '/transformations',
  CLONE_CATALOGUE: '/clone-catalogue',
  ARTICLE_AVAILABILITY: '/article-availability',
  DEX_ASSETS_PORTAL: '/assets-portal',
  OVERVIEW: '/overview',
  PAGE_NOT_FOUND: '/404',
  SERVER_ERROR: '/500',
  SERVICE_UNAVAILABLE: '/503',
  ANY: '*',
};

export const userTypes = {
  PENDING: 'pending',
  ACTIVE: 'active',
};

export const revisionStatus = {
  APPROVED: 'approved',
  DECLINED: 'declined',
  JOB_STARTED: 'jobStarted',
  DELETED: 'deleted',
  JOB_COMPLETED: 'jobCompleted',
  JOB_FAILED: 'jobFailed',
  JOB_PENDING: 'jobPending',
  PUBLISHED: 'published',
  REQUESTED: 'requested',
  WAITING_FOR_APPROVAL: 'waitingForApproval',
};

export const inputTypes = {
  TEXT: 'text',
  LIST: 'list',
  MULTISELECT: 'multiselect',
  MULTILINE: 'multiline',
  DATE: 'date',
  INTEGER: 'integer',
  FLOAT: 'float',
  NUMBER: 'number',
  SELECT: 'select',
  EMAIL: 'email',
};

export const languageLabel = {
  'en-GB': 'EN  -  English',
  'de-DE': 'DE  -  Deutsch',
  en: 'EN  -  English',
  de: 'DE  -  Deutsch',
};

export const shortLanguageLabel = {
  'en-GB': 'EN',
  'de-DE': 'DE',
  en: 'EN',
  de: 'DE',
};
