import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useLocation } from 'react-use';
import { useState } from 'react';
import i18next from 'i18next';

import {
  getTestID,
  isMatch,
} from '../../utils/utils';
import { languageLabel, routes } from '../../utils/constants';
import ToggleSidebarButton from './ToggleSidebarButton';
import SignOutButton from './SignOutButton';
import CategoriesIcon from '../../icons/CategoriesIcon';
import ProductImagesIcon from '../../icons/ProductImagesIcon';
import UnknownProductIcon from '../../icons/UnknownProductIcon';

const isOverview = (path) => isMatch([routes.OVERVIEW], path);
const isDexAssetsPortal = (path) => isMatch([routes.DEX_ASSETS_PORTAL], path);
const isAvailability = (path) => isMatch([routes.ARTICLE_AVAILABILITY], path);

function LimitedNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [, setLanguage] = useLocalStorage('i18nextLng');

  const changeLanguage = (lng) => {
    setLanguage(lng);

    i18next.changeLanguage(lng);
  };

  return (
    <>
      <Flex
        position="fixed"
        flexDirection="column"
        width={isExpanded ? '280px' : '60px'}
        height="100vh"
        backgroundColor="gray.50"
        zIndex={999}
        transition="width 0.3s"
        transitionTimingFunction="cubic-bezier (0, 0, 0.25, 1)"
      >
        <ToggleSidebarButton
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        <Button
          data-testid={getTestID('SidebarButtonRoot')}
          leftIcon={<CategoriesIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isOverview(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.ROOT);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.overview')}</span>
        </Button>
        <Button
          data-testid={getTestID('SidebarButtonAssetsPortal')}
          leftIcon={<ProductImagesIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isDexAssetsPortal(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.DEX_ASSETS_PORTAL);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.dexAssetsPortal')}</span>
        </Button>
        <Button
          data-testid={getTestID('SidebarButtonArticleAvailability')}
          leftIcon={<UnknownProductIcon />}
          variant="sidebarItem"
          justifyContent="flex-start"
          isActive={isAvailability(location.pathname)}
          onClick={() => {
            setIsExpanded(false);
            navigate(routes.ARTICLE_AVAILABILITY);
          }}
        >
          <span style={{ overflow: 'hidden', marginLeft: '3px' }}>{t('sidebarNavigation.availabilityCheck')}</span>
        </Button>
        <Divider />
        <Flex
          w="100%"
          flexDir="column"
          marginTop="auto"
        >
          <Menu gutter={0}>
            <MenuButton
              as={Button}
              variant="sidebarItem"
              data-testid={getTestID('LanguageSwitch')}

            >
              {languageLabel[i18next.language] || languageLabel[i18next.resolvedLanguage]}
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                type="radio"
                defaultValue={i18next.resolvedLanguage}
                onChange={(key) => changeLanguage(key)}
              >
                <MenuItemOption
                  key="de"
                  value="de"
                  data-testid={getTestID('LanguageSwitchToDe')}
                >
                  DE - Deutsch
                </MenuItemOption>
                <MenuItemOption
                  key="en"
                  value="en"
                  data-testid={getTestID('LanguageSwitchToEn')}
                >
                  EN - English
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>

          <SignOutButton />
        </Flex>
      </Flex>
      <Box
        id="overlay"
        position="fixed"
        display={isExpanded ? 'block' : 'none'}
        w="100%"
        h="100%"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0,0,0,0.2)"
        zIndex="998"
        onClick={() => setIsExpanded(false)}
      />
    </>
  );
}

export default LimitedNav;
