import {
  Box,
  Divider,
  Heading,
  Grid,
  GridItem,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableCaption,
  List,
  ListItem,
  Link,
  Collapse,
  useTheme,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Fragment,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useArticlesAvailability } from '../../../api/hooks';
import {
  sortLocalesAlphabetically,
  getLocale,
  getTestID,
} from '../../../utils/utils';
import GeberitSpinner from '../../ui/GeberitSpinner';
import Toggler from '../../ui/Toggler';
import AvailabilityStatusBox from '../AvailabilityStatusBox';
import Dot from '../Dot';
// The config is copied from the geberit_mbp_cati repository
import config from '../config.json';

const urlByLocale = config?.map((c) => c.catalogs).flat();

const getTranslationKey = (s) => {
  let key = 'availabilityRules.';
  if (s.includes('Attribute')) {
    key += 'MESSAGE_INVALID';
  }
  if (s.includes('before')) {
    key += 'DATE_BEFORE_MESSAGE_INVALID';
  }
  if (s.includes('after')) {
    key += 'DATE_AFTER_MESSAGE_INVALID';
  }
  if (s.includes('not available')) {
    key += 'MESSAGE_INVALID_BRAND';
  }
  return key;
};

const getTranslationValue = (s) => {
  const attribute = s?.split(' ')[3];
  const expected = s?.split('with expected value - ')[1]?.split(',')[0];
  const actual = s?.split('the actual value is - ')[1]?.split('.')[0];

  return {
    attribute,
    expected,
    actual,
  };
};

function AvailabilityList({ onListVisibilityChange }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const successColor = theme?.colors?.success;
  // Expanded rows
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  // Toggle selection state of a row by ID
  const toggleRow = (rowId) => {
    setSelectedIndexes((current) => (current.includes(rowId)
      ? current.filter((id) => id !== rowId)
      : [...current, rowId]));
  };

  const [articleField] = useField('article', 'text');

  const { data: availabilityList, error, isLoading } = useArticlesAvailability(articleField);

  // Notify parent component about the list visibility
  if (availabilityList && !isLoading && !error) {
    onListVisibilityChange(true);
  } else {
    onListVisibilityChange(false);
  }

  if (error?.response?.status === 404) {
    return (
      <AvailabilityStatusBox
        testId="ArticleInput404"
        messageTestId={getTestID('availabilityMessageText404')}
      >
        {`The article with code «${articleField.value}» could not be found.`}
      </AvailabilityStatusBox>
    );
  }

  if (error?.response?.status === 401) {
    return (
      <AvailabilityStatusBox testId="ArticleInput401">
        {`You are not authorised to view information about article «${articleField.value}»`}
      </AvailabilityStatusBox>
    );
  }

  if (isLoading) {
    return (
      <AvailabilityStatusBox testId="ArticleInputLoading">
        <GeberitSpinner />
      </AvailabilityStatusBox>
    );
  }

  const availableCount = availabilityList?.assortments.filter((a) => a.visible).length;
  const unAvailableCount = availabilityList?.assortments.filter((a) => !a.visible).length;

  return (
    <Box data-testid={getTestID('ArticleInput')}>
      <Grid>
        <GridItem>
          <Heading as="h1" size="h1">
            {t('articleAvailability.articleAvailabilityTitle')}
          </Heading>
          <Divider />
          {availabilityList && (
            <>
              <TableContainer>
                <Table>
                  <TableCaption
                    data-testid={getTestID('AvailabilityMessageText')}
                    placement="top"
                    style={{ textAlign: 'start', fontSize: 'md', paddingInlineStart: 0 }}
                  >
                    {t('availabilityMessage.availableCount', { article: articleField.value, count: availableCount })}
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th style={{ width: '5%' }} />
                      <Th style={{ width: '30%' }}>{t('articleAvailability.catalog')}</Th>
                      <Th>{t('articleAvailability.brand')}</Th>
                      <Th>{t('articleAvailability.link')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {availabilityList?.assortments
                      .filter((a) => a.visible)
                      .sort((a, b) => sortLocalesAlphabetically(a.localization, b.localization))
                      .map((a) => {
                        const { url } = urlByLocale
                          .find((c) => c.locale === a.localization && c.brand === a.brand) || {};
                        const articleLink = url ? `${url}/${articleField.value}.html` : '#';
                        return (
                          <Tr key={a.localization}>
                            <Td><Dot color={successColor} /></Td>
                            <Td data-testid={getTestID('AvailableCountry')}>{getLocale(a.localization)}</Td>
                            <Td data-testid={getTestID('AvailableBrand')}>{t(`articleAvailability.brands.${a.brand}`)}</Td>
                            <Td>
                              <Link isExternal href={articleLink}>
                                {articleLink}
                              </Link>
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
              <Divider />
              <TableContainer>
                <Table data-testid={getTestID('UnavailableTable')} layout="fixed">
                  <TableCaption
                    placement="top"
                    style={{ textAlign: 'start', fontSize: 'md', paddingInlineStart: 0 }}
                  >
                    {t('availabilityMessage.unavailableCount', { article: articleField.value, count: unAvailableCount })}
                  </TableCaption>
                  <Thead>
                    <Tr>
                      <Th style={{ width: '5%' }} />
                      <Th>{t('articleAvailability.catalog')}</Th>
                      <Th>{t('articleAvailability.brand')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {availabilityList?.assortments
                      .filter((a) => !a.visible)
                      .sort((a, b) => sortLocalesAlphabetically(a.localization, b.localization))
                      .map((a) => {
                        const rowId = a.localization + a.brand;
                        const isExpanded = selectedIndexes
                          .filter((i) => i === rowId)
                          .length > 0;
                        return (
                          <Fragment key={rowId}>
                            <Tr className={isExpanded ? 'highlighted-and-selected' : ''} key={a.localization}>
                              <Td>
                                <Toggler
                                  expand={selectedIndexes.includes(rowId)}
                                  handleClick={() => toggleRow(rowId)}
                                  marginRight="2"
                                />
                              </Td>
                              <Td data-testid={getTestID('UnavailableCountry')}>{getLocale(a.localization)}</Td>
                              <Td data-testid={getTestID('UnavailableBrand')}>{t(`articleAvailability.brands.${a.brand}`)}</Td>
                            </Tr>
                            <Tr className={isExpanded ? 'collapsable highlighted' : 'unstyled'}>
                              <Td colSpan={3}>
                                <Collapse
                                  in={isExpanded}
                                  animateOpacity
                                >
                                  <Td><div style={{ marginRight: '10px' }} /></Td>
                                  <Td>
                                    <Heading as="h5" size="sm">
                                      {t('articleAvailability.reason')}
                                    </Heading>
                                    <List>
                                      {a.rules
                                        .filter((r) => r.includes('INVALID'))
                                        .map((r) => {
                                          const key = getTranslationKey(r);
                                          const value = getTranslationValue(r);
                                          const translated = t(key, { ...value });

                                          return (
                                            <ListItem key={`${a.localization}-${r}`}>
                                              {translated.replace('INVALID : ', '')}
                                            </ListItem>
                                          );
                                        })}
                                    </List>
                                  </Td>
                                  <Td />
                                </Collapse>
                              </Td>
                            </Tr>
                          </Fragment>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
}

AvailabilityList.propTypes = {
  onListVisibilityChange: PropTypes.func.isRequired,
};

export default AvailabilityList;
