import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

import { useUser } from '../../../api/hooks';

function User({ userUuid = '' }) {
  const { data: user } = useUser(userUuid);

  return (
    <Text>
      {user?.fullName}
    </Text>
  );
}

User.propTypes = {
  userUuid: PropTypes.string,
};

export default User;
