import { useMemo } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { routes } from '../../utils/constants';
import { isMatch } from '../../utils/utils';
import CategoriesIcon from '../../icons/CategoriesIcon';
import DatapointsIcon from '../../icons/DatapointsIcon';
import PlusIcon from '../../icons/PlusIcon';
import ProductImagesIcon from '../../icons/ProductImagesIcon';
import SettingsIcon from '../../icons/SettingsIcons';
import UnknownProductIcon from '../../icons/UnknownProductIcon';
import LanguageMenu from './LanguageMenu';
import SidebarButton from './SidebarButton';
import SignOutButton from './SignOutButton';

// Helper functions to identify active routes
const routeMatchers = {
  isCatalogues: (path) => path === routes.ROOT
        || isMatch([routes.CATALOGUE, routes.REVISION, routes.CREATE_REVISION], path),
  isSystemStatus: (path) => isMatch([routes.SYSTEM_STATUS], path),
  isSettings: (path) => isMatch([routes.SETTINGS], path),
  isDexAssetsPortal: (path) => isMatch([routes.DEX_ASSETS_PORTAL], path),
  isAvailability: (path) => isMatch([routes.ARTICLE_AVAILABILITY], path),
  isTransformations: (path) => isMatch([routes.TRANSFORMATIONS, routes.CLONE_CATALOGUE], path),
};

function DrawerMenuContent({ setIsExpanded }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const buttons = useMemo(() => [
    {
      icon: <CategoriesIcon />,
      label: t('sidebarNavigation.catalogues'),
      route: routes.ROOT,
      isActive: routeMatchers.isCatalogues(location.pathname),
      testID: 'SidebarButtonRoot',
    },
    {
      icon: <DatapointsIcon />,
      label: t('sidebarNavigation.systemStatus'),
      route: routes.SYSTEM_STATUS,
      isActive: routeMatchers.isSystemStatus(location.pathname),
      testID: 'SidebarButtonSystemStatus',
    },
    {
      icon: <SettingsIcon />,
      label: t('sidebarNavigation.settings'),
      route: routes.SETTINGS,
      isActive: routeMatchers.isSettings(location.pathname),
      testID: 'SidebarButtonSettings',
    },
    {
      icon: <ProductImagesIcon />,
      label: t('sidebarNavigation.dexAssetsPortal'),
      route: routes.DEX_ASSETS_PORTAL,
      isActive: routeMatchers.isDexAssetsPortal(location.pathname),
      testID: 'SidebarButtonAssetsPortal',
    },
    {
      icon: <UnknownProductIcon />,
      label: t('sidebarNavigation.availabilityCheck'),
      route: routes.ARTICLE_AVAILABILITY,
      isActive: routeMatchers.isAvailability(location.pathname),
      testID: 'SidebarButtonArticleAvailability',
      forceReload: true,
    },
    <Divider key="menu-buttons-divider" />,
    {
      icon: <PlusIcon />,
      label: t('sidebarNavigation.newCatalogue'),
      route: routes.TRANSFORMATIONS,
      isActive: routeMatchers.isTransformations(location.pathname),
      testID: 'SidebarButtonTransformations',
      forceReload: true,
    },
  ], [location.pathname, t]);

  return (
    <Flex flexDirection="column" justifyContent="space-between" height="100%">
      <Box>
        <Box>
          {buttons.map((item) => (
            item.icon ? (
              <SidebarButton
                key={item.testID}
                icon={item.icon}
                label={item.label}
                isActive={item.isActive}
                onClick={() => {
                  setIsExpanded(false);
                  if (item.forceReload) {
                    // Force full reload for transformations page
                    window.location.href = item.route;
                  } else {
                    navigate(item.route);
                  }
                }}
                testID={item.testID}
              />
            ) : item // Divider
          ))}
        </Box>
      </Box>

      <Flex w="100%" flexDir="column">
        <LanguageMenu />
        <SignOutButton />
      </Flex>
    </Flex>
  );
}

DrawerMenuContent.propTypes = {
  setIsExpanded: PropTypes.func.isRequired,
};

export default DrawerMenuContent;
