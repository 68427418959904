import { useState } from 'react';
import {
  Button,
  Flex,
} from '@chakra-ui/react';
import {
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../../utils/utils';

function FormStepper({
  children, onSubmit, initialValues,
  validationSchema = [], initialSubmitButtonDisabled = false,
}) {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const currentChild = children[step];
  const currentSchema = validationSchema[step];

  function isLastStep() {
    return step === children.filter((c) => c).length - 1;
  }

  const handleNextStep = () => {
    setStep((s) => s + 1);
  };

  const handlePreviousStep = () => {
    setStep((s) => s - 1);
  };

  return (
    <Formik
      enableReinitialize="true"
      initialValues={initialValues}
      validationSchema={currentSchema}
      validateOnMount
      autoComplete="off"
      onSubmit={(values) => {
        if (isLastStep()) {
          onSubmit(values);
        } else {
          handleNextStep();
        }
      }}
    >
      {({ isValid, dirty }) => (
        <Form>
          {currentChild}
          <Flex
            justifyContent="flex-end"
            gap="8"
            marginTop="8"
          >
            {step > 0 ? (
              <Button
                onClick={handlePreviousStep}
                variant="secondary"
                type="button"
              >
                {t('back')}
              </Button>
            ) : null}
            <Button
              data-testid={getTestID('continueButton')}
              type="submit"
              variant="primary"
              /* The first form step does not use formik's form fields
              so you can't check if the input has been touched.
              dirty will always be false in that case */
              isDisabled={(!initialSubmitButtonDisabled)
                && (step > 0 ? (!dirty || !isValid) : !isValid)}
            >
              {isLastStep()
                ? t('submit')
                : t('continue')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

FormStepper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  validationSchema: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.shape(),
  })),
  initialSubmitButtonDisabled: PropTypes.bool,
};

export default FormStepper;
