import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useLocalStorage } from 'react-use';
import {
  Form,
  Formik,
} from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCreateAnonymousCatalogue, useTransformation } from '../../api/hooks';
import { getTestID } from '../../utils/utils';
import AssortmentOptions from '../CatalogueCreate/FormSteps/AssortmentOptions/AssortmentOptions';
import Input from '../CatalogueCreate/Form/Input';
import Required from '../ui/Required';
import {
  customAssortment,
  customPresets,
  exchangeStandard,
  getParameterInitialValues,
  getPresetInitialValues,
  sanitizeData,
} from './utils';

function DexAssetsCreate(props) {
  const { showSuccessPage } = props;
  const { t, i18n } = useTranslation();
  const [selectedCountry] = useLocalStorage('countryLanguage');

  const { data: transformation } = useTransformation(exchangeStandard);

  const { mutate: createCatalogue } = useCreateAnonymousCatalogue();

  const handleCreateCatalogue = (catalogue) => { createCatalogue({ catalogue }); };

  const articleListParam = transformation?.parameters?.find((p) => p.name === 'articleListAnonymous');

  return (
    <Box>
      {transformation && (
        <div>
          <Formik
            initialValues={{
              exchangeStandard,
              ...getPresetInitialValues(customPresets),
              ...getParameterInitialValues(transformation, selectedCountry),
            }}
            onSubmit={(formValues) => {
              const catalogue = sanitizeData(formValues, transformation);
              handleCreateCatalogue(catalogue);
              showSuccessPage(true);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box data-testid={getTestID('Transformations')}>
                  <Box maxW="half" height="100%">
                    <Flex direction="column" gap="8">
                      <Box>
                        <Flex>
                          <Heading>
                            {t('dexAssetsPortal.form.country.header')}
                          </Heading>
                          <Required />
                        </Flex>
                        <Text>
                          {t('dexAssetsPortal.form.country.explanation')}
                        </Text>
                      </Box>
                      {transformation.parameters?.filter((p) => p.name === 'localeAnonymous')
                        .map((p) => (
                          <Input
                            key={p.name}
                            type="parameter"
                            data={{ ...p, required: false }}
                            transformation={exchangeStandard}
                            hint={i18n.exists(`dexAssetsPortal.${p.name}.hint`) ? t(`dexAssetsPortal.${p.name}.hint`) : ''}
                            autoFocus
                            searchbox
                          />
                        ))}
                    </Flex>
                  </Box>
                  <Divider />
                  <Box maxW="half" height="100%">
                    <Flex direction="column" gap="8">
                      <Box>
                        <Heading>
                          {t('dexAssetsPortal.form.assortment.header')}
                        </Heading>
                        <Box>
                          <Text>
                            {t('dexAssetsPortal.form.assortment.explanation')}
                          </Text>
                        </Box>
                      </Box>
                      <AssortmentOptions
                        assortmentParam={customAssortment}
                        assortmentParamLabel={`dexAssetsPortal.${customAssortment.name}.label`}
                        articleListParam={articleListParam}
                        articleListParamLabel={`dexAssetsPortal.${articleListParam.name}.label`}
                        dropdownSize={1}
                      />
                    </Flex>
                  </Box>
                  <Divider />
                  <Box>
                    <Box maxW="full">
                      {transformation.parameters?.length > 0 && (
                        <>
                          <Box marginBottom="8">
                            <Heading>
                              {t('dexAssetsPortal.form.assetSelection.header')}
                            </Heading>
                            <Box>
                              <Text noOfLines={[2]}>
                                {t('dexAssetsPortal.form.assetSelection.explanation')}
                              </Text>
                            </Box>
                          </Box>
                          <SimpleGrid columns={[1, 2, 2, 3, 4]} columnGap={[4, 8]}>
                            {transformation?.parameters?.filter(
                              (param) => !(param.name === 'assortmentAnonymous'
                                || param.name === 'articleListAnonymous'
                                || param.name === 'localeAnonymous'
                                || param.name === 'assetNamingMethod'),
                            )
                              .map((param, i) => (
                                <Input
                                  name={`parameters[${i}].${param.name}`}
                                  key={param.name}
                                  type="parameter"
                                  data={param}
                                  transformation={exchangeStandard}
                                  label={`dexAssetsPortal.${param.name}.label`}
                                  hint={i18n.exists(`dexAssetsPortal.${param.name}.hint`) ? t(`dexAssetsPortal.${param.name}.hint`) : ''}
                                />
                              ))}
                          </SimpleGrid>
                        </>
                      )}
                    </Box>
                    <Divider />
                    {transformation.parameters?.length > 0 && (
                      <Box maxW="half" height="100%">
                        <Box marginBottom="8">
                          <Flex>
                            <Heading>
                              {t('dexAssetsPortal.form.fileNaming.header')}
                            </Heading>
                            <Required />
                          </Flex>
                          <Box>
                            <Text noOfLines={5}>
                              {t('dexAssetsPortal.form.fileNaming.explanation')}
                            </Text>
                          </Box>
                        </Box>
                        <Box>
                          {transformation?.parameters?.filter(
                            (param) => param.name === 'assetNamingMethod',
                          ).map((param, i) => (
                            <Input
                              name={`parameters[${i}].${param.name}`}
                              key={param.name}
                              type="parameter"
                              data={{ ...param, required: false }}
                              transformation={exchangeStandard}
                              label={`dexAssetsPortal.${param.name}.label`}
                              hint={i18n.exists(`dexAssetsPortal.${param.name}.hint`) ? t(`dexAssetsPortal.${param.name}.hint`) : ''}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}

                    <Divider />

                    {transformation.presets?.length > 0 && (
                      <Box maxW="half" height="100%">
                        <Box marginBottom="8">
                          <Heading>
                            {t('dexAssetsPortal.form.fileOutput.header')}
                          </Heading>
                          <Box>
                            <Text noOfLines={5}>
                              {t('dexAssetsPortal.form.fileOutput.explanation')}
                            </Text>
                          </Box>
                        </Box>
                        <SimpleGrid columns={[1]}>
                          {customPresets.map((preset, i) => (
                            <Input
                              name={`presets[${i}].name`}
                              key={preset.name}
                              type="preset"
                              data={preset}
                              transformation={exchangeStandard}
                              label={`presets.${exchangeStandard}.${preset.name}.label`}
                              hint={i18n.exists(`presets.${exchangeStandard}.${preset.name}.hint`)
                                ? t(`presets.${exchangeStandard}.${preset.name}.hint`) : ''}
                            />
                          ))}
                        </SimpleGrid>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Flex
                  justifyContent="flex-end"
                  gap="8"
                  marginTop="8"
                >
                  <Button
                    type="submit"
                    variant="primary"
                    isDisabled={isSubmitting}
                  >
                    {t('dexAssetsPortal.form.submit')}
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Box>
  );
}

DexAssetsCreate.propTypes = {
  showSuccessPage: PropTypes.func.isRequired,
};

export default DexAssetsCreate;
