import { Icon } from '@chakra-ui/react';

function SearchIcon(props) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M15.35,14.65,10.58,9.87a6,6,0,1,0-.71.71l4.78,4.77ZM6,11a5,5,0,1,1,5-5A5,5,0,0,1,6,11Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default SearchIcon;
