import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DownloadTransformationLog from './DownloadTransformationLog';
import User from './User';
import useUserStore from '../../../services/UserStore';
import { formatDateTime } from '../../../utils/utils';
import { useEventLog } from '../../../api/hooks';

function TabActivities(props) {
  const { revision } = props;
  const {
    uuid,
    name,
  } = revision;
  const user = useUserStore((s) => s.user);
  const { t } = useTranslation();

  const { data: eventLog } = useEventLog(uuid);

  return (
    <>
      {eventLog?.events && (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>{t('event')}</Th>
                <Th>{t('details')}</Th>
                <Th>{t('updatedBy')}</Th>
                <Th>{t('updatedOn')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {eventLog.events.map((event) => (
                <Tr key={event.id}>
                  <Td>
                    {event?.event}
                  </Td>
                  <Td>
                    {event?.detail}
                  </Td>
                  <Td>
                    <User userUuid={event?.updatedBy} />
                  </Td>
                  <Td>
                    {formatDateTime(event?.updatedOn)}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {(user.role === 'MDM' || user.role === 'ADMIN') && (
        <DownloadTransformationLog
          revisionUuid={uuid}
          revisionName={name}
        />
      )}
    </>
  );
}

TabActivities.propTypes = {
  revision: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default TabActivities;
