import {
  Button,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalIcon from '../ui/ModalIcon';
import CloseIcon from '../../icons/CloseIcon';
import { getTestID } from '../../utils/utils';
import { useDeleteCatalogue } from '../../api/hooks';

function ModalDeleteCatalogue(props) {
  const {
    open,
    setOpen,
    catalogueUuid,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: deleteCatalogue } = useDeleteCatalogue();

  const handleDelete = () => {
    deleteCatalogue(catalogueUuid, {
      onSuccess: () => {
        navigate('/');
      },
    });
  };

  return (
    <Modal
      data-testid={getTestID('ModalDeleteCatalogue')}
      closeOnOverlayClick={false}
      isOpen={open}
      variant="warning"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalIcon type="warning" />
          <Text as="span">{t('warning')}</Text>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => setOpen(false)}
            padding={0}
          />
        </ModalHeader>
        <ModalBody pb={6}>
          {t('catalogue.delete')}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            marginRight="5"
            onClick={() => setOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid={getTestID('deleteCatalogueButton')}
            variant="primary"
            mr={3}
            onClick={handleDelete}
          >
            {t('delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ModalDeleteCatalogue.propTypes = {
  catalogueUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ModalDeleteCatalogue;
