import {
  Box,
  Button,
  Heading,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import RightArrowIcon from '../../icons/RightArrowIcon';

function TeaserBox({
  title,
  body,
  buttonLabel = null,
  onClick = null,
  color = 'white',
  backgroundColor = 'black',
}) {
  return (
    <Box
      padding="8"
      width="330px"
      height="350px"
      backgroundColor={backgroundColor}
      color={color}
    >
      <Heading as="h3" fontWeight="500" size="lg" textTransform="uppercase">
        {title}
      </Heading>
      <Text size="lg">{body}</Text>
      {buttonLabel && (
        <Button
          paddingLeft="0"
          color={color}
          variant="link"
          onClick={onClick}
          leftIcon={<RightArrowIcon fill={color} />}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
}

TeaserBox.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default TeaserBox;
