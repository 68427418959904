const Table = {
  variants: {
    default: {
      tbody: {
        tr: {
          borderBottom: '1px solid',
          borderColor: 'gray.150',
          a: {
            color: 'black',
            _hover: {
              pointerEvents: 'auto',
              textDecoration: 'none',
            },
          },
          _hover: {
            background: 'gray.100',
            button: {
              '&.highlight-action': {
                background: 'gray.200',
                _hover: {
                  background: 'gray.200',
                },
              },
            },
          },
          '&.collapsable': {
            _hover: {
              background: 'none',
            },
          },
          '&.highlighted-and-selected': {
            background: 'gray.200',
          },
          '&.highlighted': {
            background: 'gray.100',
            _hover: {
              background: 'gray.100',
            },
          },
          '&.unstyled': {
            _hover: {
              background: 'none',
            },
            td: {
              padding: '0 0 0 36px',
            },
          },
          '&:not(.unstyled)': {
            borderTop: '1px solid',
            borderColor: 'gray.200',
          },
        },
      },
      th: {
        paddingTop: 4,
        paddingBottom: 4,
        fontWeight: 700,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        textTransform: 'none',
        '&.nested': {
          _hover: {
            background: 'none',
          },
          td: {
            padding: '0 0 0 30px',
          },
        },
      },
      td: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    main: {
      table: {
        marginBottom: 12,
      },
      td: {
        paddingLeft: 0,
        paddingRight: 2,
        justifyContent: 'center',
      },
      thead: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
        th: {
          padding: 8,
          paddingLeft: 0,
          fontWeight: 700,
          fontSize: '14px',
          whiteSpace: 'nowrap',
          textTransform: 'none',
          '&:first-of-type': {
            textAlign: 'start',
          },
          '&:nth-of-type(4)': {
            width: '74px',
            textAlign: 'center',
          },
          '&:nth-of-type(8)': {
            textAlign: 'end',
            paddingRight: 0,
          },
        },
      },
      tbody: {
        th: {
          '&:first-of-type': {
            width: '74px',
            textAlign: 'start',
            justifyContent: 'center',
          },
          '&:nth-of-type(2)': {
            minWidth: '100px',
            paddingLeft: 0,
          },
        },
        td: {
          '&:first-of-type': {
            width: '74px',
            justifyContent: 'center',
          },
          '&:nth-of-type(2)': {
            minWidth: '100px',
            paddingLeft: 0,
          },
          '&:nth-of-type(4)': {
            width: '74px',
            textAlign: 'center',
          },
          '&:nth-of-type(8)': {
            textAlign: 'end',
          },
        },
        tr: {
          '&.normal': {
            borderBottom: '1px solid',
            borderColor: 'gray.200',
          },
          '&.normal:hover': {
            background: 'gray.50',
          },
          '&.selected': {
            borderBottom: '1px solid',
            borderColor: 'gray.150',
            background: 'gray.150',
          },
          '&.nested': {
            background: 'gray.25',
          },
          '&.hidden': {
            display: 'none',
          },
        },
      },
    },
    'mobile-revisions': {
      table: {
        paddingTop: 4,
      },
      tr: {
        background: 'gray.25',
      },
      td: {
        padding: '6',
        justifyContent: 'flex-start',
      },
      th: {
        paddingTop: 4,
        fontWeight: 700,
        fontSize: '14px',
        whiteSpace: 'nowrap',
        textTransform: 'none',
      },
    },
    mobile: {
      table: {
        marginBottom: 12,
      },
      td: {
        padding: '4px 0',
        justifyContent: 'flex-start',
      },
      tbody: {
        td: {
          paddingY: '6',
        },
        tr: {
          '&.normal': {
            borderBottom: '1px solid',
            borderColor: 'gray.200',
          },
          '&.normal:hover': {
            background: 'gray.50',
          },
          '&.selected': {
            borderBottom: '1px solid',
            borderColor: 'gray.150',
            background: 'gray.150',
          },
          '&.nested': {
            background: 'gray.25',
          },
          '&.hidden': {
            display: 'none',
          },
        },
      },
    },
    basic: {
      table: {
        marginBottom: 12,
      },
      caption: {
        fontSize: '2xl',
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
        textAlign: 'start',
      },
      td: {
        paddingLeft: 0,
        justifyContent: 'center',
      },
      thead: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
        th: {
          padding: 8,
          paddingLeft: 0,
          fontWeight: 700,
          fontSize: '14px',
          whiteSpace: 'nowrap',
          textTransform: 'none',
          '&:first-of-type': {
            textAlign: 'start',
          },
          '&:nth-of-type(4)': {
            width: '74px',
            textAlign: 'center',
          },
          '&:nth-of-type(5)': {
            textAlign: 'end',
            paddingRight: 0,
          },
          '&:nth-of-type(8)': {
            textAlign: 'end',
            paddingRight: 0,
          },
        },
      },
      tbody: {
        tr: {
          borderBottom: '1px solid',
          borderColor: 'gray.200',
        },
        td: {
          '&:nth-of-type(4)': {
            div: {
              button: {
                paddingRight: 0,
              },
            },
          },
          '&:nth-of-type(5)': {
            textAlign: 'end',
            paddingRight: 0,
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};

export default Table;
