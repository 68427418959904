import {
  Box,
  Divider,
  FormLabel,
  Heading,
  Grid,
  Input as ChakraInput,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import Required from '../../ui/Required';
import { getTestID } from '../../../utils/utils';

function ArticleInput() {
  const { t } = useTranslation();
  const [articleField] = useField('article', 'text');

  return (
    <Box data-testid={getTestID('ArticleInput')}>
      <Grid gridGap="8">
        <GridItem>
          <Heading as="h1" size="lg">
            {t('articleAvailability.articleAvailabilityTitle')}
          </Heading>
          <Text size="lg">
            {t('articleAvailability.articleAvailabilityBlurb')}
          </Text>
          <Divider />
        </GridItem>
        <GridItem width="50%">
          <FormLabel>
            <Heading>
              {t('articleAvailability.articleNumber')}
              <Required />
            </Heading>
          </FormLabel>
          <ChakraInput
            autoFocus
            type="text"
            name={articleField.name}
            onChange={articleField.onChange}
            onBlur={articleField.onBlur}
            value={articleField.value}
            maxLength={255}
            isRequired
          />
          <Text size="sm" marginTop="1" color="gray.500">
            e.g. 123.456.78.9
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default ArticleInput;
