import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationDE from './translation/de/translation.json';
import translationEN from './translation/en/translation.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly', // This normalizes language codes (e.g., 'en-GB' to 'en')
    lowerCaseLng: true, // Automatically converts language codes to lowercase
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
  });

export default i18n;
